<template>
  <div :key="componentKey">
    <form v-if="show" autocomplete="off">
      <div>
        <a v-if="addSkill_btn" href="javascript:void(0)" @click="addSkill()" title="Add Skill">
          <div class="custom-icon icon">
            <plus-icon size="2x" class="custom-class"></plus-icon>
          </div>
        </a>
      </div>
      <div class="row">
        <div class="col-md-5">
          <div class="mb-3 form-group row no-gutters" v-if="skillName_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="skillName_id">Skill Name</label>
            <div class="col-sm-9">
              <select id="skillName_id" class="custom-select-option form-control form-control-sm"
                v-model="skillsSubCategoryId" >
                <option value=0>Please Select Skill</option>
                <option v-for="option in skillSubCategories" :key="option.key" :value="option.key">
                  {{ option.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="mb-3 form-group row no-gutters" v-if="skillLevel_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="skillLevel_id">Skill Level</label>
            <div class="col-sm-9">
              <!-- most basic, used for Knobs demo -->
              <input type="range" id="temp" name="temp" v-model="skillLevelOutof100" :min="minValue"
                :max="maxValue" :step="stepValue" list="markers"  class="accent"/>
              <datalist id="markers">
                <option v-for="value in markers" :key="value" :value="value"></option>
                <span>1</span>
              </datalist>
            </div>

          </div>
        </div>
        <div class="col-md-2">
          <a v-if="addNewSkill_plus" href="javascript:void(0)" @click="AddskillsToList()" >
            <div class="custom-icon icon">
              <plus-icon size="2x" class="custom-class"></plus-icon>
            </div>
          </a>
          <div class="">
            <a v-if="removeSkill_btn" href="javascript:void(0)" @click="removeSkill()" title="Remove Skill">
              <b-icon font-scale="1" variant="danger" icon="trash"></b-icon>
            </a>
          </div>
        </div>
      </div>
      <div  class="skills-container">
        <div class="row">
  <div class="col-md-12" style="display: flex; flex-wrap: wrap;">
    <!-- Skill tag with cross icon -->
    
      <span class="skill-tag" v-for="object in skills" v-if="skills.length > 0" :key="object.id">
        <span>{{ object.skillName }}</span>
        <b-icon
          font-scale="1.5"
          variant="danger"
          icon="x-circle"
          @click="deleteSkill(object.id)"
          title="Delete skill"
          class="delete-icon"
          style="color: white;"
        ></b-icon>
      </span>
   
  </div>
</div></div>
      <div class="modal-footer">

        <button type="button" id="skillbutton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
          data-toggle="modal" data-target="#exampleModal">
          Add new skills
        </button>
        <div v-if="showCloseButton">
          <b-button id="showCloseButton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
            type="button" size="sm" @click="close" variant="outline-dark" data-dismiss="modal">Close
          </b-button>
        </div>
        <div v-if="showResetButton">
          <b-button id="showResetButton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
            type="reset" size="sm" variant="outline-dark">Reset
          </b-button>
        </div>
        <div>
          <b-button v-if="showSaveButton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
            @click="AddOrUpdate()" size="sm" variant="outline-dark">Save
          </b-button>
        </div>
        <!-- <div>
          <b-button
            v-if="showReassignButton"
            class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
            @click="AddOrUpdate()"
            size="sm"
            variant="outline-dark"
            >Reassign
          </b-button>
        </div> -->
      </div>
    </form>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add New Skill</h5>
            <!-- <span class="close" @click="closeModal">
          <span aria-hidden="true">&times;</span>
        </span> -->
          </div>
          <div class="modal-body">
            <form ref="AddDropdownForm">
              <div class="row">
                <div class="col-md-12">
                  <!-- <b-form-group label="Text" label-for="Text" autocomplete="off">
                    <b-form-input id="Text" v-model="Text" :class="{'is-invalid':submitted && $v.Text.$error}"
                      type="text" required></b-form-input>

                  </b-form-group> -->
                </div>
              </div>
              <span v-if="displaymessage == true">Skill added please click outside of modal to close.</span>

            </form>
          </div>
          <div class="modal-footer">
            <!-- <span class="btn btn-primary close" @click="closeModal">Close</span> -->
            <button type="button" @click="AddDropdownValue" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
    <!--    <CPModal :is-experience-section-visible="isExperienceSection"></CPModal>-->
  </div>
</template>
<script>
import axios from "axios";
import { object } from "underscore";
import { PlusIcon } from "vue-feather-icons";
//import CPModal from "@/components/employeeComponents/CompleteProfileModalComponent";
export default {
  name: 'AddEditSkillsComponent',
  components:{
    PlusIcon,
    // CPModal
  },
  data(){
    return{
      // selectedValue: 0,   // Model for the range input
      // skillLevelOutof100:0,
      stepValue: 1,
      minValue: 0,        // Minimum value for the range input
      maxValue: 10,      // Maximum value for the range input
      markers: [0,1,2,3,4,5,6,7,8,9,10], // Array of markers for the range
    

      displaymessage:false,
      Text: null,
      employeeId:0,
      //Skill Section Start
      isSkillSection:true,
      skillName_dd:true,
      skillLevel_dd:true,
      addSkill_btn:false,
      removeSkill_btn:false,
      addNewSkill_plus:true,
      skills:[],
      skillObject:
      {
        employeeId: 0,
        skillsSubCategoryId: 0,
        skillLevelOutof100: 0,
        skillName:"",
      },


      // skills:[{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}],
      skillSubCategories:null,
      selectedSkillLevel:0,
      skillSubCategorieName:"",
      getEmpSkills:null,
      // skillLevels:[
      //   { value: 1, text: '10'},
      //   { value: 2, text: '20'},
      //   { value: 3, text: '30'},
      //   { value: 4, text: '40'},
      //   { value: 5, text: '50'},
      //   { value: 6, text: '60'},
      //   { value: 7, text: '70'},
      //   { value: 8, text: '80'},
      //   { value: 9, text: '90'},
      //   { value: 10, text: '100'},

      // ],


      empSkillId:0,
      skillsSubCategoryId:0,
      skillLevelOutof100:0,
      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,
      //showReassignButton: false,
      showSaveButton: true,


      employeelist:null,

      employeeExperience:null,




      info:null,
      rowid:null,
      loading: true,
      errored: false,
      componentKey: 0,
    };
  },
  validations: {
    
  },
  props:{
    title:{
      type: String
    },
    skillModal:{
      type: Boolean
    },
    emprecord:{
      type: Object
    }
  },
  computed: {



  },
  mounted() {
    this.getAll();
    this.getSubCategories();
    // this.AddOrEditModal();
  },
  methods:{
    PopulateOptionsIn() {
      console.log("Skill Sub Categories:", this.skillSubCategories);

      // Check and remove matching elements from skillSubCategories
      this.skillSubCategories = this.skillSubCategories.filter(skill => {
        // Check if there is a matching skillsSubCategoryId in getEmpSkills
        const isMatch = this.skills.some(empSkill => empSkill.skillsSubCategoryId === skill.key);

        // If there's a match, filter out this skill
        return !isMatch;
      });

      console.log("Updated Skill Sub Categories:", this.skillSubCategories);
    },
    deleteSkill(skillId) {
    // Find the index of the skill with the given ID
    const skillIndex = this.skills.findIndex(skill => skill.id === skillId);
    // If the skill exists, remove it from the array
    if (skillIndex !== -1) {
      this.skills.splice(skillIndex, 1);
    }
  },
    AddskillsToList() {
      const existingSkill = this.skills.find(
      (skill) => skill.skillsSubCategoryId === this.skillsSubCategoryId
    );

    if (existingSkill) {
      // If a duplicate skill is found, set the error flag and stop the addition
      this.duplicateSkillError = true;
      alert("This Skill is already exist in your selected skills");
      return;
    }
    // Check if both a skill and a skill level have been selected
    if (this.skillsSubCategoryId != null && this.skillLevelOutof100 != null && this.skillsSubCategoryId != 0 && this.skillLevelOutof100 != 0) {
     
      this.skillObject.employeeId = Number(this.employeeId);
      this.skillObject.skillLevelOutof100 = Number(this.skillLevelOutof100*10);
      this.skillObject.skillsSubCategoryId = Number(this.skillsSubCategoryId);
      this.skillObject.skillName=this.getSkillNameById(this.skillsSubCategoryId)
      this.skills.push({ ...this.skillObject});
      console.log("this is the skill list ",this.skills)

      // Reset the dropdown and range inputs
      this.skillsSubCategoryId = 0; // Reset the selected skill
      this.skillLevelOutof100 = 0; // Reset the skill level

      // Optionally, reset the `skillObject` to its initial state if needed
      this.skillObject = { employeeId: this.employeeId, skillLevelOutof100: 0, skillsSubCategoryId: 0 };
      this.PopulateOptionsIn();
    } else {
      alert("Please select a skill and a skill level.");
      return;
    }

    console.log(this.skills); // Log the updated skills list
  },

  // Helper method to get the skill name by its ID
  getSkillNameById(skillId) {
    const skill = this.skillSubCategories.find(item => item.key === skillId);
    return skill ? skill.value : "Unknown Skill";
  },
    
    AddDropdownValue() {
      axios
        .post("/api/keyvaluepair/AddDropdown", {
          value: this.Text,
          type: 'SkillCategory',
        })
        .then((res) => {
          if (res.data != null) {
            this.displaymessage = true;
            this.getSubCategories();
          }
        });
    },
    resetPage(){
      this.$emit('reset-page');
      console.log("resettriggerrd")


    },
    skillSectionState(value){
      this.isSkillSection = value;
      this.$emit('change-state', this.isSkillSection);
      console.log("state triggered")
    },
    getAll(){
       this.rerender();
       this.skills=[];
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);
            this.employeeId = this.employeelist.employeeId;
            console.log("this is emplooyee skills :", res.data.eMPSkills);
            const fectchEMPskill=res.data.eMPSkills;
            fectchEMPskill.forEach(skill => {
              this.skillObject.employeeId=skill.employeeId;
              this.skillObject.skillName=skill.skillsSubCategory;
              this.skillObject.skillLevelOutof100=skill.skillLevelOutof100;
              this.skillObject.skillsSubCategoryId=skill.skillsSubCategoryId;
              this.skills.push({...this.skillObject, Oid: 'o'});
            });
            console.log( "this is the skills",this.skills);
            this.PopulateOptionsIn();


          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
   

    
    getSubCategories() {

      this.skillSubCategories = [];
      let model = {
        type: "SkillCategory"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {



          this.skillSubCategories = res.data.data;
          
          console.log("Add Response portion");

          console.log("testing skillSubCategories : ", this.skillSubCategories);
         this. PopulateOptionsIn();
         
        })
        .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },


    AddOrEditModal(){
      // this.rerender();
      if(this.emprecord != null && this.emprecord != ""){
        console.log("Not Null: ",this.emprecord);
        this.EditModalSkill(this.emprecord);

      }
      else{

        console.log("Null: ",this.emprecord);
        this.NewModalSkill();

      }
    },







    //Add Modal Skill
    NewModalSkill() {
      this.showCloseButton = true;
      this.showResetButton = true;
      this.reset();


      this.showSaveButton = true;
      this.addSkill_btn = true;
      this.removeSkill_btn = true;
      

    },
    //EDIT MODAL Skill
    EditModalSkill(emprecord) {
      this.loading = true;
      console.log("emprecord : ",emprecord);

      this.showCloseButton = true;
      this.showResetButton = false;

      this.addSkill_btn = false;
      this.removeSkill_btn = false;
      this.skills = [{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}]
      //Bind Model
      this.skills[0].employeeId = emprecord.employeeId;
      this.skills[0].empSkillId = emprecord.empSkillId;
      this.skills[0].skillLevelOutof100 = emprecord.skillLevelOutof100;
      this.skills[0].skillsSubCategoryId = emprecord.skillsSubCategoryId;
      console.log("employeeid : ",this.skills[0].employeeId)
      console.log("empSkillId : ",this.skills[0].empSkillId)
      console.log("skillLevelOutof100 : ",this.skills[0].skillLevelOutof100)
      console.log("skillsSubCategoryId : ",this.skills[0].skillsSubCategoryId)

      this.employeeId= emprecord.employeeId;
      this.empSkillId= emprecord.empSkillId;
      this.skillsSubCategoryId = emprecord.skillsSubCategoryId;
      this.skillLevelOutof100 = emprecord.skillLevelOutof100;


      this.loading = false;


    },

    addSkill(){
      this.skills.push({empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0})
    },
    removeSkill(index){
      this.skills.splice(index,1);
    },

    AddOrUpdate() {

      //skill Section
      if (this.title == "Edit Skill") {
        console.log("EditTitle : " + this.title);

        this.updateSkills();

      }
      else  {
        console.log("addTitle : " + this.title);
        if (this.skills.length>0) {
          this.AddskillsToList();
          this.addSkills();
        }else{
          this.AddskillsToList();
          this.addSkills();
        }
        
        //this.getAll();
      }
    },

    addSkills() {
      this.skills = this.skills.filter(skill => skill.Oid !== 'o');
      if (this.skills.length > 0) {
        axios
          .post("/api/EMPSkill/InsertEMPSkills", this.skills)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info);
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {
              this.rerender();
              this.skillSectionState(false);
              this.resetPage();
              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {
              this.rerender();
              this.skillSectionState(false);
              this.resetPage();
              this.$bvToast.toast("Skill Inserted Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });


            }
          });
        alert("skill insert sucesfully");
      } else {
        alert("Select the skills first");
      }

    },
    updateSkills(){
      if (
          this.skills[0].skillsSubCategoryId != null &&
          this.skills[0].skillLevelOutof100 != null
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.skills[0].empSkillId);
        //this.skills[0].employeeId = Number(this.employeeId);
        let model={
          empSkillId:Number(this.skills[0].empSkillId),
          employeeId:Number(this.skills[0].employeeId),
          skillsSubCategoryId:Number(this.skills[0].skillsSubCategoryId),
          skillLevelOutof100:Number(this.skills[0].skillLevelOutof100)
        };

        axios
            .post("/api/EMPSkill/UpdateEmployeeSkill", model)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                this.rerender();
                this.skillSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.rerender();
                this.skillSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Skill Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });


              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },
    reset() {
      this.skills=[{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}];

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
      this.$emit('update-Component-Key', this.componentKey);
    },
    mounted() {
    this.getAll();
    this.getSubCategories();
    // this.AddOrEditModal();
  }
  }
  
}
  

</script>
<style scoped>

#skillbutton{
  width: 9rem !important;
}
@media screen and (max-width: 720px) {
  #skillbutton{
  width: 20rem !important;
}
}
/* Basic reset for the slider container */
.range-slider {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  --min: 0;
  --max: 100;
  --step: 5;
  --value: 75;
  --text-value: "75";
}

/* Style for the range input */
.range-slider input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: var(--slider-background, #ddd);
  border-radius: 5px;
  outline: none;
  position: relative;
  z-index: 2;
}

/* Thumb style (the circular part you drag) */
.range-slider input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  background: transparent;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #0366d6;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.range-slider input[type="range"]::-moz-range-track {
  height: 10px;
  background: transparent;
}

.range-slider input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #0366d6;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.range-slider input[type="range"]::-ms-track {
  height: 10px;
  background: transparent;
  border-color: transparent;
  border-width: 10px;
  color: transparent;
}

.range-slider input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #0366d6;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Output display for the value */
.range-slider output {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: #333;
  font-weight: bold;
  white-space: nowrap;
  content: var(--text-value);
}

/* Progress bar styling */
.range-slider__progress {
  position: absolute;
  top: 50%;
  left: 0;
  height: 8px;
  background: #0366d6;
  border-radius: 5px;
  z-index: 1;
  transition: width 0.3s ease;
  transform: translateY(-50%);
}

/* Ensure the progress bar is updated dynamically */
.range-slider input[type="range"]:focus + .range-slider__progress {
  background: #005bb5;
}

.range-slider input[type="range"]:active + .range-slider__progress {
  background: #004a99;
}

/* Update the progress bar width based on the slider value */
.range-slider input[type="range"]:input {
  --progress-width: calc( (var(--value) - var(--min)) / (var(--max) - var(--min)) * 100% );
  .range-slider__progress {
    width: var(--progress-width);
  }
}
input[type="range"] {
  width: 100%;
  margin: 10px 0;
}
.skills-container {
  display: flex;               /* Use flexbox to align items horizontally */
  flex-wrap: wrap;             /* Allow items to wrap to the next line if necessary */
  gap: 10px;                   /* Add spacing between the tags */
}

.skill-tag {
  display: flex;               /* Flexbox for skill name and delete icon */
  align-items: center;         /* Vertically align the skill name and delete icon */
  background-color:  #EA216C;   /* Background color for the tag */
  padding: 5px 10px;           /* Padding inside the tag */
  margin: 5px;                 /* Margin between the tags */
  border-radius: 20px;         /* Rounded corners */
  font-size: 14px;             /* Font size */
  cursor: pointer;    
  color:white;        /* Show pointer on hover */
}

.delete-icon {
  margin-left: 8px;            /* Space between skill name and delete icon */
  cursor: pointer;  
  color: white;          /* Show pointer on hover */
}

.delete-icon:hover {
  color: red;                  /* Change color on hover */
}
.text-danger{
  color:white !important;
}
/* Styling the range input for all browsers */
/* input[type="range"] {
 
  background: #EA216C; /* Light background */

/* } */ 

.accent{
  accent-color: #EA216C;
}

</style>