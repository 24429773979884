import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import "./plugins/custom";
import App from './App.vue'
import VueRouter from "vue-router"
import router from './router'
import store from './store'

import axios from "axios";
import VueAxios from "vue-axios";
import "es6-promise/auto";
import VueAuth from "@websanova/vue-auth";
import auth from "./js/auth";
import moment from "moment";
import Vuelidate from "vuelidate";

import "./js/GoogleLoginAuth"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import GAuth from 'vue-google-oauth2'

// const gauthOption = {
//   clientId: '687431962450-ekkj198uohfda5ai3qugv6gkgjfhn8hu.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'consent',
//   fetch_basic_profile: true
// }
// Vue.use(GAuth, gauthOption)

Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

Vue.filter('formatDateAndTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY, hh:mm:ss a')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('hh:mm:ss a')
  }
});
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY')
  }
});

Vue.filter('formatDateMaxOrMin', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY-MM-DD')
  }
});

Vue.filter('formatEmpPackagePaymentStatus', function(value) {

  return value == 2 ? "Paid"  : "Un-Paid"

});

Vue.router = router;
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueAuth, auth);



// axios.defaults.baseURL = "https://demo.itrooscloud.net";
// http://localhost:57589/swagger/index.html
//  axios.defaults.baseURL = "http://localhost:57589";
// http://localhost:57589/swagger/index.html
// axios.defaults.baseURL = "http://localhost:57589";
// axios.defaults.baseURL = "https://smw-demo-fyadcxczcnfrgeh6.centralus-01.azurewebsites.net";
axios.defaults.baseURL = "https://smw-live.azurewebsites.net";


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
