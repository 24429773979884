import { render, staticRenderFns } from "./DeleteComponent.vue?vue&type=template&id=5992a429&scoped=true"
import script from "./DeleteComponent.vue?vue&type=script&lang=js"
export * from "./DeleteComponent.vue?vue&type=script&lang=js"
import style0 from "./DeleteComponent.vue?vue&type=style&index=0&id=5992a429&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5992a429",
  null
  
)

export default component.exports