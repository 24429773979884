<template>
  <div :id="mainContainer" class="m-5 ml-auto mr-auto" :key="componentKey">
    <div class="container">
      <div  class="row no-gutters w-100 mt-2">
        <div class="col-lg-2">
          <div class="">


            <div class="w3-card-4 w3-center custom-profile-card">
              <div class="pt-4 pb-4">
                <div>
                  <div class="custom-profile-image-wrap" >
                    <span class="b-avatar badge-info rounded-circle custom-b-avatar-profile">
                        <span  class="b-avatar-img">
                          <span v-if="emp.EmployeeImage != null && emp.EmployeeImage != ''">
                             <img :src="url+emp.EmployeeImage">
                           </span>
                           <span v-else>

                                <img src="../../assets/images/no-image.png" >


                           </span>

                        </span>

                      </span>
<!--                    <div v-if="emp.EmployeeImage != null">
                      <img :src="url+emp.EmployeeImage" alt="" class="rounded-circle custom-profile-image">
                    </div>
                    <div v-else>
                    -->

                    <div class="custom-change-profile-image-btn-wrap">
                      <a
                          class="custom-change-profile-image-btn"
                          href="#addorEditModal"
                          data-toggle="modal"
                          @click="NewModalImage"
                          title="Edit Profile Image"
                          data-backdrop="static"
                          data-keyboard="false"
                      >
                        <b-icon
                            font-scale="1.5"
                            class="text-white"
                            icon="camera-fill"
                        ></b-icon
                        ></a>
                    </div>
                  </div>
<!--                  <div class="custom-profile-image-wrap" v-else>
                    <div>
                      <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                    </div>


                    &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;
                    <div class="custom-change-profile-image-btn-wrap">
                      <a
                          class="custom-change-profile-image-btn"
                          href="#addorEditModal"
                          data-toggle="modal"
                          @click="NewModalImage"
                          title="Edit Profile Image"
                          data-backdrop="static"
                          data-keyboard="false"
                      >
                        <b-icon
                            font-scale="1.5"
                            class="text-white"
                            icon="camera-fill"
                        ></b-icon
                        ></a>
                    </div>
                  </div>-->

                </div>


                <div class=" w3-center custom-profile-detail">
                  <h5 class="font-weight-bold text-capitalize">

                    {{ emp.FullName }}

                  </h5>



                    <div class="text-capitalize" >
                      {{ latestExperience.jobTitle }}
                    </div>




<!--                  <section class="cd-intro">
                    <h5 class="cd-headline loading-bar">

                    <span class="cd-words-wrapper">
&lt;!&ndash;                      <b class="is-visible">.Net Developer</b>&ndash;&gt;
                      <b class="is-visible" v-for="exp in employeeExperience" :key="exp.empExperienceId">
                        {{ exp.jobTitle }}
                      </b>

                    </span>
                    </h5>

                  </section>-->

                  <div class="custom-profile-contacts">
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="phoneNumberLink+emp.mobileNumber">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
<!--                        <span>{{ emp.code }}</span>
                        <span>-</span>
                        <span>{{ emp.networkCode }}</span>
                        <span>-</span>-->
                        <span class="">{{ emp.mobileNumber }}</span>
                      </a>


                    </div>

                    <div class=" ">
                      <a class="text-decoration-none w3-hover-text-light-gray " :href="emailLink+emp.Email">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span class="" style="overflow-wrap: break-word;">{{ emp.Email }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="custom-row justify-content-center mt-2 ">
                    <a :href="'https://'+emp.FacebookLink" type="url" target="_blank" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-facebook-f"></i>
                    </a>

                    <a :href="'https://'+emp.GooglePlusLink" type="url" target="_blank" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-google"></i>
                    </a>
                    <a :href="'https://'+emp.LinkedInLink" type="url" target="_blank" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a :href="'https://'+emp.TwitterLink" type="url" target="_blank" class="custom-twitter-icon custom-icon-style  custom-social-link">
                      <i class="fab fa-twitter"></i>
                    </a>

                  </div>
                </div>



              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-7">

          <div id="MyPersonal" class="container">
            <div class="custom-profile-heading">
              <span>Personal Information</span>
            </div>

                      <router-link to="/add-profile-detail"
            class="custom-default-btn" style="float:right;"><i>Edit Personal Information</i></router-link>
            <div class="custom-underline"></div>
            <div class="custom-information-list mt-2">
              <div class="row">
                <div class="col-md-5">
                  <ul class="custom-list">
                    <!-- <li><span class="font-weight-bold">Age: </span><span v-if="calculatedAge != NaN">{{ calculatedAge }}</span><span v-else> No Age Added yet.</span></li> -->
                    <li><span class="font-weight-bold">FullName </span><span v-if="emp.FullName != null">{{ emp.FullName }}</span><span v-else> update your profile.</span></li>
                    <li><span class="font-weight-bold">CNIC: </span><span v-if="emp.CNIC != null">{{ emp.CNIC }}</span><span v-else> No CNIC Added yet.</span></li>
                    <li>
  <span class="font-weight-bold">Language: </span>
  <span>
    {{ emp.Languages }}
  </span>
</li>

<!--                      <span v-for="eexp in employeeExperience" :key="eexp.empExperienceId">-->
                      <!-- <span v-if="latestExperience.company != null">{{ latestExperience.company }}</span> -->
                      <!-- <span v-else>No Company Added yet.</span> -->
<!--                    </span>-->
                    <!-- </li> -->
                    <li v-if="emp.CurrentSalary != 0"><span class="font-weight-bold">Current Salary: </span><span>{{ emp.CurrentSalary }} PKR</span></li>
                    <li v-if="emp.CountryId == null"><span class="font-weight-bold">Domicile: </span><span v-if="emp.Domicile != null">{{ emp.Domicile }}</span><span v-else>No Domicile Added yet.</span></li>
                    <li v-else>
                      <span class="font-weight-bold">Country: </span>
                        <span v-for="option in countries" :key="option.key">
                          <span v-if="emp.CountryId == option.key">
                            {{ option.value }}
                          </span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-md-7">
                  <ul class="custom-list">
                    <li><span class="font-weight-bold">Marital Status: </span>
                      <span v-for="option in maritalStatusList" :key="option.key">
                        <span v-if="emp.MaritalStatusId === option.key">
                          {{ option.value }}
                        </span>
                    </span>
                    </li>
                    <li>
                      <!-- <span class="font-weight-bold">Degree Title: </span> -->
                      <span class="font-weight-bold">Email: </span>
                      <span>
                        {{ emp.Email }}
                      </span>
<!--                      <span v-for="edu in employeeEducation" :key="edu.empEducationId">-->
                        <!-- <span v-if="latestDegreeLevel.degreeTitle != null">{{ latestDegreeLevel.degreeTitle }}</span>
                        <span v-else>No Degree Added yet.</span> -->

<!--                      </span>-->
                    </li>
                    <li><span class="font-weight-bold">Experience: </span>
                      <span v-for="option in yearsOfExperiences" :key="option.key">
                        <span v-if="emp.YearOfExperienceId === option.key">
                          {{ option.value }}
                        </span>
                      </span>
                    </li>
                    <li v-if="emp.ExpectedSalary != 0"><span class="font-weight-bold">Expected Salary: </span><span>{{ emp.ExpectedSalary }} PKR</span></li>
                    <li v-if="emp.CountryId == null">
                      <span class="font-weight-bold">City: </span>
                        <span v-for="option in cities" :key="option.key">
                          <span v-if="emp.CityId === option.key">
                            {{ option.value }}
                          </span>
                      </span>
                    </li>
                    <li v-else>
                      <span class="font-weight-bold">City: </span>

                          <span>
                            {{ emp.OtherCityName }}
                          </span>

                    </li>
                  </ul>
                </div>
              </div>

            </div>

            <div class="mb-3" >
              <ul class="custom-list">
                <li>
                  <span class="font-weight-bold">Category: </span>
                  <ul class="custom-list pl-4">
                    <li>
                      <span v-if="emp.Graduate === 24">Graduate</span>
                      <span v-else></span>

                    </li>
                    <li>
                      <span v-if="emp.MomReturn === 25">Mums Return To Work</span>
                      <span v-else></span>
                    </li>
                    <li>
                      <span v-if="emp.SpecialNeedyPerson === 26">Persons With Disabilities (PWD)</span>
                      <span v-else></span>
                    </li>
                    <li>
                      <span v-if="emp.LabourManPower === 27">Labour Man Power</span>
                      <span v-else></span>
                    </li>
                    <li>
                      <span v-if="emp.Hecancy === 30">Hecancy</span>
                      <span v-else></span>
                    </li>
                  </ul>
                </li>

              </ul>
              <div id="MyCategories" class="container">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1" v-if="emp.Graduate == 24">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-1  class="custom-bg-color" >
                    <span>Graduate</span>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    
                    <b-card-text>
                      <div class="custom-information-list mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Availability of Internship: </span>
                                <span>{{ emp.AvailableDate | formatDate}}</span>
                                

                              </li>
                              
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li><span class="font-weight-bold">Internship Category: </span>
                                <span v-for="wp in workPlacements" :key="wp.graduateWorkPlacementTypeId">
                                  <span v-if="emp.GraduateWorkPlacementTypeId === wp.graduateWorkPlacementTypeId">
                                    {{ wp.graduateWorkPlacementTitle }}
                                  </span>
                                  <span v-else></span>
                                </span>
                              </li>
                              
                            </ul>
                          </div>
                        </div>
                        <div class="row" v-if="emp.GraduateWorkPlacementTypeId === 3">
                          <div class="col-md-12">
                            <ul class="custom-list">
                              <li >
                                <div class="font-weight-bold">Service Description: </div>
                                <div class="text-justify">{{ emp.ServiceDescription}}</div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li><span class="font-weight-bold">Category: </span>
                                <span v-for="cat in skillsCategories" :key="cat.key">
                                  <span v-if="emp.SkillsCategoryId === cat.key">
                                    {{ cat.value }}
                                  </span>
                                  <span v-else></span>
                                </span>
                              </li>

                              
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Sub-Category: </span>
                                <span >
<!--                                  <span v-if="emp.SkillsSubCategoryId === sbcat.skillsSubCategoryId">-->
                                    {{ emp.SubCategory }}
<!--                                  </span>-->
                                </span>
                              </li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>

                      
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              
              <b-card no-body class="mb-1" v-if="emp.MomReturn == 25">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-2 class="custom-bg-color" >
                    <span>Mums Return To Work</span>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="custom-information-list mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li><span class="font-weight-bold">Current Employment Status: </span>
                                <span v-for="status in employeeStatuses" :key="status.value">
                                  <span v-if="emp.CurrentEmployementStatus === status.value">
                                    {{ status.text }}
                                  </span>
                                </span>
                              </li>
                              
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <ul class="custom-list">
                              
                            </ul>
                          </div>
                        </div>
                        <h6 class="font-weight-bold">Career Pause</h6>
                        <div class="row">
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Start Career: </span>
                                <span>{{ emp.StartCareer | formatDate }}</span>
                                

                              </li>
                              
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">End Career: </span>
                                <span>{{ emp.EndCareer | formatDate }}</span>
                              </li>                            </ul>
                                

                          </div>
                        </div>
                        <h6 class="font-weight-bold">Description</h6>
                        <div class="text-justify">{{ emp.CPDescription }}</div>
                        <div class="row">
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Work Hours: </span>
                                <span v-for="wh in employementWorkHours" :key="wh.key">
                                  <span v-if="emp.WeeklyWorkingHour === wh.key">
                                    {{ wh.value }}
                                  </span>
                                </span>
                              </li>
                              <li>
                                <span class="font-weight-bold">
<!--                                  Willing to Commute Distance: -->
                                  Travel Preference:
                                </span>
<!--                                <span v-for="dis in kms" :key="dis.value">-->
                                  <span v-if="emp.TravelMiles === 1">
                                    Yes
                                  </span>
                                <span v-else-if="emp.TravelMiles === 2">
                                    No
                                  </span>
                                <span v-else>
                                  </span>
<!--                                </span>-->
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Work Place: </span>
                                <span v-for="wp in employementWorkWhere" :key="wp.value">
                                  <span v-if="emp.WorkLocation === wp.value">
                                    {{ wp.text }}
                                  </span>
                                </span>
                              </li>
                              <li>
                                <span class="font-weight-bold">Percentage of Time Travelling: </span>
                                <span v-for="travel in travels" :key="travel.value">
                                  <span v-if="emp.TravelTimePercent === travel.value">
                                    {{ travel.text }}
                                  </span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              
              <b-card no-body class="mb-1" v-if="emp.SpecialNeedyPerson == 26">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle.accordion-3 class="custom-bg-color" >
                    <span >Persons With Disabilities (PWD’s)</span>
                  </b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="custom-information-list mt-2">
                        <div class="row">
                          <div class="col-md-12">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Disability Information: </span>
                                <span class="text-justify">{{ emp.DisabilityInformationTitle }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">


                            
                            <ul class="custom-list">                              <li>
                                <span class="font-weight-bold">Disability Certificate: </span>
                                <span v-if="emp.DisabilityCertificate === 1">Yes</span>
                                <span v-else-if="emp.DisabilityCertificate === 2">No</span>
                                <span v-else></span>
                              </li>                              <li>
                                

                                <span class="font-weight-bold">Knowledge of Sign Language: </span>
                                <span v-if="emp.IsKnowSignLangauge === 1">Yes</span>
                                <span v-else-if="emp.IsKnowSignLangauge === 2">No</span>
                                <span v-else-if="emp.IsKnowSignLangauge === 3">Not Applicable</span>
                                <span v-else></span>
                              </li>                              <li>
                                

                                <span class="font-weight-bold">Tranportation: </span>
                                <span v-if="emp.FillBy === 1">Yes</span>
                                <span v-else-if="emp.FillBy === 2">No</span>
                                <span v-else></span>
                              </li>
                              <li>
                                <span class="font-weight-bold">Job Preference: </span>
                                <span>{{ emp.KindofJob }}</span>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Disability Type: </span>
                                <span v-for="type in disabilityType" :key="type.key"
                                  <span v-if="emp.DisabilityTypeId === type.key">
                                    {{ type.value }}
                                  </span>
                               
                              </li>
                              <li v-if="emp.DisabilitySeverity === 1">
                                <span class="font-weight-bold">Disability Severity: </span>
                                <span v-if="emp.DisabilitySeverity === 1">Mild</span>
                              </li>
                              <li v-else-if="emp.DisabilitySeverity === 2">
                                <span class="font-weight-bold">Disability Severity: </span>
                                <span v-if="emp.DisabilitySeverity === 2">Moderate</span>                                </li>
                                

                              <li v-else-if="emp.DisabilitySeverity === 3">
                                <span class="font-weight-bold">Disability Severity: </span>
                                <span v-if="emp.DisabilitySeverity === 3">Severe</span>
                              </li>
                              <li v-else>
                                <span class="font-weight-bold">Disability Severity: </span>
                                <span v-if="emp.DisabilitySeverity === 4">Profound</span>
                              </li>
                              <li>
                                <span class="font-weight-bold">Cause Of Disability: </span>
                                <span v-for="cause in causesOfDisability" :key="cause.key">
                                  <span v-if="emp.CauseOfDiabilityTypeId === cause.key">
                                    {{ cause.value }}
                                  </span>
                                </span>
                              </li>

                              
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <ul class="custom-list">
                              <li>
                                <span class="font-weight-bold">Special Assistance is required: </span>
                                <span class="text-justify">{{ emp.SpecialAssistanceRequirment }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
              <!--            <h5>Choose a Category:</h5>
                          <select
                              class="custom-select-option form-control form-control-sm"
                              v-model="selectedCategory"
                          >
                            <option
                                v-for="option in categories"
                                :key="option.jobTypeId"
                                :value="option.jobTypeId"
                            >
                              {{ option.jobTypeTitle }}
                            </option>
                          </select>-->
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <ProfileStrength :profileStrengthSection="true" @strength-state="getStrengthStates"></ProfileStrength>

          <div class="row">
            <div class="col-12 text-center mt-2">
              <div class="" v-if="uploadCVBtn">
                <a
                    class="custom-default-btn"
                    href="#addorEditModal"
                    data-toggle="modal"
                    @click="NewModalUploadCV"
                    title="Upload CV"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                  <b-icon
                      class="mr-2"

                      icon="upload"
                  ></b-icon
                  >Upload CV</a>
              </div>

                <div class="" v-if="updateCVBtn">
                  <div class="mb-2 text-center">
                    <span class="font-weight-bold">File Name: </span>
                    <span class="custom-text-color"><i>{{ emp.FileName}}</i></span>

                  </div>
                  <div class="custom-row justify-content-center">
                    <a
                        class="custom-default-btn"
                        href="#addorEditModal"
                        data-toggle="modal"
                        @click="EditModalUploadCV"

                        title="Update CV"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                      class="mr-1"

                          icon="arrow-clockwise"
                      ></b-icon
                      >Update CV</a>
                    <span class="ml-2"></span>
                    <a
                        v-if="emp.Resume != null && emp.Resume != ''"
                        class="custom-default-btn"
                        :href="url+emp.Resume"


                        title="Download CV"

                    >
                      <b-icon
                      class="mr-1"

                          icon="download"
                      ></b-icon
                      >Download CV</a>
                  </div>

                  
                </div>



              <!--            <button type="button" class="btn btn-sm btn-soft-primary" @click="exportToPDF">Upload
                            CV</button>-->
            </div>
            
          </div>
          <div class="row">
            <div class="col-12 text-center mt-2">
              <div class="" v-if="uploadpdfBtn">
                <a
                    class="custom-default-btn"
                    href="#addorEditModal"
                    data-toggle="modal"
                    @click="NewModalUploadPdf"
                    title="Upload PDF"
                    data-backdrop="static"
                    data-keyboard="false"
                >
                  <b-icon
                      class="mr-2"

                      icon="upload"
                  ></b-icon
                  >Upload Assessment</a>
              </div>

                <div class="" v-if="updatepdfBtn">
                  <div class="text-center">
                    <span class="font-weight-bold">Assessment File Name: </span>
                    <span class="custom-text-color"><i>{{ emp.PdfFileName}}</i></span>

                  </div> 
                  <div class="custom-row justify-content-center">
                    <a
                        class="custom-default-btn"
                        href="#addorEditModal"
                        data-toggle="modal"
                        @click="EditModalUploadPdf"

                        title="Update Assessment"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                          icon="arrow-clockwise"
                      ></b-icon>
                      Update Assessment</a>
                    <span class="ml-2"></span>
                      
                    <a
                        v-if="emp.PDfName != null && emp.PDfName != ''"
                        class="custom-default-btn"
                        :href="url+emp.PDfName"


                        title="Download CV"

                    >
                      <b-icon
                          class="mr-1"

                          icon="download"
                      ></b-icon
                      >Download Assessment</a>
                  </div>

                  
                </div>



              <!--            <button type="button" class="btn btn-sm btn-soft-primary" @click="exportToPDF">Upload
                            CV</button>-->
            </div>
        </div>
      </div>
      <!--SPACER-->
      <div class="style-spacer"></div>
      <div  class="row no-gutters" style="width: 75%; height:fit-content;">
        <div class="col-lg-2 w-100 h-100 banner-container">
          <div v-if="sMWCoursesAdd.length != 0" class="w-100 h-100 text-center ">
            <a :href="sMWCoursesAdd[0].url" :title="sMWCoursesAdd[0].url" target="_blank" class="navbar-brand mr-0">
              <img :src="url+sMWCoursesAdd[0].image" class="advertisementimage" />
            </a>
          </div>
          <div v-else class="w-100 h-100 text-center">
            <img src="../../assets/images/viilearn vertical.jpg" class="advertisementimage" style="height: 800px; width: 160%;"/>
          </div>
        </div>
        <div  class="col-lg-7 mt-5" style="padding-left:10%;">
          <div class="m-4">
            <div id="MyProfessional" class="w-100">
              <div  class="custom-profile-heading">
                <span>Professional Brief</span>
              </div>
              <div class="custom-underline"></div>
              <h4 class="text-secondary font-weight-bold">I'm <span class="text-capitalize">{{ emp.FullName }}</span></h4>
              <p class="text-justify">{{ emp.ProfessionalSummary }}</p>
            </div>
            <!--SPACER-->
            <div class="style-spacer"></div>
            <div id="MyExperience" class="w-100">
              <div  class="custom-profile-heading ">
                <div class="custom-row w-100">
                  <span class="flex-grow-1">Experience</span>


                </div>

              </div>
              <div class="custom-underline"></div>
              <div class="custom-row">

                <div class="resume-icon flex-grow-1">
                  <i class="fas fa-briefcase"></i>

                </div>
                <!--end resume-icon-->


                <div class="">
                  <a
                      href="#addorEditModal"
                      data-toggle="modal"
                      @click="NewModalExperience()"
                      title="Add Experience"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    <div class="custom-icon icon">
                      <plus-icon size="2x" class="custom-class"></plus-icon>

                    </div>
                  </a>
                </div>
                <!--                <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button" ><i
                                    class="fas fa-edit"></i></router-link>-->

              </div>
              <div v-for="(experience, index) in employeeExperience" :key="experience.empExperienceId">
                <div class="custom-row w-100">
                  <div class="flex-grow-1">
                    <h5 class="font-weight-bold custom-text-color text-capitalize">{{ experience.jobTitle }}</h5>
                  </div>


                  <div>
                    <a
                        href="#addorEditModal"
                        data-toggle="modal"
                        @click="EditModalExperience(experience)"
                        title="Edit Experience"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                          font-scale="1.8"
                          variant="success"
                          icon="pencil-square"
                      ></b-icon
                      ></a>
                  </div>

                  <div class="w3-border-right ml-2 mr-2" style="height: 5vh"></div>
                  <div>
                    <a
                        href="javascript:void(0)"
                        @click="getExperienceRow(experience)"
                        data-toggle="modal"
                        title="Delete Experience"
                    >
                      <b-icon font-scale="1.8" variant="danger" icon="trash"></b-icon
                      ></a>
                  </div>


                </div>

                <h6 class="font-weight-bold">Description</h6>
                <div class="text-justify">{{ experience.experienceDescription }}</div>
                <div class="">


                  <div class="custom-information-list mt-2">
                    <div class="row">
                      <div class="col-md-6">
                        <ul class="custom-list">
                          <li><span class="font-weight-bold">Company Name: </span><span>{{ experience.company}}</span></li>
<!--                          <li><span class="font-weight-bold">Position: </span><span>{{ experience.position }}</span></li>-->
                          <li>
                            <span class="font-weight-bold">Salary (PKR): </span>
                            <span v-for="option in salaries" :key="option.value">

                              <span v-if="option.value == experience.salary">
                                {{ option.text }}
                              </span>
                              <span v-else></span>

                            </span>
                          </li>
                          <li v-if="experience.countryId != null">
                            <span class="font-weight-bold">Country: </span>
                            <span v-for="option in countries" :key="option.key">
                                <span v-if=" option.key === experience.countryId">
                                  {{ option.value }}
                                </span>
                            </span>
                          </li>

                          <li ><span class="font-weight-bold">Start Date: </span><span>{{ experience.startDateMonthYear | formatDate }}</span></li>
                          <li><span class="font-weight-bold">Reference Email: </span>
                            <span>{{ experience.referenceEmail }}</span>
                          </li>
                          <li v-if="experience.countryId1 != null">
                            <span class="font-weight-bold">Reference Country: </span>
                            <span v-for="option in countries" :key="option.key">
                                <span v-if=" option.key === experience.countryId1">
                                  {{ option.value }}
                                </span>
                            </span>
                          </li>







                        </ul>
                      </div>
                      <div class="col-md-6">
                        <ul class="custom-list">
                          <li><span class="font-weight-bold">Job Title: </span><span>{{ experience.jobTitle }}</span></li>
                          <li v-if="experience.cityId != null">
                            <span class="font-weight-bold">City: </span>
                            <span v-for="option in cities" :key="option.key">
                                <span v-if=" option.key === experience.cityId">
                                  {{ option.value }}
                                </span>
                            </span>
                          </li>
                          <li v-if="experience.cityName != null && experience.cityName != ''">
                            <span class="font-weight-bold">City: </span>

                                  {{ experience.cityName }}

                          </li>
                          <li v-if="experience.currentlyWorking">
                            <span class="font-weight-bold">Currently Working: </span>
                            <span>Yes</span>
                          </li>
                          <li v-else><span class="font-weight-bold">End Date: </span><span>{{ experience.endDateMonthYear | formatDate }}</span></li>

                          <li><span class="font-weight-bold">Reference Number: </span>
                            <span>{{ experience.referenceNumber }}</span>
                          </li>




                        </ul>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </div>
            <!--SPACER-->
            <div class="style-spacer"></div>
            <div id="MyEducation" class="w-100">
              <div  class="custom-profile-heading">
                <span>Education</span>
              </div>
              <div class="custom-underline"></div>
              <!--            <label class="form-label">Help us match you with your next job Desired Job Title</label>
                          <input type="text" class="form-control" placeholder="" />-->
              <div class="custom-row">

                <div class="resume-icon flex-grow-1">
                  <i class="fas fa-graduation-cap"></i>

                </div>
                <!--end resume-icon-->
                <div class="">
                  <a
                      href="#addorEditModal"
                      data-toggle="modal"
                      @click="NewModalEducation()"
                      title="Add Education"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    <div class="custom-icon icon">
                      <plus-icon size="2x" class="custom-class"></plus-icon>

                    </div>
                  </a>
                </div>

                <!--                <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button"><i
                                    class="fas fa-edit"></i></router-link>-->

              </div>
              <div v-for="(eedu, index) in employeeEducation" :key="eedu.empEducationId">
                <div class="custom-row w-100">
                  <div class="flex-grow-1">
                    <h5 class="font-weight-bold custom-text-color text-capitalize">{{ eedu.degreeTitle}}</h5>
                  </div>


                  <div>
                    <a
                        href="#addorEditModal"
                        data-toggle="modal"
                        @click="EditModalEducation(eedu)"
                        title="Edit Education"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                          font-scale="1.8"
                          variant="success"
                          icon="pencil-square"
                      ></b-icon
                      ></a>
                  </div>

                  <div class="w3-border-right ml-2 mr-2" style="height: 5vh"></div>
                  <div>
                    <a
                        href="javascript:void(0)"
                        @click="getEducationRow(eedu)"
                        data-toggle="modal"
                        title="Delete Education"
                    >
                      <b-icon font-scale="1.8" variant="danger" icon="trash"></b-icon
                      ></a>
                  </div>


                </div>
                <h6 class="font-weight-bold">Description</h6>
                <div class="text-justify">{{ eedu.educationDescription }}</div>
                <div class="">


                  <div class="custom-information-list mt-2">
                    <div class="row">
                      <div class="col-md-6">
                        <ul class="custom-list">
                          <li><span class="font-weight-bold">Degree Title: </span><span>{{ eedu.degreeTitle}}</span></li>
                          <li v-if="eedu.universityName != '' && eedu.universityName != null">

                            <span  class="font-weight-bold">University: </span><span>{{ eedu.universityName }}</span>

                          </li>
                          <li v-else-if="eedu.institute != '' && eedu.institute != null">


                            <span  class="font-weight-bold">Institute: </span><span>{{ eedu.institute }}</span>
                          </li>
                          <li v-else>


                            <span  class="font-weight-bold">Institute: </span><span>{{ eedu.notInPakistan }}</span>
                          </li>


                          <li v-if="eedu.studentType == 'Student'"><span class="font-weight-bold">Expected Graduation Year: </span><span>{{ eedu.expectedGraduationYear | formatDate }}</span></li>
                          <li v-if="eedu.studentType == 'Graduate'"><span class="font-weight-bold">Graduation Completion Year: </span>
                            <span>{{ eedu.graduationCompletionYear | formatDate }}</span>
                          </li>

                        </ul>
                      </div>
                      <div class="col-md-6">
                        <ul class="custom-list">
                          <li><span class="font-weight-bold">Degree Level: </span><span>{{ eedu.degreeLevel }}</span>
<!--                            <span v-for="dl in degreeLevels" :key="dl.key">
                              <span v-if="eedu.degreeLevelId === dl.key">
                                {{ dl.value }}
                              </span>
                            </span>-->
                          </li>

                          <li><span class="font-weight-bold">CGPA/Percentage: </span>
                            <span>{{ eedu.cgpaPercentage }}</span>
                          </li>

                          <li>
                            <span class="font-weight-bold">Country: </span>
                            <span v-for="option in countries" :key="option.key">
                                <span v-if=" option.key === eedu.countryGNId">
                                  {{ option.value }}
                                </span>
                            </span>
                          </li>



<!--                          <li><span class="font-weight-bold">End Date: </span><span>{{ eedu.endDate | formatDate }}</span></li>-->

                        </ul>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <ul class="custom-list">
                          <li>
                            <div class="font-weight-bold ">Project Title: </div>
                            <div class="text-justify">{{ eedu.academicProjectDetail }}</div>
                          </li>
                          <li>
                            <div class="font-weight-bold ">Project Detail: </div>
                            <div class="text-justify">{{ eedu.projectDetails }}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>


                </div>
              </div>


            </div>
            <div id="Skills" class="w-100">
              <div class="custom-profile-heading">
                <span>Skills</span>
              </div>
              <div class="custom-underline"></div>
              <div class="custom-row">

                <div class="resume-icon flex-grow-1">
                  <i class="fas fa-sliders-h"></i>

                </div>
                <!--end resume-icon-->

                <div class="">
                  <a
                      href="#addorEditModal"
                      data-toggle="modal"
                      @click="NewModalSkill()"
                      title="Add Skill"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    <div class="custom-icon icon">
                      <plus-icon size="2x" class="custom-class"></plus-icon>

                    </div>
                  </a>
                </div>

              </div>
              <div v-for="(skill, index) in employeeSkill" :key="skill.empSkillId">
                <div class="custom-row w-100">
                  <div class="flex-grow-1">
                    <h5 class="font-weight-bold custom-text-color" v-for="data in skillSubCategories" :key="data.key">
                      <span v-if="skill.skillsSubCategoryId == data.key">
                        {{ data.value }}
                      </span>
                    </h5>
                  </div>


                  <!-- <div>
                    <a
                        href="#addorEditModal"
                        data-toggle="modal"
                        @click="EditModalSkill(skill)"
                        title="Edit Skill"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                          font-scale="1.8"
                          variant="success"
                          icon="pencil-square"
                      ></b-icon
                      ></a>
                  </div>

                  <div class="w3-border-right ml-2 mr-2" style="height: 5vh"></div> -->
                  <div>
                    <a
                        href="javascript:void(0)"
                        @click="getSkillRow(skill)"
                        data-toggle="modal"
                        title="Delete Skill"
                    >
                      <b-icon font-scale="1.8" variant="danger" icon="trash"></b-icon
                      ></a>
                  </div>


                </div>
                <label class="">{{ skill.skillsSubCategoryTitle }}</label>
                <b-progress variant="secondary" :max="100"  :value="skill.skillLevelOutof100" height="3px" class="mb-3"></b-progress>
              </div>
            </div>
            <div class="style-spacer"></div>
            <div id="MyProjects" class="w-100">
              <div  class="custom-profile-heading">
                <span>Projects</span>
              </div>
              <div class="custom-underline"></div>
              <!--            <label class="form-label">Help us match you with your next job Desired Job Title</label>
                          <input type="text" class="form-control" placeholder="" />-->
              <div class="custom-row">

                <div class="resume-icon flex-grow-1">
                  <i class="fas fa-graduation-cap"></i>

                </div>
                <!--end resume-icon-->
                <div class="">
                  <a
                      href="#addorEditModal"
                      data-toggle="modal"
                      @click="NewModalProject()"
                      title="Add Project"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    <div class="custom-icon icon">
                      <plus-icon size="2x" class="custom-class"></plus-icon>

                    </div>
                  </a>
                </div>

              </div>
<!--                <div class="">
                  <a
                      href="#addorEditModal"
                      data-toggle="modal"
                      @click="NewModalEducation()"
                      title="Add Education"
                      data-backdrop="static"
                      data-keyboard="false"
                  >
                    <div class="custom-icon icon">
                      <plus-icon size="2x" class="custom-class"></plus-icon>

                    </div>
                  </a>
                </div>-->

                <!--                <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button"><i
                                    class="fas fa-edit"></i></router-link>-->
              </div>
              <div v-for="(epro, index) in employeeProjects" :key="epro.projectId">
                <div class="custom-row w-100">
                  <div class="flex-grow-1">
                    <h5 class="font-weight-bold custom-text-color text-capitalize">{{ epro.projectTitles }}</h5>
                  </div>


                  <div>
                    <a
                        href="#addorEditModal"
                        data-toggle="modal"
                        @click="EditModalProject1(epro)"
                        title="Edit Project"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                          font-scale="1.8"
                          variant="success"
                          icon="pencil-square"
                      ></b-icon
                      ></a>
                  </div>

                  <div class="w3-border-right ml-2 mr-2" style="height: 5vh"></div>
                  <div>
                    <a
                        href="#deleteModal"
                        @click="getProjectRow(epro)"
                        data-toggle="modal"
                        title="Delete Project"
                    >
                      <b-icon font-scale="1.8" variant="danger" icon="trash"></b-icon
                      ></a>
                  </div>


                </div>
                <h6 class="font-weight-bold">Description</h6>
                <div class="text-justify">{{ epro.projectDescription }}</div>
<!--                <div class="">


                  <div class="custom-information-list mt-2">
                    <div class="row">
                      <div class="col-md-12">
                        <ul class="custom-list">
                          <li><span class="font-weight-bold">Project Title: </span><span>{{ epro.projectTitles }}</span></li>

                        </ul>
                      </div>

                    </div>

                  </div>


                </div>-->
              </div>

            </div>
            <div class="style-spacer"></div>
            <div id="MyCourses" class="w-100" style="padding-left: 5%;">
              <div  class="custom-profile-heading">
                <span>Vii Learn Courses</span>
              </div>
              <div class="custom-underline"></div>

              <div class="custom-row">

                <div class="resume-icon flex-grow-1">
                  <i class="fas fa-book"></i>

                </div>

                                <div class="">
                                  <a
                                      href="#addorEditModal"
                                      data-toggle="modal"
                                      @click="NewModalCourse()"
                                      title="Add Course"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                  >
                                    <div class="custom-icon icon">
                                      <plus-icon size="2x" class="custom-class"></plus-icon>

                                    </div>
                                  </a>
                                </div>


              </div>
<!--              <div v-for="(epro, index) in employeeProjects" :key="epro.projectId">
                <div class="custom-row w-100">
                  <div class="flex-grow-1">
                    <h5 class="font-weight-bold custom-text-color text-capitalize"><i class="">{{ epro.projectTitles }}</i></h5>
                  </div>


                  <div>
                    <a
                        href="#addorEditModal"
                        data-toggle="modal"

                        title="Edit Course"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                      <b-icon
                          font-scale="1.8"
                          variant="success"
                          icon="pencil-square"
                      ></b-icon
                      ></a>
                  </div>

                  <div class="w3-border-right ml-2 mr-2" style="height: 5vh"></div>
                  <div>
                    <a
                        href="#deleteModal"

                        data-toggle="modal"
                        title="Delete Course"
                    >
                      <b-icon font-scale="1.8" variant="danger" icon="trash"></b-icon
                      ></a>
                  </div>


                </div>
                <h6 class="font-weight-bold">Description</h6>
                <span>{{ epro.projectDescription }}</span>
                <div class="">


                  <div class="custom-information-list mt-2">
                    <div class="row">
                      <div class="col-md-12">
                        <ul class="custom-list">
                          <li><span class="font-weight-bold">Project Title: </span><span>{{ epro.projectTitles }}</span></li>

                        </ul>
                      </div>

                    </div>

                  </div>


                </div>
              </div>-->

            </div>
          </div>

          <!-- Add/EDIT Modal Start -->
          <div
              class="modal fade p-0"
              id="addorEditModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="addorEditModalLabel"
              aria-hidden="true"

          >
            <div class="modal-dialog modal-lg" role="document">
              <form
                  class="modal-content"
                  @reset="reset()"
                  v-if="show"
                  autocomplete="off"
              >
                <div class="modal-header">
                  <h5 class="modal-title" id="addorEditModalLabel">
                    {{ modalTitle }}
                  </h5>
                
                
                  <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div v-if="profileSection">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3 form-group row no-gutters" v-if="profileImage">
                          <label class="col-sm-3 col-form-label col-form-label-sm" >Choose Profile Image</label>

                          <div class="col-sm-9">
                            <b-form-file id="file-small" accept="image/*" size="sm" @change="onSelectImage"></b-form-file>
                          </div>
                        </div>
                        <div class="mb-3 form-group row no-gutters" v-if="uploadCV">

                          <label class="col-sm-3 col-form-label col-form-label-sm" >Choose File</label>
                          <div class="col-sm-9">
                            <b-form-file id="file-small" accept=".pdf" size="sm" @change="onSelectCV"></b-form-file>
                          </div>
                          <h6>Please upload your CV in PDF format, Maximum size for the CV should be 2 MB.</h6>
                        </div>
                        <div class="mb-3 form-group row no-gutters" v-if="uploadpdf">

                              <label class="col-sm-3 col-form-label col-form-label-sm" >Choose File</label>
                              <div class="col-sm-9">
                                <b-form-file id="file-small" accept=".pdf" size="sm" @change="onSelectpdf"></b-form-file>
                              </div>
                              <h6>Please upload your Assessment PDF, Maximum size for the Assessment should be 2 MB.</h6>
                              </div>
                          
                        </div>
                    </div>
                  </div>
                  <!-- Add/Edit Experience Start  -->
                  <div v-if="experienceSection">
                    <AddEditExperienceComponent :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord" :empprojectrecord="employeeProjectRecord" @navigate-form="navigate" @reset-page="refreshPage" @toggle-modal="closeModal"></AddEditExperienceComponent>
                  </div>
                  <!-- Add/Edit Experience End  -->
                  <!-- Add/Edit Education Start  -->


                  <div v-if="educationSection">

                    <AddEditEducationComponent :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord" @navigate-form="navigate" @reset-page="refreshPage" @toggle-modal="closeModal"></AddEditEducationComponent>

                  </div>
                  <!-- Add/Edit Education End  -->
                  <!-- Add/Edit Skill Start  -->
                  <div v-if="skillSection">
                    <AddEditSkillsComponent  :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord" @reset-page="refreshPage" @toggle-modal="closeModal"></AddEditSkillsComponent>

                  </div>
                  <!-- Add/Edit Skill End  -->
                                  <!-- Add/Edit Skill Start  -->
                    <div v-if="ProjectSection">
                    <AddEditProjectComponent  :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord" :empprojectrecord="employeeProjectRecord" @reset-page="refreshPage" @toggle-modal="closeModal"></AddEditProjectComponent>

                  </div>
                  <!-- Add/Edit Skill End  -->
                  <!-- Add/Edit Skill Start  -->
                  <div v-if="courseSection">
                    In Maintenance mode

                  </div>
                  <!-- Add/Edit Skill End  -->
                </div>
                <div class="modal-footer" v-if="isModalFooterVisible">
                  <div v-if="showCloseButton">
                    <b-button
                        id="closeButton"
                        class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                        type="button"
                        size="sm"
                        variant="outline-dark"
                        data-dismiss="modal"
                    >Close
                    </b-button>
                  </div>
                  <div v-if="showResetButton">
                    <b-button
                        id="resetButton"
                        class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                        type="reset"
                        size="sm"
                        variant="outline-dark"
                    >Reset
                    </b-button>
                  </div>

                  <div>
                    <b-button
                        v-if="showSaveButton"
                        class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                        @click="AddOrUpdate()"
                        size="sm"
                        variant="outline-dark"
                    >Save
                    </b-button>
                  </div>

                </div>
              </form>
            </div>
          </div>
          <!-- Add Modal End -->
          <!-- Delete Modal Start -->
          <DeleteModal v-if="isModalVisible" :title="modalTitle"  :exp-record="experienceRecord" :pro-record="projectRecord" :edu-record="educationRecord" :skill-record="skillRecord" @reset-page="refreshPage" @toggle-modal="closeModal"></DeleteModal>
<!--          <div
              class="modal fade"
              id="deleteModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                  <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span class="mr-4 ml-2">
                    <b-icon
                        icon="exclamation-triangle"
                        variant="danger"
                        scale="2"
                    ></b-icon>
                  </span>
                  <span> Are you sure you want to delete this {{ modalTitle }}? </span>
                </div>
                <div class="modal-footer">
                  <div>
                    <b-button
                        class="form-control form-control-sm custom-form-btn custom-close-btn"
                        type="button"
                        size="sm"
                        variant="secondary"
                        data-dismiss="modal"
                    >
                      Close
                    </b-button>
                  </div>
                  <div>
                    <b-button
                        class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                        @click="deleteRecord()"
                        type="button"
                        size="sm"
                        variant="outline-dark"
                        data-dismiss="modal"
                    >
                      Delete
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
          <!-- Delete Modal End -->

        </div>
        <div class="col-lg-3">
          <div class="sticky-top custom-sticky-navigation">
            <ul class="verti-timeline list-unstyled"
                style=" margin-top:50px;">
              <li class="event-list" :class="[ ((emp.FatherName == '' || emp.FatherName == null) && (emp.Gender == 0 || emp.Gender == null)) ? 'custom-event-list-false-state':'', ((emp.FatherName != '' || emp.FatherName != null) && (emp.Gender != 0 || emp.Gender != null)) ? 'custom-event-list-true-state':'']" style="padding-bottom: 10px;">
                <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)">
                  <h5 data-id="MyPersonal">Personal Information</h5>
                </a>
              </li>
              <li class="event-list" :class="[ (emp.ProfessionalSummary == '' || emp.ProfessionalSummary == null) ? 'custom-event-list-false-state':'', (emp.ProfessionalSummary != '' || emp.ProfessionalSummary != null) ? 'custom-event-list-true-state':'']" style="padding-bottom: 10px;">
                <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)">
                  <h5 data-id="MyProfessional">Professional Brief</h5>
                </a>
              </li>
              <li class="event-list" :class="[ employeeExperience.length == 0 ? 'custom-event-list-false-state':'', employeeExperience.length != 0 ? 'custom-event-list-true-state':'']" style="padding-bottom: 10px;">
                <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)">
                  <h5 data-id="MyExperience">Experience</h5>
                </a>
              </li>

              <li class="event-list" :class="[ employeeEducation.length == 0 ? 'custom-event-list-false-state':'', employeeEducation.length != 0 ? 'custom-event-list-true-state':'']" style="padding-bottom: 10px;">
                <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)">
                  <h5 data-id="MyEducation">Education</h5>
                </a>
              </li>
              <li class="event-list" :class="[ employeeSkill.length == 0 ? 'custom-event-list-false-state':'', employeeSkill.length != 0 ? 'custom-event-list-true-state':'']" style="padding-bottom: 10px;">
                <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)">
                  <h5 data-id="Skills">Skills</h5>
                </a>
              </li>
              <li class="event-list" :class="[ employeeProjects.length == 0 ? 'custom-event-list-false-state':'', employeeProjects.length != 0 ? 'custom-event-list-true-state':'']" style="padding-bottom: 10px;">
                <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)">
                  <h5 data-id="MyProjects">My Projects</h5>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>
<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { PlusIcon } from "vue-feather-icons";
import html2pdf from 'html2pdf.js'
import axios from "axios";
import _ from "underscore";


import ProfileStrength from "@/components/employeeComponents/ProfileStrengthComponent";
import AddEditExperienceComponent from "@/components/employeeComponents/AddEditExperienceComponent";
import AddEditEducationComponent from "@/components/employeeComponents/AddEditEducationComponent";
import AddEditSkillsComponent from "@/components/employeeComponents/AddEditSkillsComponent";
import AddEditProjectComponent from "@/components/employeeComponents/AddEditProjectComponent";

import DeleteModal from "@/components/employeeComponents/DeleteComponent";
import moment from "moment";

export default {
  name:"EmployeeProfile",
  components:{
    PlusIcon,
    ProfileStrength,
    AddEditExperienceComponent,
    AddEditEducationComponent,
    AddEditSkillsComponent,
    AddEditProjectComponent,
    DeleteModal
  },
  data(){
    return{
      isProfileStrengthStateVisible:false,
      isExperienceStrengthStateVisible:false,
      isEducationStrengthStateVisible:false,
      isSkillStrengthStateVisible:false,

      isModalFooterVisible:false,
      close:"",
      url:axios.defaults.baseURL,
      mainContainer:"main-container",







      //employee Section start
      employeeRecord:null,
      employeeProjectRecord:null,
      //bool
      profileSection:false,
      profileImage:false,
      uploadCV:false,
      updateCVBtn:false,
      uploadpdfBtn:false,
      uploadFile:null,
      uploadpdf:null,
      uploadPdfFile:false,
      emp:{
        FullName:"",
        FatherName:"",
        CNIC:"",
        DateofBirth:"",
        Gender:0,
        MaritalStatusId:0,
        Email:"",
        Address:"",
        Address1:"",
        Area:"",
        CityId:0,
        EmployeeImage:"",
        Resume:"",
        FileName:"",
        PDfName:"",
        PdfFileName:"",
        CountryId:0,
        OtherCityName:"",
        code:0,
        networkCode:0,
        mobileNumber:null,
        PhoneNumber:"",
        Domicile:"",
        Languages:"",
        Sector:"",
        YearOfExperienceId:0,
        DepartmentId: 0,
        CareerLevelId: 0,

        CurrentSalary:0,
        ExpectedSalary:0,
        JobTypId:0,
        ProfessionalSummary:"",
        LinkedInLink:"",
        FacebookLink:"",
        GooglePlusLink:"",
        TwitterLink:"",
        MomReturn:0,
        SpecialNeedyPerson:0,
        Graduate:0,
        LabourManPower:0,
        Hecancy:0,
        //Graduate
        AvailableDate: new Date(),
        GraduateWorkPlacementTypeId: 0,
        ServiceDescription: "",
        SkillsCategoryId: 0,
        SkillsSubCategoryId: 0,
        SubCategory:"",


        //Special Needy
        DisabilityInformationTitle: "",
        DisabilityTypeId: 0,
        DisabilityCertificate: 0,
       /* DisabilitySeverityPhysical: 0,
        DisabilitySeverityHearingSpeech: 0,
        DisabilitySeverityVisual: 0,
        DisabilitySeverityIntellectual: 0,*/
        DisabilitySeverity: 0,
        IsKnowSignLangauge: 0,
        CauseOfDiabilityTypeId: 0,
        SpecialAssistanceRequirment: "",
        KindofJob: "",
        FillBy:0,

        //Mom Return
        CurrentEmployementStatus: 0,
        StartCareer:new Date(),
        EndCareer:new Date(),
        /*CPStartMonth: 0,
        CPStartYear: 0,
        CPEndMonth:0,
        CPEndYear: 0,*/
        CPDescription: "",
        WeeklyWorkingHour: 0,
        WorkLocation: 0,
        TravelMiles: 0,
        TravelTimePercent: 0,


      },
      fullName:"",
      mobileNumber:"",
      email:"",
      facebookLink:"",
      linkedInLink:"",
      twitterLink:"",
      googlePlusLink:"",
      cnic:"",
      empGender:0,
      userResume:null,
      userPDfName:null,
      userImage:null,
      currentSalary:0,
      domicile:"",
      yearOfExperienceId:0,
      expectedSalary:0,
      maritalStatus:0,
      cityId:0,
      professionalSummary:"",
      phoneNumberLink: "tel:",
      linkMobileNo:"",
      emailLink:"mailto:",
      selectedCategory:"-1",
      cities:[],
      countries:[],
      categories:null,

      employeelist:null,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,
      employeeProjects:null,
      maritalStatusList:[],
      yearsOfExperiences:[],

      latestExperience:{},
      latestDegreeLevel:{},

      calculatedAge:0,

      countryCodes:[
        {value: 1,text:"+92"},
        {value: 2,text:"+93"},
        {value: 3,text:"+94"},
      ],

      networkCodes:[
        {value:1,text:"300"},
        {value:2,text:"301"},
        {value:3,text:"302"},
        {value:4,text:"303"},
        {value:5,text:"304"},
        {value:6,text:"305"},
        {value:7,text:"306"},
        {value:8,text:"307"},
        {value:9,text:"308"},
        {value:10,text:"309"},
        {value:11,text:"311"},
        {value:12,text:"312"},
        {value:13,text:"313"},
        {value:14,text:"314"},
        {value:15,text:"315"},
        {value:16,text:"320"},
        {value:17,text:"321"},
        {value:18,text:"322"},
        {value:19,text:"323"},
        {value:20,text:"324"},
        {value:21,text:"325"},
        {value:22,text:"330"},
        {value:23,text:"331"},
        {value:24,text:"332"},
        {value:25,text:"333"},
        {value:26,text:"334"},
        {value:27,text:"335"},
        {value:28,text:"336"},
        {value:29,text:"337"},

        {value:30,text:"340"},
        {value:31,text:"341"},
        {value:32,text:"342"},
        {value:33,text:"343"},
        {value:34,text:"344"},
        {value:35,text:"345"},
        {value:36,text:"346"},
        {value:37,text:"347"},

      ],

      //employee Section End

      //Experience Detail Start
      experienceSection:false,
      //bool
      jobTitle_input:true,
      industry_dd:true,
      salary_dd:true,
      startDate_dd:true,
      projectStartDate1_dd:true,
      company_input:true,
      manageTeam_dd:true,
      location_dd:true,
      endDate_dd:true,
      projectEndDate1_dd:true,
      currentlyWorking_radio:true,
      description1_input:true,
      referenceEmail_input:true,
      referenceNumber_input:true,
      projectTitle_input:true,
      position_input:true,
      currentlyOngoing_radio:true,
      pdescription_input:true,

      currentlyWorkingState: false,



      city1_input:false,
      country1_dd:false,
      country2_dd:true,

      //project Section
      addProject_btn:true,
      removeProject_btn:true,
      selectedCountry1:null,
      selectedCountry2:null,
      //countries:null,
      selectedLocation:null,
      //cities:null,


      selectedIndustry:0,
      industries:[
        { value: 0, text: 'Select Industry'},
        { value: 1, text: 'Pharmaceutical'},
        { value: 2, text: 'Textile'},
      ],
      skillsCategories: null,

      cityInput1:"",
      exp:{
        EmployeeId:0,
        empExperienceId:0,
        jobTitle: "",
        company: "",
        industryId: 0,
        teamManage: 0,
        salary: 0,
        cityId: "",
        isPakistan: true,
        startDateMonthYear: new Date(),
        endDateMonthYear: new Date(),
        currentlyWorking: false,
        experienceDescription: "",
        referenceEmail: "",
        referenceNumber: "",
        countryId: "",
        projectTitle: "",
        position: "",
        projectStartDateMonthYear: new Date(),
        projectEndDateMonthYear: new Date(),
        isProjectOngoing: false,
        projectDescription: ""
      },
      employeeExperienceId:0,
      selectedManageTeam:-1,
      manageTeams:[
        { text: 'Choose One...', value: -1 },
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],
      selectedSalary : -1,
      salaries:[
        { text: 'Choose One...', value: -1 },
        { text: '0-5000', value: 1 },
        { text: '5000-15000', value: 2 },
        { text: '15000-25000', value: 3 },
        { text: '25000-35000', value: 4 },
        { text: '35000-50000', value: 5 },
        { text: '50000-70000', value: 6 },
        { text: '70000-100000', value: 7 },
        { text: '100000-125000', value: 8 },
        { text: '125000-150000', value: 9 },
        { text: '150000-175000', value: 10 },
        { text: '175000-200000', value: 11 },
        { text: '200000-250000', value: 12 },
        { text: '300000-400000', value: 13 },
        { text: '400000-500000', value: 14 },
        { text: '500000-600000', value: 15 },
        { text: '600000-700000', value: 16 },
        { text: '700000-800000', value: 17 },
        { text: '800000-900000', value: 18 },
        { text: '900000-1000000', value: 19 },
        { text: '1000000+', value: 20 },
      ],

      projects:[
          {

            projectTitle: "",
            projectDescription: "",
            employeeId: 0
          }
      ],
      //Experience Detail End
      //Education Detail Start
      educationSection:false,
      //bool
      degreeLevel_dd:true,
      majorField_input:true,
      degreeTitle_input:true,
      institute_input:true,
      instituteNotInPakistan_input:false,
      expectedDate_dd:false,
      completionDate_dd:false,
      country3_dd:true,
      cgpa_input:false,
      percentage_input:false,
      city_dd:false,
      city2_input:false,
      description2_input:true,
      academicProjectTitle_input:true,
      projectStartDate2_dd:false,
      projectEndDate2_dd:false,
      projectDetail_input:true,
      softSkill_input:false,



      //Education Object
      edu:{
        empEducationId:0,
        employeeId:0,
        degreeLevelId: 0,

        degreeTitle: "",
        majorFieldId: "",
        institute: "",
        expectedGraduationYear: new Date(),
        graduationCompletionYear: new Date(),
        resultType: 0,
        cgpaPercentage: 0,
        countryGNId: 0,
        stateGNId: 0,
        educationDescription: "",
        academicProjectDetail: "",
        startDate: new Date(),
        endDate: new Date(),
        projectDetails: "",
        softSkills: "",
        notInPakistan:""
      },
      selectedState:0,
      selectedCountry3:0,
      selectedDegreeLevel:0,
      degreeLevels:null,
      selectedMajorField:0,
      majorFields:null,
      selectedStudentOrGraduate:0,
      studentOrGraduate:[
        { text: 'Student', value: 1},
        { text: 'Graduate', value: 2},
      ],
      selectedResultType:0,
      resultTypes:[
        { text: 'CGPA', value: 1},
        { text: 'Percentage', value: 2},
      ],
      cityInput2:"",

      //Education Detail End

      //Skill Section Start
      skillSection:false,
      ProjectSection:false,
      skillName_dd:true,
      skillLevel_dd:true,
      addSkill_btn:false,
      removeSkill_btn:false,

      skills:[{empSkillId:0,employeeId:0,skillsSubCategoryId:0,skillLevelOutof100:0}],
      skillSubCategories:null,
      selectedSkillLevel:0,
      skillLevels:[
        { value: 1, text: '10'},
        { value: 2, text: '20'},
        { value: 3, text: '30'},
        { value: 4, text: '40'},
        { value: 5, text: '50'},
        { value: 6, text: '60'},
        { value: 7, text: '70'},
        { value: 8, text: '80'},
        { value: 9, text: '90'},
        { value: 10, text: '100'},

      ],

      employeeId:0,
      empSkillId:0,
      skillsSubCategoryId:0,
      skillLevelOutof100:0,

      //Graduate Section
      workPlacements:null,


      //Mums Return To Work Section
      selectedEmployeeStatus:"0",
      employeeStatuses:[
        { value: 1, text:"Full Time" },
        { value: 2, text:"Part Time" },
        { value: 3, text:"Freelancing or On/Off projects" },
        { value: 4, text:"Not Currently Working" },
      ],
      selectedEmployementWorkHours:"0",
      employementWorkHours:[
        { value: 1, text:"1-20 hours per week" },
        { value: 2, text:"21-30 hours per week" },
        { value: 3, text:"31-40 hours per week" },
        { value: 4, text:"40 hours per week" },
      ],

      selectedEmployementWorkWhere:"0",
      employementWorkWhere:[
        { value: 1, text:"At the office" },
        { value: 2, text:"Remote" },
        { value: 3, text:"Combination" },
        { value: 4, text:"Unsure right now" },
      ],

      yearList:[],
      selectedStartMonth:"0",
      selectedEndMonth:"0",
      selectedStartYear:"0",
      selectedEndYear:"0",

      months:[
        { value: 0, text:"Select Month" },
        { value: 1, text:"1" },
        { value: 2, text:"2" },
        { value: 3, text:"3" },
        { value: 4, text:"4" },
        { value: 5, text:"5" },
        { value: 6, text:"6" },
        { value: 7, text:"7" },
        { value: 8, text:"8" },
        { value: 9, text:"9" },
        { value: 10, text:"10" },
        { value: 11, text:"11" },
        { value: 12, text:"12" }

      ],


      selectedKM:0,
      kms:[
        {value : 1, text: "0-5"},
        {value : 2, text: "5-10"},
        {value : 3, text: "10-20"},
        {value : 4, text: "20-30"},
        {value : 5, text: "30-40"},
        {value : 6, text: "40-50"},
        {value : 7, text: "50-60"},
        {value : 8, text: "60-70"},
        {value : 9, text: "70-80"},
        {value : 10, text: "80-90"},
        {value : 11, text: "90-100"},
        {value : 12, text: "100-150"},
        {value : 13, text: "150-200"},
        {value : 14, text: "200-250"},
        {value : 15, text: "250-300"},
        {value : 16, text: "300-350"},
        {value : 17, text: "350-400"},
        {value : 18, text: "400-450"},
        {value : 19, text: "450-500"},
        {value : 20, text: "500-600"},
        {value : 21, text: "600-700"},
        {value : 22, text: "700-800"},
        {value : 23, text: "800-900"},
        {value : 24, text: "900-1000"}
      ],
      selectedTravelPercent:"0",
      travels:[
        {value : 1, text: "not at all"},
        {value : 2, text: "Up to 10%"},
        {value : 3, text: "Up to 20%"},
        {value : 4, text: "Up to 30%"},
        {value : 5, text: "Up to 40%"},
        {value : 6, text: "Up to 50%"},
        {value : 7, text: "Up to 60%"},
        {value : 8, text: "Up to 70%"},
        {value : 9, text: "Up to 80%"},
        {value : 10, text: "Up to 90%"},
        {value : 11, text: "Up to 100%"}

      ],

      courseSection:false,
      //Special Need person Section
      disabilityType:null,
      causesOfDisability:null,
      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,
      //showReassignButton: false,
      showSaveButton: true,
      info:null,

      profileStrength:0,
      modalTitle: "",
      rowid:null,
      loading: true,
      errored: false,
      componentKey: 0,
      rerendercomponentKey:0,

      isModalVisible:false,

      experienceRecord:null,
      projectRecord:null,
      educationRecord:null,
      skillRecord:null,
    };
  },
  computed: {

    sMWCoursesAdd(){
      console.log("availableSMWCoursesAdd : ", this.$store.getters.availableSMWCoursesAdd)
      return this.$store.getters.availableSMWCoursesAdd
    },

    disabledCurrentlyWorking() {
      return this.exp.currentlyWorking === true;
    },

  },
  methods:{
    getAll(){
      this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);
            this.employeeId = this.employeelist.employeeId;



            this.emp.FullName = this.employeelist.fullName;
            console.log("this.emp.FullName : ", this.emp.FullName);
            this.emp.FatherName = this.employeelist.fatherName;
            for (let option in this.countryCodes){
              if(this.employeelist.code === this.countryCodes[option].value){
                this.emp.code = this.countryCodes[option].text;
              }
            }
            console.log("country Code : ", this.code);
            //this.code = this.employeelist.code;
            for (let option in this.networkCodes){
              if(this.employeelist.networkCode === this.networkCodes[option].value){
                this.emp.networkCode = this.networkCodes[option].text;
              }
            }
            console.log("network Code : ", this.networkCode);
            //this.networkCode = this.employeelist.networkCode;

            this.emp.mobileNumber = this.employeelist.mobileNumber;
            this.linkMobileNo = this.emp.code+this.emp.networkCode+this.emp.mobileNumber;
            // this.emp.careerLevelId = this.employeelist.careerLevelId;
            this.emp.DateofBirth = this.employeelist.dateofBirth;
            console.log("DOB : ", this.emp.DateofBirth);
            console.log("calculated Age : ",this.caculateAge(this.emp.DateofBirth));
            this.calculatedAge = this.caculateAge(this.emp.DateofBirth);
            this.emp.Email = this.employeelist.email;
            this.emp.Address = this.employeelist.address;
            this.emp.Address1 = this.employeelist.address1;
            this.emp.EmployeeImage = this.employeelist.employeeImage;
            this.emp.Resume = this.employeelist.resume;
            this.emp.PDfName = this.employeelist.pDfName;
            this.emp.PdfFileName = this.employeelist.pdfFileName;
            this.emp.FileName = this.employeelist.fileName;
            //this.userImage = this.employeelist.employeeImage;
            //this.userResume = this.employeelist.resume;
            console.log("emp.Resume : ", this.emp.Resume);
            console.log("emp.PDfName: ", this.emp.pdfname);
            console.log("emp.PdfFileName", this.employeelist);
            console.log("emp.PDfName: ", this.emp.PDfName);
            console.log("emp.PdfFileName", this.emp.PdfFileName);
            if(this.emp.PDfName == null){
              this.uploadpdfBtn = true;
              this.updatepdfBtn = false;
            }
            else{
              this.uploadpdfBtn = false;
              this.updatepdfBtn = true;
            }
            if(this.emp.Resume == null ){
              this.uploadCVBtn = true;
              this.updateCVBtn = false;
            }
            else{              
              this.uploadCVBtn = false;
              this.updateCVBtn = true;
}
 
            console.log("img : ", this.url+this.emp.EmployeeImage);



            this.emp.Area = this.employeelist.area;
            this.emp.Languages = this.employeelist.languages;
            this.emp.ExperienceLevel = this.employeelist.experienceLevel;
            this.emp.CNIC = this.employeelist.cnic;
            this.emp.FacebookLink = this.employeelist.facebookLink;
            this.emp.LinkedInLink = this.employeelist.linkedInLink;
            this.emp.TwitterLink = this.employeelist.twitterLink;
            this.emp.GooglePlusLink = this.employeelist.googlePlusLink;

            this.emp.CurrentSalary = this.employeelist.currentSalary;
            this.emp.Domicile = this.employeelist.domicile;
            this.emp.MaritalStatusId = this.employeelist.maritalStatusId;
            this.emp.Gender = this.employeelist.gender;
            
            this.emp.CareerLevelId = this.employeelist.CareerLevelId;
            this.emp.DepartmentId = this.employeelist.DepartmentId;
            
            this.emp.YearOfExperienceId = this.employeelist.yearOfExperienceId;
            this.emp.ExpectedSalary = this.employeelist.expectedSalary;

            //this.selectedCountry = this.employeelist.countryId;
            this.emp.CityId=this.employeelist.cityId;
            this.emp.CountryId=this.employeelist.countryId;
            this.emp.OtherCityName=this.employeelist.cityName;
            this.emp.Sector = this.employeelist.sector;
            this.emp.ProfessionalSummary = this.employeelist.professionalSummary;
            this.emp.Graduate = this.employeelist.graduate;

            this.emp.MomReturn = this.employeelist.momReturn

            this.emp.SpecialNeedyPerson = this.employeelist.specialNeedyPerson

            this.emp.LabourManPower = this.employeelist.labourManPower

            this.emp.Hecancy = this.employeelist.hecancy

            //Graduate
            this.emp.AvailableDate = this.employeelist.availableDate;
            this.emp.GraduateWorkPlacementTypeId = this.employeelist.graduateWorkPlacementTypeId;
            this.emp.ServiceDescription = this.employeelist.serviceDescription;
            this.emp.SkillsCategoryId = this.employeelist.skillsCategoryId;
            this.emp.SubCategory = this.employeelist.subCategory;


            //Special Needy
            this.emp.DisabilityInformationTitle = this.employeelist.disabilityInformationTitle;
            this.emp.DisabilityTypeId = this.employeelist.disabilityTypeId;
            this.emp.DisabilityCertificate = this.employeelist.disabilityCertificate;
            /*this.emp.DisabilitySeverityPhysical = this.employeelist.disabilitySeverityPhysical;
            this.emp.DisabilitySeverityHearingSpeech = this.employeelist.disabilitySeverityHearingSpeech;
            this.emp.DisabilitySeverityVisual = this.employeelist.disabilitySeverityVisual;*/
            this.emp.DisabilitySeverity = this.employeelist.disabilitySeverity;
            this.emp.IsKnowSignLangauge = this.employeelist.isKnowSignLangauge;
            this.emp.CauseOfDiabilityTypeId = this.employeelist.causeOfDiabilityTypeId;
            this.emp.SpecialAssistanceRequirment = this.employeelist.specialAssistanceRequirment;
            this.emp.KindofJob = this.employeelist.kindofJob;
            this.emp.FillBy = this.employeelist.fillBy;

            //Mom Return
            this.emp.CurrentEmployementStatus = Number(this.employeelist.currentEmployementStatus);
            this.emp.StartCareer = this.employeelist.startCareer;
            this.emp.EndCareer = this.employeelist.endCareer

            this.emp.CPDescription = this.employeelist.cpDescription;
            this.emp.WeeklyWorkingHour = Number(this.employeelist.weeklyWorkingHour);
            this.emp.WorkLocation = Number(this.employeelist.workLocation);
            this.emp.TravelMiles = Number(this.employeelist.travelMiles);
            this.emp.TravelTimePercent = Number(this.employeelist.travelTimePercent);




            this.employeeSkill = res.data.eMPSkills;
            console.log("employeeSkill : ", this.employeeSkill);

            this.employeeExperience = _.sortBy(res.data.eMPExperiences, 'startDateMonthYear').reverse();

            if(this.employeeExperience.length != 0){
              console.log("employeeExperience : ", this.employeeExperience);
              this.employeeExperienceId = this.employeeExperience.empExperienceId;
              this.latestExperience = _.first(this.employeeExperience);
              console.log("this.latestExperience", this.latestExperience);
            }


            this.employeeEducation = _.sortBy(res.data.empEducations, 'degreeLevelId').reverse();

            if(this.employeeEducation.length != 0){
              console.log("employeeEducation : ", this.employeeEducation);

              this.latestDegreeLevel = _.first(this.employeeEducation);
              console.log("this.latestDegreeLevel", this.latestDegreeLevel);
            }

            this.employeeProjects = res.data.project;
            console.log("employeeProjects : ", this.employeeProjects);








          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    getJobTypeList() {
      this.employementWorkHours = [];
      let model = {
        type: "JobType"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.employementWorkHours = res.data.data;

            console.log("Add Response portion");

            console.log("employementWorkHours : ", this.employementWorkHours);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });
    },
    getCategories() {
      this.categories = null;
      axios.get('/api/JobType/GetAll')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.categories = res.data.data;
            console.log("categories : ", this.categories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },

    caculateAge(dob){
      //var diff_ms = Date.now() - dob.getTime();
      //var age_dt = new Date(diff_ms);

      //return Math.abs(age_dt.getUTCFullYear() - 1970);

      var formatedDate = moment(String(dob)).format('YYYY/MM/DD');
      var today = new Date();
      var birthDate = new Date(formatedDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },



    //dropdownsLists
    getMaritalStatusList(){
      this.maritalStatusList = [];
      let model = {
        type: "MaritalStatus"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.maritalStatusList = res.data.data;

            console.log("Add Response portion");

            console.log("maritalStatusList : ", this.maritalStatusList);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },
    getCityList(){
      this.cities = null;
      let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },
    getYearOfExperienceList(){
      this.yearsOfExperiences = [];
      let model = {
        type: "YearofExperience"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.yearsOfExperiences = res.data.data;

            console.log("Add Response portion");

            console.log("yearsOfExperiences : ", this.yearsOfExperiences);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },

    getCountryList(){
      this.countries = null;
      let model = {
        type: "Country"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.countries = res.data.data;

            console.log("Add Response portion");

            console.log("countries : ", this.countries);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },
    getDegreeLevelList(){
      this.degreeLevels = null;
      let model = {
        type: "DegreeLevel"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.degreeLevels = res.data.data;

            console.log("Add Response portion");

            console.log("degreeLevels : ", this.degreeLevels);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },

    getSkillCategoriesList() {
      this.skillsCategories = [];
      let model = {
        type: "SkillsCategory"
      };
      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res =>{


            this.skillsCategories = res.data.data;
            console.log("skillsCategories : ", this.skillsCategories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },

    getSubCategories() {
      this.skillSubCategories = [];
      let model = {
        type: "SkillCategory"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run


            this.skillSubCategories = res.data.data;


            console.log("Add Response portion");

            console.log("skillSubCategories : ", this.skillSubCategories);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {


            }
          });

    },
    getGraduateWorkPlacementType() {
      this.workPlacements = null;
      axios.get('/api/GraduateWorkPlacementType/GetGraduateWorkPlacementTypes')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            res.data
            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.workPlacements = res.data.data;
            console.log("workPlacements : ", this.workPlacements);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    getDisabilityTypeList(){
      this.disabilityType = null;
      let model = {
        type: "DisabilityType"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.disabilityType = res.data.data;

            console.log("Add Response portion");

            console.log("disabilityType : ", this.disabilityType);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },

    getCauseOfDisabilityList(){
      this.causesOfDisability = null;
      let model = {
        type: "CauseofDisability"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.causesOfDisability = res.data.data;

            console.log("Add Response portion");

            console.log("causesOfDisability : ", this.causesOfDisability);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },

    //Add Modal Profile Image
    NewModalImage() {
      this.modalTitle = "Change Profile Image";
      this.reset();
      this.profileSection= true;
      this.profileImage=true;
      this.uploadCV = false
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection= false;
      this.courseSection = false;
      this.isModalFooterVisible = true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    //Add Modal Profile Image
    NewModalUploadCV() {
      this.modalTitle = "Upload CV";
      this.reset();
      this.profileSection= true;
      this.profileImage=false;
      this.uploadCV = true;
      this.uploadpdf = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    NewModalUploadPdf() {
      this.modalTitle = "Upload Assessment";
      this.reset();
      this.profileSection= true;
      this.profileImage=false;
      this.uploadCV = false;
      this.uploadpdf = true;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    //Update Modal Profile Image
    EditModalUploadCV() {
      this.modalTitle = "Update CV";

      this.profileSection= true;
      this.profileImage=false;
      this.uploadCV = true;
      this.uploadpdf = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    EditModalUploadPdf() {
      this.modalTitle = "Update Assessment";

      this.profileSection= true;
      this.profileImage=false;
      this.uploadCV = false;
      this.uploadpdf = true;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    //Add Modal Experience
    NewModalExperience() {
      this.rerenderComponent();
      this.modalTitle = "Add New Experience";


      this.employeeRecord = null;
      this.employeeProjectRecord = null;
      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = true;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=false;

      },


    //EDIT MODAL Experience
    EditModalExperience(emprecord) {
      this.rerenderComponent();
      this.loading = true;
      console.log("emprecord : ",emprecord);
      this.modalTitle = "Edit Experience";
      this.employeeRecord = emprecord;
      console.log("employeeRecord : ",this.employeeRecord)
      this.employeeProjectRecord = null;
      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = true;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.loading = false;
      this.isModalFooterVisible=false;

      },
    //EDIT MODAL Experience
    EditModalProject(empprojectrecord) {
      this.rerenderComponent();
      this.loading = true;
      console.log("empprojectrecord : ",empprojectrecord);
      this.modalTitle = "Edit Project";
      this.employeeRecord = null;
      this.employeeProjectRecord = empprojectrecord;
      console.log("employeeRecord : ",this.employeeRecord)
      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = true;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.loading = false;
      this.isModalFooterVisible=false;

    },
    //Add Modal Education
    NewModalEducation() {
      this.rerenderComponent();
      this.modalTitle = "Add New Education";

      this.employeeRecord = null;
      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = true;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=false;
    },

    //EDIT MODAL Education
    EditModalEducation(emprecord) {
      this.loading = true;
      this.rerenderComponent();
      console.log("emprecord : ",emprecord);
      this.modalTitle = "Edit Education";
      this.employeeRecord = emprecord;
      console.log("employeeRecord : ",this.employeeRecord)
      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = true;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.loading = false;
      this.isModalFooterVisible=false;

    },

    //Add Modal Skill
    NewModalSkill() {
      this.rerenderComponent();
      this.modalTitle = "Add New Skill";

      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = true;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=false;
    },
    //EDIT MODAL Skill
    EditModalSkill(emprecord) {
      this.loading = true;
      this.rerenderComponent();
      console.log("emprecord : ",emprecord);
      this.modalTitle = "Edit Skill";
      this.employeeRecord = emprecord;
      console.log("employeeRecord : ",this.employeeRecord)

      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = true;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible=false;
      this.loading = false;
      this.skillsSubCategoryId=emprecord.skillsCategoryId;


    },
  //Add Modal Project
  NewModalProject() {
      this.rerenderComponent();
      this.modalTitle = "Add New Project";

      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = true;
      this.courseSection = false;
      this.isModalFooterVisible=false;
    },
    //EDIT MODAL Skill
    EditModalProject1(emprecord) {
      this.loading = true;
      this.rerenderComponent();
      console.log("emprecord : ",emprecord);
      this.modalTitle = "Edit Project";
      this.employeeRecord = null;
      this.employeeProjectRecord=emprecord;
      console.log("employeeRecord : ",this.employeeRecord)

      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = true;
      this.courseSection = false;
      this.isModalFooterVisible=false;
      this.loading = false;


    },

//Add Modal Skill
    NewModalCourse() {
      this.rerenderComponent();
      this.modalTitle = "Add New Course";

      this.profileImage=false;
      this.uploadCV = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = true;
      this.isModalFooterVisible=false;
    },


    AddOrUpdate() {
      if(this.modalTitle == "Change Profile Image"){
        this.onSubmit();
      }
      else if(this.modalTitle == "Upload CV"){
        this.onSubmit();
      }
      else if(this.modalTitle == "Update CV"){
        this.onSubmit();
      }
      else if (this.modalTitle == "Upload Assessment"){
        this.onpdfSubmit();
      }
      else if (this.modalTitle == "Update Assessment"){
        this.onpdfSubmit();
      }
    },
    onSelectImage(e) {
      this.uploadFile = null;
      this.uploadFile = e.target.files[0];
      console.log("uploadFile : ", e.target.files[0].size);
      console.log("uploadFileName : ", this.uploadFile.name)
      /*this.uploadFile = e.target.value
      var filePath = fileInput.value;*/

      // Allowing file type
      var fileSize = 209715;

      if (this.uploadFile.size > fileSize) {
        alert('File Size Limit is 80 KB');
        e.target.value = '';
        this.uploadFile = null;
        return false;
      }
      /*this.userLogo = URL.createObjectURL(file);
      console.log("userLogo : ", this.userLogo)*/
    },
    onSelectpdf(e) {
      this.uploadPdfFile = null;
      this.uploadPdfFile = e.target.files[0];
      console.log("uploadPdfFile : ", e.target);
      console.log("uploadPdfFile : ", this.uploadPdfFile.name)
      /*this.uploadFile = e.target.value
      var filePath = fileInput.value;*/

      // Allowing file Size
      var fileSize = 2097152;

      if (this.uploadPdfFile.size > fileSize) {
        alert('File Size Limit is 2 MB');
        e.target.value = '';
        this.uploadPdfFile = null;
        return false;
      }
      // else{
      //   onpdfSubmit();
      // }
      /*this.userLogo = URL.createObjectURL(file);
      console.log("userLogo : ", this.userLogo)*/
    },
    onpdfSubmit(){



if (this.uploadPdfFile != null) {

  var formData = new FormData();
  // Allowing img file type
  var imgAllowedExtensions =
      /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  // Allowing CV file type
  var pdfAllowedExtensions =
      /(\.pdf)$/i;

  if(imgAllowedExtensions.exec(this.uploadPdfFile.name)){
    formData.append("EmployeeImage", this.uploadPdfFile);
    console.log("formData ; ",formData);
    axios
        .post("/api/Employee/UpdateImages", formData)
        .then(res => {
          // code that we will 'try' to run
          this.info = res.data;

          console.log("Add Response portion");
          $("#addorEditModal").modal("hide");
          console.log("response : ", this.info);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            this.getAll();
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
            this.getAll();
            this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });


          }
        });
  }
  else if(pdfAllowedExtensions.exec(this.uploadPdfFile.name)){
    formData.append("Assessment", this.uploadPdfFile);
    console.log("formData ; ",formData);
    axios
        .post("/api/Employee/UploadPdf", formData)
        .then(res => {
          // code that we will 'try' to run
          this.info = res.data;
          
          console.log("Add Response portion");
          $("#addorEditModal").modal("hide");
          console.log("response : ", this.info);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            this.getAll();
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
            this.getAll();
            this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });


          }
        });
  }


}
else {
  console.log("Error : Sorry there is no response");
  this.$bvToast.toast("Please Choose  a File ", {
    toaster: "b-toaster-top-center",
    variant: "warning",

    solid: true,
  });
}
},
    onSelectCV(e) {
      this.uploadFile = null;
      this.uploadFile = e.target.files[0];
      console.log("uploadFile : ", e.target);
      console.log("uploadFileName : ", this.uploadFile.name)
      /*this.uploadFile = e.target.value
      var filePath = fileInput.value;*/

      // Allowing file Size
      var fileSize = 2097152;

      if (this.uploadFile.size > fileSize) {
        alert('File Size Limit is 2 MB');
        e.target.value = '';
        this.uploadFile = null;
        return false;
      }
      /*this.userLogo = URL.createObjectURL(file);
      console.log("userLogo : ", this.userLogo)*/
    },
    onSubmit(){



      if (this.uploadFile != null) {

        var formData = new FormData();
        // Allowing img file type
        var imgAllowedExtensions =
            /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        // Allowing CV file type
        var cvAllowedExtensions =
            /(\.pdf)$/i;

        if(imgAllowedExtensions.exec(this.uploadFile.name)){
          formData.append("EmployeeImage", this.uploadFile);
          console.log("formData ; ",formData);
          axios
              .post("/api/Employee/UpdateImages", formData)
              .then(res => {
                // code that we will 'try' to run
                this.info = res.data;

                console.log("Add Response portion");
                $("#addorEditModal").modal("hide");
                console.log("response : ", this.info);
              })
              .catch(error => {
                console.log("Errorrrrrr : ", error);
                this.errored = true;
              })
              .finally(() => {
                if (this.errored) {
                  this.getAll();
                  console.log("Error : Sorry there is no response");
                  // this.$bvToast.toast("Sorry there is no response ", {
                  //   toaster: "b-toaster-top-center",
                  //   variant: "danger",

                  //   solid: true,
                  // });
                } else {
                  this.getAll();
                  this.$bvToast.toast("Updated Successfully ", {
                    toaster: "b-toaster-top-center",
                    variant: "success",

                    solid: true
                  });


                }
              });
        }
        else if(cvAllowedExtensions.exec(this.uploadFile.name)){
          formData.append("Resume", this.uploadFile);
          console.log("formData ; ",formData);
          axios
              .post("/api/Employee/UploadCv", formData)
              .then(res => {
                // code that we will 'try' to run
                this.info = res.data;

                console.log("Add Response portion");
                $("#addorEditModal").modal("hide");
                console.log("response : ", this.info);
              })
              .catch(error => {
                console.log("Errorrrrrr : ", error);
                this.errored = true;
              })
              .finally(() => {
                if (this.errored) {
                  this.getAll();
                  console.log("Error : Sorry there is no response");
                  // this.$bvToast.toast("Sorry there is no response ", {
                  //   toaster: "b-toaster-top-center",
                  //   variant: "danger",

                  //   solid: true,
                  // });
                } else {
                  this.getAll();
                  this.$bvToast.toast("Updated Successfully ", {
                    toaster: "b-toaster-top-center",
                    variant: "success",

                    solid: true
                  });


                }
              });
        }


      }
      else {
        console.log("Error : Sorry there is no response");
        this.$bvToast.toast("Please Choose  a File ", {
          toaster: "b-toaster-top-center",
          variant: "warning",

          solid: true,
        });
      }
    },



    //shows detail per record
    getExperienceRow(row) {
      this.isModalVisible = true;
      this.experienceRecord = row;
      this.projectRecord = null;
      this.educationRecord = null;
      this.skillRecord = null;
      //this.rowid = row.empExperienceId;
      this.modalTitle = "Experience";
      console.log("row : "+this.experienceRecord);

    },

    getProjectRow(row){
      this.isModalVisible = true;
      this.experienceRecord = null;
      this.projectRecord = row;
      this.educationRecord = null;
      this.skillRecord = null;
      /*this.rowid = row.empEducationId;*/
      this.modalTitle = "Project";
      console.log("row : "+this.projectRecord);
    },

    getEducationRow(row) {
      this.isModalVisible = true;
      this.experienceRecord = null;
      this.projectRecord = null;
      this.educationRecord = row;
      this.skillRecord = null;
      /*this.rowid = row.empEducationId;*/
      this.modalTitle = "Education";
      console.log("row : "+this.educationRecord);

    },

    getSkillRow(row) {
      this.isModalVisible = true;
      this.experienceRecord = null;
      this.projectRecord = null;
      this.educationRecord = null;
      this.skillRecord = row;
      //this.rowid = row.empSkillId;
      this.modalTitle = "Skill";
      console.log("row : "+this.skillRecord);

    },





    scrollTo(id) {
      //var id=event.target.attr("data-id");
      console.log(id)
      let yOffset = -235;
      /*if(id.match("accordion")){
        yOffset = -250;
      }*/

      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});

    },
    generatePdf() {
      prompt('', document.getElementById(this.mainContainer).innerHTML.replace(/"/g, "'"));
    },
    exportToPDF() {
      html2pdf(this.$refs.document, {
        margin: 1,
        filename: 'document.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          dpi: 192,
          letterRendering: true
        },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'landscape'
        }
      })
    },

    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },
    //Rerender Component
    rerenderComponent() {
      this.rerendercomponentKey += 1;
    },
    closeModal(){
      $("#addorEditModal").modal("hide");
      this.isModalVisible=false;
    },


    refreshPage(){
      $("#addorEditModal").modal("hide");
      this.isModalVisible=false;
      this.getAll();

    },
    navigate(){

      $("#addorEditModal").animate({ scrollTop: 0 }, "fast");



    },
    reset(){
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    getYears() {
      var startYear = 2000;
      var i = 0;
      for (i = startYear; i <= new Date().getFullYear(); i++) {
        this.yearList.push(i);
      }
      console.log("year List: ",this.yearList)
    },
    getStrengthStates(pro, exp, edu, skill){
      console.log(pro, exp, edu);
      this.isProfileStrengthStateVisible = pro;
      this.isExperienceStrengthStateVisible = exp;
      this.isEducationStrengthStateVisible = edu;
      this.isSkillStrengthStateVisible = skill;
    },


    getAdds(){
      this.$store.dispatch("fetchAdds");
    },


  },
  mounted() {
    //this.scrollTo(this.mainContainer);
    this.getAdds();
    this.getAll();
    this.getCategories();
    //this.getCities();
    this.getMaritalStatusList();
    this.getCityList();
    this.getYearOfExperienceList();
    this.getCountryList();
    this.getSkillCategoriesList();
    this.getDegreeLevelList();
    this.getJobTypeList();
    //this.getMajorFields();
    this.getSubCategories();
    this.getGraduateWorkPlacementType();
    this.getYears();
    this.getDisabilityTypeList();
    this.getCauseOfDisabilityList();
    // this.CalculatePercentage();

  },
}
</script>
<style scoped>
.event-list{
  border-left: 3px dashed #f6f6f6;
  position: relative;
  padding: 0 0 30px 30px;
}
.event-list.custom-event-list-true-state::after{
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #6bff30 !important;
  background-color: #6bff30 !important;
}
.event-list.custom-event-list-false-state::after{
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ea216c !important;
  background-color: #ea216c !important;
}
/*.event-list::after{
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ea216c;
  background-color: #fff;
}*/
.resume-icon{
  font-size: 52px;
  color: #ea216c;
}
.custom-edit-button{
  font-size: 30px;
}
.advertisementimage{
  height: 800px;
  width: 100%;
}
@media screen and (max-width:720px) {
  .advertisementimage{
    height: 319px;
    width: 180px;
}
}
@media (max-width: 991.98px) { /* Bootstrap's breakpoint for large screens */
  .banner-container {
    display: none;
  }
}
</style>
