<template>
  <div>
    <div class="row no-gutters w-100">
      <div class="col-md-3 w3-card-2">
        <div class=" w3-bar-block w3-animate-left" id="mySidebar1">
          <div id="sidebar-tabs" class="nav nav-tabs custom-sidebar-nav-tabs" role="tablist">
            <!-- JobType/Category Filter-->
            <a href="#" class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none"
              data-toggle="collapse" data-target="#id-category" aria-expanded="false" ariacontrols="id-category">
              <div class="custom-sidebar-heading">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="shop"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Category</span>
                  </div>
                </div>
                <div class="custom-sidebar-icons custom-add-btn">
                  <a href="#" class="custom-sidebar-link">
                    <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                  </a>
                </div>
              </div>
            </a>
            <div id="id-category" class="collapse custom-collapse show" aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs">
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 24">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Mums Back to Work is specifically designed to cater to the needs of moms who have taken a break from
                  their careers to raise children, and are now ready to jump back into the job market.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 25">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Paid Internship Placement for minimum 3 months in the relevant field of expertise.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 26">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  It is a paid internship in any relevant field of expertise with 8-10 Weeks of internship in any of the
                  company.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 41">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  This is an excellent resource for students/graduates who are looking for short-term, project-based
                  work
                  that can be done remotely or with flexible hours.
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 30">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  This section offers a range of job listings from companies that prioritize diversity, equity, and
                  inclusion, and are committed to providing reasonable accommodations for employees with disabilities
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 105">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  We provide employers with access to a pool of talented and diverse male candidates
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 254">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Labour Man Power - Technical Staff
                </div>
              </div>
              <div v-if="filter.JobSubTypes[0] != null && filter.JobSubTypes[0] == 259">
                <div style="border: solid 2px; margin: 0.5rem;padding: 0.5rem;">
                  Others
                </div>
              </div>

              <b-form-group class="border-0">
                <b-form-checkbox v-for="(option, index) in categories" v-model="selectedJobSubType" :key="option.key"
                  :value="option.key" class="mt-2 custom-checkbox-color w3-animate-opacity" @change="getJobsByFilters">
                  <span>
                    <a v-if="option.key != 105"> {{ option.value }} </a>
                    <a target="_blank" href="https://www.hecancy.com/#/all-jobs" v-if="option.key == 105">{{
                      option.value }} </a>

                   <div v-if="option.key == 30" id="chkDisabiltyTypes" class="hideDiv">
                      <b-form-checkbox v-for="(option, index) in disabilityTypes" v-model="filter.disabilityTypes"
                        :key="option.key" :value="option.key" class="mt-2 custom-checkbox-color w3-animate-opacity"
                        @change="getJobsByFilters">
                        <span>
                          <a> {{ option.value }} </a>
                        </span>
                      </b-form-checkbox>
                    </div> 



                  </span>
                </b-form-checkbox>

              </b-form-group>

            </div>
            <!-- JobSubType/JobType Filter-->
            <a href="#" class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none collapsed"
              data-toggle="collapse" data-target="#id-jobtype" aria-expanded="false" ariacontrols="id-jobtype">
              <div class="custom-sidebar-heading">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="shop"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Job Type</span>
                  </div>
                </div>
                <div class="custom-sidebar-icons custom-add-btn">
                  <a href="#" class="custom-sidebar-link">
                    <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                  </a>
                </div>
              </div>
            </a>
            <div id="id-jobtype" class="collapse custom-collapse collapse" aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs">
              <b-form-group class="border-0">
                <b-form-checkbox v-for="option in jobTypes" v-model="filter.JobTypes" :key="option.key"
                  :value="option.key" class="mt-2 custom-checkbox-color w3-animate-opacity" @change="getJobsByFilters">
                  {{ option.value }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <!-- Experience Filter-->
            <a href="#" class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none collapse"
              data-toggle="collapse" data-target="#id-experience" aria-expanded="false" ariacontrols="id-experience">
              <div class="custom-sidebar-heading">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="shop"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Experience</span>
                  </div>
                </div>
                <div class="custom-sidebar-icons custom-add-btn">
                  <a href="#" class="custom-sidebar-link">
                    <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                  </a>
                </div>
              </div>
            </a>
            <div id="id-experience" class="collapse custom-collapse collapse" aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs">
              <b-form-group class="border-0">
                <b-form-checkbox v-for="(option, index) in visibleExperiences" v-model="filter.EmpExperiences"
                  :key="option.key" :value="option.key" class="mt-2 custom-checkbox-color" @change="getJobsByFilters">
                  {{ option.value }}


                </b-form-checkbox>
                <!--  View More Button    -->
                <div v-if="visibleExperiences != null">
                  <div v-if="experiencesVisible < filteredExperiences.length">
                    <div class="text-center clear-bottom">
                      <a href="javascript:void(0)" @click="experiencesVisible += step" class="text-decoration-none">
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </div>
            <!-- cityFilter-->
            <a href="#" class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none collapse"
              data-toggle="collapse" data-target="#id-city" aria-expanded="false" ariacontrols="id-city">
              <div class="custom-sidebar-heading">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="shop"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>City</span>
                  </div>
                </div>
                <div class="custom-sidebar-icons custom-add-btn">
                  <a href="#" class="custom-sidebar-link">
                    <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                  </a>
                </div>
              </div>
            </a>
            <div id="id-city" class="collapse custom-collapse collapse" aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs">
              <b-form-group class="border-0">
                <b-form-checkbox v-for="option in filteredCities" v-model="filter.Cities" :key="option.key"
                  :value="option.key" class="mt-2 custom-checkbox-color w3-animate-opacity" @change="getJobsByFilters">
                  {{ option.value }}
                </b-form-checkbox>
                <b-form-checkbox v-for="option in otherOptions" v-model="filter.isOthersSelected" value-field="item"
                  text-field="value" class="mt-2 custom-checkbox-color w3-animate-opacity" @change="getJobsByFilters">
                  {{ option.value }}
                </b-form-checkbox>
                <!--              <b-form-checkbox
                  v-model="isSelectedOther"
                  value=true
                  unchecked-value=false
                  @change="getJobsByFilters"
              >
                Others
              </b-form-checkbox>-->

              </b-form-group>
            </div>

            <!-- Degree Level Filter-->
            <a href="#" class="w3-bar-item w3-button custom-bar-item custom-active text-decoration-none collapse"
              data-toggle="collapse" data-target="#id-degreelevel" aria-expanded="false" ariacontrols="id-degreelevel">
              <div class="custom-sidebar-heading">
                <div class="custom-row">
                  <div class="custom-sidebar-icons">
                    <b-icon icon="shop"></b-icon>
                  </div>
                  <div class="custom-sidebar-text">
                    <span>Degree Level</span>
                  </div>
                </div>
                <div class="custom-sidebar-icons custom-add-btn">
                  <a href="#" class="custom-sidebar-link">
                    <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                  </a>
                </div>
              </div>
            </a>
            <div id="id-degreelevel" class="collapse custom-collapse collapse" aria-labelledby="sidebar-tabs"
              data-parent="#sidebar-tabs">
              <b-form-group class="border-0">
                <b-form-checkbox v-for="option in degreeLevels" v-model="filter.DegreeLevels" :key="option.key"
                  :value="option.key" class="mt-2 custom-checkbox-color" @change="getJobsByFilters">
                  {{ option.value }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-9">
        <div class="">
          <div class="custom-category-title" v-if="isCategoryVisible">
            <span style="color: #ea216c"><b-icon icon="caret-right-fill"></b-icon></span>
            <span v-if="categoryValue != []" class="text-capitalize">
              <span v-for="(data, index) in categoryValue" :key="data.id">
                <span>{{ data.title }}<span class="ml-1 mr-1"> </span></span>
              </span>
            </span>
          </div>
          <div class="custom-jobs-heading text-center  pt-4 pb-4">


            <div class="container">
              <p class="headingstyles"><b>Find Your Next Job, Fast</b></p>
              <p>View by Experience, Search by Skills</p>
              <!--              <p>Search By Skills, View Salaries, One-Click Apply</p>-->
              <form class="">
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-5">
                    <div class="mb-2">
                      <!--                <label  class="sr-only">Email</label>-->
                      <input type="text" v-model="jobSearchByNameOrCity.jobTitle"
                        @keydown.enter.prevent="getJobsByNameAndCity"
                        class="form-control custom-input-gotopage custom-job-search-input" placeholder="Job Title">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="mb-2">
                      <!--                <label  class="sr-only">Password</label>-->
                      <select id="city_id" class="custom-select-option custom-select-city-option form-control"
                        v-model="selectedCity" selected="selectedCity">
                        <option value=0>Select City</option>
                        <option v-for="option in cities" :key="option.key" :value="option.key">
                          {{ option.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-2">
                      <button type="button" @click="getJobsByNameAndCity"
                        class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-search mb-2">
                        Search
                        <!--                      <b-icon icon="search"></b-icon>-->

                      </button>
                    </div>
                  </div>
                  <div class="col-md-1"></div>
                </div>



                <!--              <button type="submit" class=" btn btn-primary btn-apply mb-2">Search</button>-->
              </form>
            </div>



          </div>
          <hr>
          <div>
            <div class="container pt-3 pb-3 ml-auto mr-auto " :key="componentKey">


              <div v-if="visibleRecords.length != 0">
                <div v-for="(job, jobIndex) in visibleRecords" :key="job.jobId">

                  <div class="w3-card-2 pl-4 pr-4 pt-3 pb-3 w3-container mb-3 custom-job-card">



                    <div class="row align-items-center">
                      <div class="col-md-10">
                        <router-link class="text-decoration-none" :to="{
                          name: 'JobDetail',
                          params: { id: job.jobId }
                        }" title="Job Detail">
                          <div class="mb-2">

                            <span class="b-avatar badge-info rounded-circle"
                              style="width:3rem;height: 3rem; background-color: #fff">
                              <span class="b-avatar-img">
                                <span v-if="job.companyLogoAddress != null && job.companyLogoAddress != ''">
                                  <img :src="url + job.companyLogoAddress">
                                </span>
                                <span v-else>

                                  <img src="../assets/images/no-image.png">


                                </span>

                              </span>

                            </span>
                            <!--                            <img v-if="job.companyLogoAddress != null || job.companyLogoAddress != ''" :src="url+job.companyLogoAddress" alt="Avatar" class="rounded-circle" style="width:60px; height:60px">
                            <img v-else src="../assets/images/no-image.png" alt="Avatar" class=" w3-circle" style="width:60px">-->
                          </div>
                          <div class="mb-2 custom-muted-text">
                            <span>{{ job.companyName }}</span>
                            <!--                        <span>Itroos</span>-->
                          </div>
                          <div class="mb-2">
                            <h5 class="custom-job-title text-dark text-capitalize  m-0"><u>{{ job.jobTitle }}</u></h5>
                          </div>



                          <div class="custom-row mb-2 custom-muted-text">
                            <span class="mr-2"><i class="fas fa-map-marker-alt"></i></span>
                            <span>{{ job.cityName }}, {{ job.countryName }}</span>
                          </div>
                          <div class="custom-row mb-2 custom-muted-text">
                            <span class="mr-2"><i class="fas fa-money-bill"></i></span>
                            <span v-if="job.packageTo === 0 || job.packageTo === 1">Market Competitive Salary</span>
                            <span v-else>Rs {{ job.packageFrom }} - {{ job.packageTo }}/Month</span>
                          </div>

                          <div class="custom-row mb-2">
                            <span>
                              <b-badge class="custom-status-badge" variant="primary">{{ job.category }}</b-badge>
                            </span>
                            <div class="pl-1 pr-1"></div>
                            <span v-if="job.experiencelevel !== null">
                              <b-badge class="custom-status-badge" variant="primary">
                                <!--                            <span v-for="exp in yearsOfExperiences" :key="exp.key">
                              <span v-if="exp.key === job.requiredExperience">
                                <span v-if="exp.key > 1">Min </span>
                                <span v-else></span>
                                <span>{{ exp.value }}</span>

                              </span>
                            </span>-->

                                Experience: {{ job.experiencelevel }}
                              </b-badge>
                            </span>
                            <div class="pl-1 pr-1"></div>
                            <span>
                              <b-badge class="custom-status-badge" variant="primary">{{ job.jobTypeTitle }}</b-badge>
                            </span>
                          </div>
                        </router-link>
                        <div v-if="job.jobDescription != null" class="custom-muted-text text-justify">
                          <span class="font-weight-bold">Job Description:</span>
                          <br>
                          <span v-if="job.jobDescription.length > 100">
                            <span>{{ job.jobDescription.substr(0, 103) }}</span>
                            <span class="collapse w3-animate-opacity" :id="'readMoreSection' + jobIndex + 1">
                              {{ job.jobDescription.substr(103, job.jobDescription.length) }}
                            </span>
                            <span>
                              <a @click="onAreaExpand" class="custom-text-color text-decoration-none"
                                data-toggle="collapse" :href="'#readMoreSection' + jobIndex + 1" role="button"
                                aria-expanded="false" aria-controls="collapseExample">

                                <span class="w3-animate-opacity" style="float: right;"
                                  v-if="isAreaExpanded == false">Read More>></span>
                                <span class="w3-animate-opacity" style="float: right;" v-else>
                                  Read Less</span>
                              </a>
                            </span>

                          </span>
                          <span v-else>
                            {{ job.jobDescription }}
                          </span>
                        </div>
                        <div v-else></div>
                      </div>


                      <div class="col-md-2 text-center">
                        <!--                    <div class="mb-2" v-for="(date, dateIndex) in dateDifferences" :key="dateIndex">
                                              <i class="font-weight-bold custom-muted-text " v-if="jobIndex == dateIndex">
                                                {{ date }}
                                                <span v-if="date > 1">Days</span>
                                                <span v-else>Day</span>
                                                Ago
                                              </i>
                                            </div>-->

                        <div class="mb-3">
                          <span class="flex-grow-1">
                            <!-- <button type="button"  @click="submitAssessment(job.jobId)" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2" data-toggle="modal" data-target="#surveyModal">
                              ASSESSMENT 
                            </button>
                            
                          </span>
                          </div>
<div class="modal" id="surveyModal">
  <div class="modal-dialog">
    <div class="modal-content" style="width: 750px;
    margin-left: -80px;">

       Modal Header -->
                            <!-- <div class="modal-header" style="padding-left: 270px;">
        <h4 class="modal-title">PERSONALITY ASSESSMENT</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div> -->

                            <!-- Modal Body -->
                            <!-- <div class="modal-body" style=" padding: 1rem;
    text-indent: 29px;">
        <form>
          <p>NAME: <input id="username" v-model="username" type="text"></p>

          <p>
        1. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q1" name="q1" value="A)Restrained (Hold back, do not share)"> Restrained (Hold back, do not share)</label>
        <label class="radio-inline"><input type="radio" id="q1" name="q1" value="B)Forceful (to persuade)"> Forceful (to persuade)</label>
        <label class="radio-inline"><input type="radio" id="q1" name="q1" value="C)Careful (Deliberate, Slow)"> Careful (Deliberate, Slow) </label>
        <label class="radio-inline"><input type="radio" id="q1" name="q1" value="D)Expressive (reveal & show emotion)"> Expressive (reveal & show emotion)</label> 
    </p>

    <p>
        2. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q2" name="q2" value="A)Pioneering (Will try new ideas)"> Pioneering (Will try new ideas)</label>
        <label class="radio-inline"><input type="radio" id="q2" name="q2" value="B)Correct (Precise)"> Correct (Precise)</label>
        <label class="radio-inline"><input type="radio" id="q2" name="q2" value="C)Exciting (Eager)">Exciting (Eager)</label>
        <label class="radio-inline"><input type="radio" id="q2" name="q2" value="D)Satisfied (Contented)">Satisfied (Contented)</label>
    </p>

     
    <p>
        3. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q3" name="q3" value="A)Willing (Glad to help others)"> Willing (Glad to help others)</label>  
        <label class="radio-inline"><input type="radio" id="q3" name="q3" value="B)Animated(Lively and Active)"> Animated(Lively and Active)</label> 
        <label class="radio-inline"><input type="radio" id="q3" name="q3" value="C)Bold (Daring to take risk)"> Bold (Daring to take risk)</label>
        <label class="radio-inline"><input type="radio" id="q3" name="q3" value="D)Precise(Doing things accurately)"> Precise(Doing things accurately)</label>
    </p>
    
    <p>
        4. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q4" name="q4" value="A)"> Argumentative(Quarrels with other)</label>
        <label class="radio-inline"><input type="radio" id="q4" name="q4" value="B)"> Doubting(Want to see proof)</label>
        <label class="radio-inline"><input type="radio" id="q4" name="q4" value="C)"> Indecisive(Hard to decide)</label>
        <label class="radio-inline"><input type="radio" id="q4" name="q4" value="D)"> Unpredictable()</label> 
    </p>  
    <p>
        5. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q5" name="q5" value="A)"> Argumentative</label>
        <label class="radio-inline"><input type="radio" id="q5" name="q5" value="B)"> Quarrels with others</label>
        <label class="radio-inline"><input type="radio" id="q5" name="q5" value="C)"> Respectful</label>
        <label class="radio-inline"><input type="radio" id="q5" name="q5" value="D)"> Animated</label>
    </p>
    
    <p>
        6. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q6" name="q6" value="A)"> Patient</label>
        <label class="radio-inline"><input type="radio" id="q6" name="q6" value="B)"> Listen </label>
        <label class="radio-inline"><input type="radio" id="q6" name="q6" value="C)"> Perfect</label>
        <label class="radio-inline"><input type="radio" id="q6" name="q6" value="D)"> Popular</label>
    </p>
    


    <p>
        7. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q7" name="q7" value="A)"> Reserved</label> 
        <label class="radio-inline"><input type="radio" id="q7" name="q7" value="B)"> Not sharing thoughts</label>
        <label class="radio-inline"><input type="radio" id="q7" name="q7" value="C)"> Steady</label> 
        <label class="radio-inline"><input type="radio" id="q7" name="q7" value="D)"> Aggressive</label>
    </p>
    
    <p>
        8. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q8" name="q8" value="A)"> Attractive</label>
        <label class="radio-inline"><input type="radio" id="q8" name="q8" value="B)"> Pleasing to others</label>
        <label class="radio-inline"><input type="radio" id="q8" name="q8" value="C)"> Systematic</label>
        <label class="radio-inline"><input type="radio" id="q8" name="q8" value="D)"> Easy-going</label>
    </p>
    
    <p>
        9. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q9" name="q9" value="A)"> Colorful</label> 
        <label class="radio-inline"><input type="radio" id="q9" name="q9" value="B)"> Lively and unique</label>
        <label class="radio-inline"><input type="radio" id="q9" name="q9" value="C)"> Cheerful, standing out</label>  
        <label class="radio-inline"><input type="radio" id="q9" name="q9" value="D)"> Modest</label>
    </p>
    
    <p>
        10. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q10" name="q10" value="A)"> Unyielding</label>
        <label class="radio-inline"><input type="radio" id="q10" name="q10" value="B)"> Not flexible</label>
        <label class="radio-inline"><input type="radio" id="q10" name="q10" value="C)"> Not willing to change</label>
        <label class="radio-inline"><input type="radio" id="q10" name="q10" value="D)"> Charming</label>
    </p>
    
    <p>
        11. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q11" name="q11" value="A)"> Adventurous</label>  
        <label class="radio-inline"><input type="radio" id="q11" name="q11" value="B)"> Will try new ideas</label>
        <label class="radio-inline"><input type="radio" id="q11" name="q11" value="C)"> Disciplined</label>
        <label class="radio-inline"><input type="radio" id="q11" name="q11" value="D)"> Deliberate</label>
    </p>
    
    <p>
        12. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q12" name="q12" value="A)"> Relentless</label>
        <label class="radio-inline"><input type="radio" id="q12" name="q12" value="B)"> Strict</label> 
        <label class="radio-inline"><input type="radio" id="q12" name="q12" value="C)"> Humble</label>  
        <label class="radio-inline"><input type="radio" id="q12" name="q12" value="D)"> Neighborly</label>
    </p>
    
    <p>
        13. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q13" name="q13" value="A)"> Talkative</label>
        <label class="radio-inline"><input type="radio" id="q13" name="q13" value="B)"> Like to talk</label> 
        <label class="radio-inline"><input type="radio" id="q13" name="q13" value="C)"> Friendly</label>
        <label class="radio-inline"><input type="radio" id="q13" name="q13" value="D)"> Observant</label>
    </p>
    
    <p>
        14. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q14" name="q14" value="A)"> Playful</label>
        <label class="radio-inline"><input type="radio" id="q14" name="q14" value="B)"> Wanting to have fun</label>
        <label class="radio-inline"><input type="radio" id="q14" name="q14" value="C)"> Strong-willed</label>
        <label class="radio-inline"><input type="radio" id="q14" name="q14" value="D)"> Sticks to decision</label>
    </p>
    
    <p>
        15. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q15" name="q15" value="A)"> Enthusiastic</label>
        <label class="radio-inline"><input type="radio" id="q15" name="q15" value="B)"> Full of energy and motivation</label> 
        <label class="radio-inline"><input type="radio" id="q15" name="q15" value="C)"> Analytical</label> 
        <label class="radio-inline"><input type="radio" id="q15" name="q15" value="D)"> Break down whole into step</label>
    </p>
    
    <p>
        16. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q16" name="q16" value="A)"> Accommodating</label>
        <label class="radio-inline"><input type="radio" id="q16" name="q16" value="B)"> To do a favor</label>
        <label class="radio-inline"><input type="radio" id="q16" name="q16" value="C)"> Commanding</label>
        <label class="radio-inline"><input type="radio" id="q16" name="q16" value="D)"> Controlling</label>
    </p>
    
    <p>
        17. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q17" name="q17" value="A)"> Impulsive</label>
        <label class="radio-inline"><input type="radio" id="q17" name="q17" value="B)"> Spontaneous</label> 
        <label class="radio-inline"><input type="radio" id="q17" name="q17" value="C)"> Slow-paced</label>
        <label class="radio-inline"><input type="radio" id="q17" name="q17" value="D)"> Moving at a rate less than usual</label>
    </p>
    
    <p>
        18. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q18" name="q18" value="A)"> Critical</label>  
        <label class="radio-inline"><input type="radio" id="q18" name="q18" value="B)"> Judgmental</label>
        <label class="radio-inline"><input type="radio" id="q18" name="q18" value="C)"> Finding faults</label>
        <label class="radio-inline"><input type="radio" id="q18" name="q18" value="D)"> Unpredictable</label>
    </p>
    
    <p>
        19. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q19" name="q19" value="A)"> Outgoing</label>
        <label class="radio-inline"><input type="radio" id="q19" name="q19" value="B)"> Not shy</label>
        <label class="radio-inline"><input type="radio" id="q19" name="q19" value="C)"> Share thoughts</label>  
        <label class="radio-inline"><input type="radio" id="q19" name="q19" value="D)"> Daring</label>
    </p>
    
    <p>
        20. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q20" name="q20" value="A)"> Impatient</label> 
        <label class="radio-inline"><input type="radio" id="q20" name="q20" value="B)"> Restless, Eager</label>
        <label class="radio-inline"><input type="radio" id="q20" name="q20" value="C)"> Serious</label>
        <label class="radio-inline"><input type="radio" id="q20" name="q20" value="D)"> Sincere, Earnest, not joking</label>
    </p>
    <p>
        21. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q21" name="q21" value="A)"> Outgoing</label>
        <label class="radio-inline"><input type="radio" id="q21" name="q21" value="B)"> Not shy</label>
        <label class="radio-inline"><input type="radio" id="q21" name="q21" value="C)"> Share thoughts</label>  
        <label class="radio-inline"><input type="radio" id="q21" name="q21" value="D)"> Daring</label>
    </p>
    
    <p>
        22. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q22" name="q22" value="A)"> Impatient</label> 
        <label class="radio-inline"><input type="radio" id="q22" name="q22" value="B)"> Restless, Eager</label>
        <label class="radio-inline"><input type="radio" id="q22" name="q22" value="C)"> Serious</label>
        <label class="radio-inline"><input type="radio" id="q22" name="q22" value="D)"> Sincere, Earnest, not joking</label>
    </p>
    <p>
        23. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q23" name="q23" value="A)"> Outgoing</label>
        <label class="radio-inline"><input type="radio" id="q23" name="q23" value="B)"> Not shy</label>
        <label class="radio-inline"><input type="radio" id="q23" name="q23" value="C)"> Share thoughts</label>  
        <label class="radio-inline"><input type="radio" id="q23" name="q23" value="D)"> Daring</label>
    </p>
    
    <p>
        24. Please choose one option<br>
        <label class="radio-inline"><input type="radio" id="q24" name="q24" value="A)"> Impatient</label> 
        <label class="radio-inline"><input type="radio" id="q24" name="q24" value="B)"> Restless, Eager</label>
        <label class="radio-inline"><input type="radio" id="q24" name="q24" value="C)"> Serious</label>
        <label class="radio-inline"><input type="radio" id="q24" name="q24" value="D)"> Sincere, Earnest, not joking</label>
    </p>


        </form>
      </div>

       Modal Footer -->
                            <!-- <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="getSelectedValues">Submit</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div> -->

                            <span v-if="disableEasyApplyButton">
                              <!-- <span> -->
                              <a @click="showOrHideModal(job)"
                                class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2">Easy Apply Now</a>
                            </span>
                          </span>
                        </div>
                        <!--                          <span  v-else>
                                                    <a type="button" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2 custom-btn-bookmark ">Applied</a>
                                                  </span>-->
                        <div class="">
                          <a class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark"
                            @click="onSavingJob(job)">Save</a>
                        </div>
                        <!--                    <div class="mb-3">
                                              <router-link class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark">More</router-link>
                                            </div>-->
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div v-else>

              </div>
              <!--   Spacer   -->
              <div class="pt-5"></div>

              <div class="text-center clear-bottom">
                <div class="row no-gutters ">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-2"></div>
                  <div class="col-lg-2" v-if="visibleRecords != null">
                    <!--  View More Button    -->
                    <div>
                      <div v-if="recordsVisible < jobsList.length">
                        <div class="text-center clear-bottom">
                          <a href="javascript:void(0)" @click="recordsVisible += step"
                            class="btn btn-theme text-decoration-none">
                            View More
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-lg-2"></div>
                  <div class="col-lg-3"></div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <CPModal v-if="isModalVisible" :save-job-status="isSaveJobStatus" :signInOrSignUpSection="isSignInOrSignUpSection"
      :employeeProfileFormSection="isEmployeeProfileFormSection" :show-alert-msg="isShowAlertMsg"
      :show-profile-info-card="isShowProfileInfoCard" :apply-job-confirmation-section="isApplyJobConfirmationSection"
      :selected-job-record="jobRecord" @get-strength="recievedStrength" @toggle-modal="toggleModal">

    </CPModal>
    <ConfirmationModal v-if="isConfirmationModalVisible" @toggle-modal="closeModal"></ConfirmationModal>

  </div>
</template>
<script>
import Vue from 'vue';
import axios from "axios";
import moment from "moment";
//Vue.use(moment);
import CPModal from "../components/employeeComponents/CompleteProfileModalComponent";
import _ from "underscore";
import ConfirmationModal from "../components/ConfirmationModal";
export default {
  name: "AllJobs",
  components: {
    ConfirmationModal,
    CPModal,
  },
  data() {
    return {
      url: axios.defaults.baseURL,
      info: null,
      list: [],
      username: '',
      //alert msg
      dismissSecs: 5,
      dismissCountDown: 0,
      jobId: 0,


      isCategoryVisible: false,
      isCategoryChecklistVisible: false,
      categoryValue: {
        value: []
      },
      selectedCity: 0,
      jobSearchByNameOrCity: {
        jobTitle: "",
        cityId: null
      },
      isSelectedOther: false,
      otherOptions: [
        { item: false, value: "Others" }
      ],
      ShowDisabilityTypesChecks:false,
      selectedDisabilityType:null,
      filter: {
        JobTypes: [],
        JobSubTypes: [],
        disabilityTypes:[],
        Cities: [],
        DegreeLevels: [],
        EmpExperiences: [],
        isOthersSelected: false
      },
      //cities:null,
      //categories:[],
      categoriesCount: null,
      //jobTypes:[],
      //filteredExperiences:[],
      //yearsOfExperiences:[],

      //filteredCities:[],
      //degreeLevels:[],

      currentDate: new Date(),
      insertionDates: [],
      dateDifferences: [],
      isApplyNow_btn: true,
      isApplied_btn: false,
      disableEasyApplyButton: false,
      showAssessmentButton: false,
      unansweredQuestions: [],

      job: {
        JobId: 0,
        EmployeeId: 0,
        ExpectedSalary: 0,
        Status: 1,
        IsApplicantViewed: false
      },

      isSaveJobStatus: false,


      saveJob: {
        saveJobId: 0,
        jobId: 0,
        employeeId: 0,
        isActive: true

      },
      jobID: 0,
      jobRecord: null,
      jobsStatuses: null,
      employeelist: null,
      employeeId: 0,
      employeeSkill: null,
      employeeExperience: null,
      employeeEducation: null,
      profileStrength: 0,


      isButtonVisible: false,

      //combined list with status
      listWithStatus: [],
      SelectedValues: [],
      //Complete Profile Modal
      isModalVisible: false,
      isConfirmationModalVisible: false,
      isSignInOrSignUpSection: false,
      isEmployeeProfileFormSection: false,
      isShowAlertMsg: false,
      isShowProfileInfoCard: false,
      isApplyJobConfirmationSection: false,
      modalMsg: "",
      showAlertMsg: false,
      modalDescription: "",
      errored: false,
      componentKey: 0,

      //Read More Collapse
      isAreaExpanded: false,

      // Show More Functionality
      isShowMoreBtnVisible: false,
      recordsVisible: 5,
      experiencesVisible: 10,
      step: 5,
      selectedJobSubType: 0
    };
  },
  mounted() {

    this.$root.$on('data-of-search', (msg) => {
      debugger
      console.log(msg);
      this.selectedCity = msg.cityId;
      this.jobSearchByNameOrCity.jobTitle = msg.jobTitle;
      console.log(this.jobSearchByNameOrCity);
    })
    this.getAllEmployees();
    this.getCategoryList();
    this.getJobTypesList();
    this.getExperienceList();
    this.getCityList();
    this.buttonsMethod();
    this.getDisabilityTypesList();
    this.getDegreeLevelsList();
    const triggerFilter = this.$route.query.triggerFilter;
    const searchFilter = this.$route.query.searchFilter ? JSON.parse(this.$route.query.searchFilter) 
    : null;

    if (!triggerFilter) {
      this.getAll();

    }else{
      this.jobSearchByNameOrCity.jobTitle=searchFilter.jobTitle;
      this.selectedCity = searchFilter.cityId ? searchFilter.cityId : 0;
    }
    // if (this.$route.params.key != undefined && this.jobSearchByNameOrCity != null && this.selectedCity != null) {
    //   this.filter.JobSubTypes = [];
    //   this.filter.JobSubTypes.push(this.$route.params.key);
    //   this.getJobsByFilters()
    // }
    // else {
    //   this.getAll();
    // }
    // if (this.jobList.companyId == 212) {
    //   this.showAssessmentButton = true;
    // }

  },
  beforeCreate() {
    return this.$options.computed.visibleRecords;

  },

  computed: {
    jobsList() {

      console.log("joblist : ", this.$store.state.jobList)

      return this.$store.state.jobList;
    },
    visibleRecords() {
      return this.jobsList.slice(0, this.recordsVisible)
    },
    cities() {
      return this.$store.getters.availableCities
    },
    categories() {
      return this.$store.getters.availableCategories
    },
    disabilityTypes(){
      return this.$store.getters.availableDisabilityTypes
    },

    jobTypes() {
      return this.$store.getters.availableJobTypes
    },
    filteredExperiences() {
      return this.$store.getters.availableExperiences
    },
    visibleExperiences() {
      return this.filteredExperiences.slice(0, this.experiencesVisible)
    },

    filteredCities() {
      return this.$store.getters.availableFilteredCities
    },
    degreeLevels() {
      return this.$store.getters.availableDegreeLevels
    },
    categoryFilter() {
      console.log("this.$store.state.categoryFilter : ", this.$store.state.categoryFilter)
      return this.$store.state.categoryFilter;
    },


  },
  /*created() {

    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },*/
  methods: {
    getSelectedValues() {
      var radioButtons = [];
      var selectedValues = {};

      // Assuming you have radio buttons with names 'q1' to 'q24'
      for (var i = 1; i <= 24; i++) {
        radioButtons[i] = document.getElementsByName('q' + i);
        var userid = 0;
        var selectedInGroup = false;

        // Loop through the radio buttons to find the selected one
        for (var j = 0; j < radioButtons[i].length; j++) {
          if (radioButtons[i][j].checked) {

            selectedInGroup = true;

            selectedValues['q' + i] = radioButtons[i][j].value;
            var entry = {
              JobId: this.jobId,
              UserId: userid,
              Username: "test",
              QuestionNo: radioButtons[i][j].name,
              Answers: radioButtons[i][j].value
            };
            this.SelectedValues.push(entry);
            break;
          }
        }
        if (!selectedInGroup) {
          // Record unanswered question number
          this.unansweredQuestions.push(i);
        }
      }

      if (this.unansweredQuestions.length > 0) {
        // Show an alert or handle unanswered questions as needed
        alert('Please select an option for questions: ' + this.unansweredQuestions.join(', '));
        // Clear the unanswered questions array for the next attempt
        this.unansweredQuestions = [];
        return;
      }

      console.log(this.SelectedValues);
      this.disableEasyApplyButton = true;


      // const modal = document.getElementById('surveyModal');
      // modal.style.display = 'none';


      axios
        .post("/api/Job/InsertAssesment", this.SelectedValues)
        .then(res => {
          // code that we will 'try' to run

          console.log("InsertAssesment", res);
          console.log("InsertAssesment data", res.data);
          console.log("InsertAssesment data2", res.data.data);
          if (res.data.data == 3000) {
            alert('Already submitted the Assessment!');
            window.location.reload();
          }
          if (res.data.data == 2000) {
            alert('Assesment Saved Successfully!');
            window.location.reload();
          }
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
      console.log(selectedValues);
    },
    getAll() {
      this.rerender();

      console.log("this.$route.params : ", this.$route.params);
      console.log("this.$route.params.cId : ", this.$route.params.cId);

      // Check if a company ID is provided in the route parameters
      if (this.$route.params.cId != undefined) {
        this.$store.dispatch("getJobsByCompanyId", this.$route.params.cId);

        // Adjust packageFrom if packageTo is 0
        if (this.job.packageTo === 0 || this.job.packageTo === 1) {
          this.job.packageFrom = null;
        }
      }
      else {
        this.$store.dispatch("getAll");

        // Adjust packageFrom if packageTo is 0
        if (this.job.packageTo === 0 || this.job.packageTo === 1) {
          this.job.packageFrom = null;
        }
      }

      // Ensure jobData is initialized to an empty object
      if (!this.jobData) {
        this.jobData = {};
      }
    },


    showMore() {
      this.recordsVisible += 5;
    },
    getAllEmployees() {
      //this.rerender();
      //this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }



          this.employeelist = res.data.employee;
          console.log("employeelist : ", this.employeelist);


          this.employeeId = this.employeelist.employeeId;
          this.job.ExpectedSalary = this.employeelist.expectedSalary;

          this.employeeSkill = res.data.eMPSkills;
          console.log("employeeSkill : ", this.employeeSkill);
          this.employeeExperience = res.data.eMPExperiences;



          this.employeeEducation = res.data.empEducations;
          console.log("employeeEducation : ", this.employeeEducation);


          this.CalculatePercentage();


          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    getJobsByNameAndCity() {
      //this.list = [];
      if (this.jobSearchByNameOrCity.jobTitle != "" || this.selectedCity != 0) {

        this.jobSearchByNameOrCity.cityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity)


        this.$store.dispatch("getJobsByNameAndCityId", this.jobSearchByNameOrCity)

        /* axios
             .post("/api/Job/GetJobsSearch", this.jobSearchByNameOrCity)
             .then(res => {
               // code that we will 'try' to run
   
               let jobList = res.data.data;
               let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
               for(let job in jobList){
                 if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   this.list.push(jobList[job]);
                 }
               }
   
   
                 console.log("Add Response portion");
   
                 console.log("response : ", this.list);
               this.getCategories(this.list);
               this.getJobTypes(this.list);
               this.getExperiences(this.list);
               this.getFilteredCities(this.list);
               //this.getDegreeLevel(this.list);
   
               if(this.list.length == 0){
                 this.$bvToast.toast("No Record Found ", {
                   toaster: "b-toaster-top-center",
                   variant: "warning",
   
                   solid: true
                 });
               }
   
             })
             .catch(error => {
               console.log("Errorrrrrr : ",error);
               this.errored = true;
             })
             .finally(() => {
               if (this.errored) {
   
                 console.log("Error : Sorry there is no response");
                 // this.$bvToast.toast("Sorry there is no response ", {
                 //   toaster: "b-toaster-top-center",
                 //   variant: "danger",
   
                 //   solid: true,
                 // });
               } else {
   
                 /!*this.$bvToast.toast("Updated Successfully ", {
                   toaster: "b-toaster-top-center",
                   variant: "success",
   
                   solid: true
                 });*!/
   
   
               }
             });*/
      }
      else {

        this.$route.params.jobTitle = undefined;
        this.$route.params.selectedCity = undefined;
        this.getAll();
      }
    },

    /* getJobsByNameAndCityOnHomePage(){
       if(this.$route.params.searchString != undefined){
         console.log("jobTitle : ",this.$route.params.searchString)
         console.log("selectedCity : ",this.$route.params.selectedCity)
         this.jobSearchByNameOrCity.jobTitle = "";
         this.jobSearchByNameOrCity.cityId = null;
         this.jobSearchByNameOrCity.jobTitle = this.$route.params.searchString;
         this.jobSearchByNameOrCity.cityId = (this.$route.params.selectedCity == 0 || this.$route.params.selectedCity == null) ? null : Number(this.$route.params.selectedCity);
         this.getJobsByNameAndCityOnHomePage();
       }
 
 
       console.log("this.$route.params : ", this.$route.params);
 
 
 
       /!*this.list = [];
       if(this.jobSearchByNameOrCity.cityId != 0 || this.jobSearchByNameOrCity.jobTitle != "" ){
 
 
 
 
         axios
             .post("/api/Job/GetJobsSearch", this.jobSearchByNameOrCity)
             .then(res => {
               // code that we will 'try' to run
 
               let jobList = res.data.data;
               let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
               for(let job in jobList){
                 if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   this.list.push(jobList[job]);
                 }
               }
 
               console.log("Add Response portion");
 
               console.log("response : ", this.list);
               this.getCategories(this.list);
               this.getJobTypes(this.list);
               this.getExperiences(this.list);
               this.getFilteredCities(this.list);
               //this.getDegreeLevel(this.list);
 
               if(this.list.length == 0){
                 this.$bvToast.toast("No Record Found ", {
                   toaster: "b-toaster-top-center",
                   variant: "warning",
 
                   solid: true
                 });
               }
 
             })
             .catch(error => {
               console.log("Errorrrrrr : ",error);
               this.errored = true;
             })
             .finally(() => {
               if (this.errored) {
 
                 console.log("Error : Sorry there is no response");
                 // this.$bvToast.toast("Sorry there is no response ", {
                 //   toaster: "b-toaster-top-center",
                 //   variant: "danger",
 
                 //   solid: true,
                 // });
               } else {
 
                 /!*this.$bvToast.toast("Updated Successfully ", {
                   toaster: "b-toaster-top-center",
                   variant: "success",
 
                   solid: true
                 });*!/
 
 
               }
             });
       }
       else {
         this.getAll();
       }*!/
     },
     getJobsByName(title){
       this.rerender();
       this.list = [];
       if(title != ""){
         this.jobSearchByNameOrCity.jobTitle = title;
         this.jobSearchByNameOrCity.cityId = null
 
 
 
         axios
             .post("/api/Job/GetJobsSearch", this.jobSearchByNameOrCity)
             .then(res => {
               // code that we will 'try' to run
 
               let jobList = res.data.data;
               let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
               for(let job in jobList){
                 if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   this.list.push(jobList[job]);
                 }
               }
 
 
               console.log("Add Response portion");
 
               console.log("response : ", this.list);
               this.getCategories(this.list);
               this.getJobTypes(this.list);
               this.getExperiences(this.list);
               this.getFilteredCities(this.list);
               //this.getDegreeLevel(this.list);
 
               if(this.list.length == 0){
                 this.$bvToast.toast("No Record Found ", {
                   toaster: "b-toaster-top-center",
                   variant: "warning",
 
                   solid: true
                 });
               }
 
             })
             .catch(error => {
               console.log("Errorrrrrr : ",error);
               this.errored = true;
             })
             .finally(() => {
               if (this.errored) {
 
                 console.log("Error : Sorry there is no response");
                 // this.$bvToast.toast("Sorry there is no response ", {
                 //   toaster: "b-toaster-top-center",
                 //   variant: "danger",
 
                 //   solid: true,
                 // });
               } else {
 
                 /!*this.$bvToast.toast("Updated Successfully ", {
                   toaster: "b-toaster-top-center",
                   variant: "success",
 
                   solid: true
                 });*!/
 
 
               }
             });
       }
       else {
 
         this.$route.params.jobTitle = undefined;
         this.$route.params.selectedCity = undefined;
         this.getAll();
       }
     },*/
    /*getJobsByCompanyId(jobRecord){
      this.list = [];

      console.log("jobRecord in company : ",jobRecord)

      let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
      console.log("currentDate Format : ",currentDate)
      for (let data in jobRecord){

        console.log("expired date: ", moment(String(jobRecord[data].jobExpiryDate)).format('YYYY-MM-DD') ,": current date : ", currentDate)
        if((jobRecord[data].companyId === this.$route.params.setCompanyId && jobRecord[data].status == "Approved") && moment(String(jobRecord[data].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
          /!*this.list[data] = jobRecord [data];*!/
          this.list.push(jobRecord [data]);
        }

      }
      console.log("this.list in company : ",this.list);

    },*/



    //Get Dropdowns List
    getCityList() {

      this.$store.dispatch("getCityList")

      /* this.cities = null;
       let model = {
         type: "City"
       };
 
       axios
           .post("/api/KeyValuePair/GetDropDownListByIds", model)
           .then(res => {
             // code that we will 'try' to run
             this.cities = res.data.data;
 
             console.log("Add Response portion");
 
             console.log("cities : ", this.cities);
           })
           .catch(error => {
             console.log("Errorrrrrr : ",error);
             this.errored = true;
           })
           .finally(() => {
             if (this.errored) {
 
               console.log("Error : Sorry there is no response");
               // this.$bvToast.toast("Sorry there is no response ", {
               //   toaster: "b-toaster-top-center",
               //   variant: "danger",
 
               //   solid: true,
               // });
             } else {
 
 
 
 
 
 
             }
           });*/
    },
    getCategoryList() {

      this.$store.dispatch("getCategories")

    },
    getExperienceList() {

      this.$store.dispatch("getExperiences")
    },
    getJobTypesList() {

      this.$store.dispatch("getJobTypes")

    },

    getDegreeLevelsList() {

      this.$store.dispatch("getDegreeLevel")


    },
    getDisabilityTypesList(){
      this.$store.dispatch("getDisailityTypes")
      console.log("get Disability is hit")
    },
    toggleDisabilityTypes() {
      
      if (chkDisability) {
        // Toggle the display between 'block' and 'none'
        
    }},


    getJobsByFilters() {
      this.rerender();
      this.isCategoryVisible = false;
      this.list = [];
      if (this.selectedJobSubType == 30) {
        const chkDisability = document.getElementById('chkDisabiltyTypes');
        chkDisability.classList.remove("hideDiv");
      } else {
        const chkDisability = document.getElementById('chkDisabiltyTypes');
        chkDisability.classList.add("hideDiv");
      }
      console.log("this.filter in jobboard : ", this.filter);
      //this.jobSearchByNameOrCity.cityId = Number(this.selectedCity);
      if (this.filter.JobTypes != [] || this.selectedJobSubType != 0 || this.filter.Cities != [] || this.filter.EmpExperiences != [] || this.filter.disabilityTypes!=[]) {

        /*console.log("this.isSelectedOther : ", this.isSelectedOther)
        this.filter.isOthersSelected = !Boolean(this.isSelectedOther);*/
        if(this.selectedJobSubType==false){
          this.selectedJobSubType=0;
          this.filter.JobSubTypes = [this.selectedJobSubType];
        }else{
          this.filter.JobSubTypes = [this.selectedJobSubType];

        }
       
console.log("thisisthefilter",this.filter)
        this.$store.dispatch("getJobsByFilters", this.filter)

        this.categoryValue = []

        if (this.selectedJobSubType) {
          const selectedCategory = this.categories.find(
            (data) => data.key === this.selectedJobSubType
          );

          if (selectedCategory) {
            this.categoryValue.push({
              id: selectedCategory.key,
              title: selectedCategory.value,
            });
          }
        }


        // for (const item of this.filter.JobSubTypes) {
        //   for (const data of this.categories) {

        //     if (item == data.key) {
        //       this.categoryValue.push({
        //         id: data.key,
        //         title: data.value
        //       });
        //     }
        //   }

        // }

        console.log("categoryValueList : ", this.categoryValue)
        if (this.categoryValue.length != 0) {
          this.isCategoryVisible = true;
        }
        else {
          this.isCategoryVisible = false;
        }


      }
      else {

        this.getAll();

      }
    },
    buttonsMethod() {
      // if (this.jobList.companyId == 212) {
      // If the company name is 'Unze London', show the assessment button and enable the easy apply button
      this.showAssessmentButton = false;
      this.disableEasyApplyButton = true;
      // } else {
      //   // If the company name is not 'Unze London', hide the assessment button and disable the easy apply button
      //   this.showAssessmentButton = true;
      //   this.disableEasyApplyButton = false;
      // }
    },
    submitAssessment(jobId) {
      // Your logic to submit the assessment modal
      this.jobId = jobId;
      // After submission, disable the easy apply button
      this.disableEasyApplyButton = true;
    },

    showOrHideModal(jobrecord) {
      this.jobRecord = jobrecord;

      if (this.$auth.check()) {
        console.log("chek : ", this.$auth.check());
        if (this.$auth.user().user.role != 'Employer') {
          if (this.profileStrength > 80) {
            console.log("total strength inside if statement after Employee Skill section: ", this.profileStrength)

            this.isModalVisible = true;

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = false;
            this.isShowProfileInfoCard = true;
            this.isApplyJobConfirmationSection = true;
            console.log("job strength inside if statement: ", this.profileStrength)
            console.log("jobRecord inside if statement: ", this.jobRecord)
          }
          else {
            this.$bvToast.toast("Your profile Strength Must be Over 80%, Please Update your Profile", {
              toaster: "b-toaster-top-center",
              variant: "warning",

              solid: true
            });

            this.isModalVisible = true;
            console.log("job strength inside else statement: ", this.profileStrength)

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = true;
            this.isShowProfileInfoCard = false;
            this.isApplyJobConfirmationSection = false;



          }
        }
        else {
          this.isConfirmationModalVisible = true;
        }



      }
      else {
        console.log("chek : ", this.$auth.check());
        this.isModalVisible = true;
        this.isEmployeeProfileFormSection = false;
        //this.modalTitle = "Attention";
        this.isSignInOrSignUpSection = true;
        this.isShowAlertMsg = false;
        this.isShowProfileInfoCard = false;
        this.isApplyJobConfirmationSection = false;
        //this.modalDescription = "Please Register/Login Before Applying a Job"
      }
    },

    onSavingJob(jobrecord) {
      this.jobRecord = jobrecord;
      if (this.$auth.check()) {
        this.saveJob.jobId = Number(jobrecord.jobId);
        this.saveJob.employeeId = Number(this.employeeId);
        console.log("jobId + Emp ID : ", this.saveJob.jobId + this.saveJob.employeeId)
        axios
          .post("/api/Job/SaveJob", this.saveJob)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info.msg);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {


            if (this.info.msg == "Job Already Save") {
              this.$bvToast.toast("You have Saved to the Job Already. ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true
              });
            }
            else {
              this.$bvToast.toast("Job Saved Successfully", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });
            }
            this.getAll();
            this.getAllEmployees();


          });
      }
      else {
        this.$bvToast.toast("Please Sign Up / Sign In, Before Saving a job", {
          toaster: "b-toaster-top-center",
          variant: "warning",

          solid: true
        });
        setTimeout(this.showSignUpOrSignInModal(), 1000)
      }

    },

    showSignUpOrSignInModal() {
      this.isModalVisible = true;
      this.isSaveJobStatus = true;
      this.isEmployeeProfileFormSection = false;

      this.isSignInOrSignUpSection = true;
      this.isShowAlertMsg = false;
      this.isShowProfileInfoCard = false;
      this.isApplyJobConfirmationSection = false;
    },

    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
      this.getAll();
    },



    recievedStrength(value) {
      this.profileStrength = value;
      console.log("On All JOb Page: ", this.profileStrength);

    },
    CalculatePercentage() {

      //Employee

      let profilePercentUponSignUp = 50;
      let profilePercent = 40;
      // let expPercent = 20;
      // let eduPercent = 20;
      let skillPercent = 10;

      let percent = 0;

      if (this.employeelist != {}) {
        if ((this.employeelist.fullName != "" || this.employeelist.fullName != null) && (this.employeelist.mobileNumber != "" || this.employeelist.mobileNumber != null)) {
          percent += profilePercentUponSignUp;
          console.log("percent in signup : ", percent)
        }

        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);


        // if (this.employeelist.fatherName != "" && this.employeelist.fatherName != null && this.employeelist.cnic != "" && this.employeelist.dateofBirth != null && this.employeelist.gender != 0 && this.employeelist.gender != null) {

        if (this.employeelist.gender != 0 && this.employeelist.gender != null) {
          percent += profilePercent;

          console.log("percent in Profile : ", percent)
        }
        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);




      // if (this.employeeExperience.length != 0) {
      //   percent += expPercent;

      // }

      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Experience section : ", this.profileStrength);





      // if (this.employeeEducation.length != 0) {
      //   percent += eduPercent;

      // }

      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Education section : ", this.profileStrength);

      if (this.employeeSkill.length != 0) {
        percent += skillPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Skill section : ", percent);


    },

    onAreaExpand() {
      if (this.isAreaExpanded == false) {
        this.isAreaExpanded = true;
      }
      else {
        this.isAreaExpanded = false;
      }
    },
    rerender() {
      this.componentKey += 1;
    },
    closeModal() {
      // $("#addorEditModal").modal("hide");
      this.isConfirmationModalVisible = false;
    },



    //  For Mobile Responsive
    /*handleResize() {
      if(window.innerWidth <= 991)
      {

        this.contentClass = '';
      }
      else
      {

        this.contentClass = 'show';
      }

      // this.window.height = window.innerHeight;
    },
    handleMobile()
    {
      if(window.innerWidth <= 991)
      {
        this.isCollapsed = true;
      }
    }*/
  }
}
</script>

<style scoped>
.modal-header h4 {
  font-weight: bolder;
  color: Black;
}

.modal-body p {
  font-weight: bolder;
}

.modal-body p label {
  font-weight: normal;
}

.custom-collapse {
  background-color: #f7f7f7;
}

.headingstyles {
  font-size: x-large;
}

@media screen and (min-width: 720px) {
  .headingstyles {
    font-size: xx-large;
  }
}
.hideDiv{
  display: none;
}
</style>
