<template>
  <div id="main-container" class="mt-5 mb-5 ml-auto mr-auto">
    <div class="container">
      <!--<nav
        v-if="isDataLoaded"
        class="navbar navbar-expand-lg navbar-dark mt-30 filter-nav"
      >
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a class="nav-item nav-link active" href="#" style="border: 0px"
              >{{ jobdata.jobTitle }} <span class="sr-only">(current)</span></a
            >
          </div>
        </div>
      </nav>
      <div class="row mt-30">
        <div class="col-lg-3 text-center">
          <span v-if="isLoading" class="mb-2">
            <b-spinner small></b-spinner>
          </span>
          <b-alert :show="isMessageDismissed" dismissible fade variant="info">
            No Applicant(s) Found...
          </b-alert>
        </div>
      </div>
      <div
              v-if="isDataLoaded"
              class="w3-card-2 pt-3 pb-3 ml-auto mr-auto mt-30"
            >
              <header class="w3-container custom-row">
                <h3>Applicants</h3>
              </header>
              <hr />

              <button
                v-b-modal.modal-2
                class="btn btn-secondary"
                type="button"
                style="
                  background: rgb(114, 20, 126);
                  border-color: rgb(115, 21, 128);
                  width: 100%;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
              >
                Candidates Filter
              </button>

              <div
                style="max-height: 350px; overflow-y: auto; position: relative"
              >
                <div
                  v-for="appli in applicants.slice().reverse()"
                  :key="appli.jobApplicants"
                >
                  <div class="w3-container custom-row">
                    <div>
                      <p
                        class="
                          font-weight-bold
                          w3-text-pink
                          m-0
                          text-capitalize
                        "
                      >
                        {{ appli.employeeName }}
                      </p>
                      <!--                  <span>{{appli.employeeName}}</span>-->
      <!-- <p class="font-weight-bold text-black m-0">
                        <span v-if="appli.appliedDate === 0">Today</span>
                        <span v-else-if="appli.appliedDate === 1"
                          >{{ appli.appliedDate }} Day Ago</span
                        >
                        <span v-else>{{ appli.appliedDate }} Days Ago</span>
                      </p>
                    </div>
                    <div class="ml-auto">
                      <a
                        v-on:click="FetchEmployee(appli.employeeId)"
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary ml-auto"
                        >View</a
                      > -->
      <!-- <a
                        v-if="isApplicantViewd == true"
                        v-on:click="FetchEmployee(appli.employeeId)"
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary ml-auto"
                        >Viewed</a
                      >
                      <a
                      v-if="isApplicantViewd == false"
                        href="javascript:void(0)"
                        v-on:click="FetchEmployee(appli.employeeId)"
                        class="btn btn-outline-secondary ml-auto"
                        >View</a
                      > -->
      <!-- </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div> -->
      <div v-if="isDataLoaded" class="row no-gutters w-100">
        <div class="col-lg-3 mr-5">
          <div class="">
            <div class="w3-card-4 w3-center custom-profile-card" style="background-color:#ffffff" v-if="isEmpLoaded">
              <!--              <div class="pt-4 pb-4">
                              <div>
                                <div class="custom-profile-image-wrap" v-if="emp.employeeImage != null">
                                  <img :src="url+emp.employeeImage" alt="" class="w3-circle custom-profile-image">
                                  &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;
                                  <div class="custom-change-profile-image-btn-wrap">

                                  </div>
                                </div>
                                <div class="custom-profile-image-wrap" v-else>
                                  <div v-if="emp.gender == 1">
                                    <img src="../../assets/images/MaleUser.png" alt="" class="w3-circle custom-profile-image">
                                  </div>
                                  <div v-else>
                                    <img src="../../assets/images/FemaleUser1.png" alt="" class="w3-circle custom-profile-image">
                                  </div>

                                  &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;
                                  <div class="custom-change-profile-image-btn-wrap">

                                  </div>
                                </div>

                              </div>


                              <div class=" w3-center custom-profile-detail">
                                <h5 class="font-weight-bold text-capitalize">

                                  {{ emp.fullName }}

                                </h5>

                                <section class="cd-intro">
                                  <h5 class="cd-headline loading-bar">

                                  <span class="cd-words-wrapper">
                                    &lt;!&ndash; <b class=" ">{{ exp.fullName }}</b> &ndash;&gt;
                                    <b v-for="exp in empdata.eMPExperiences" :key="exp.empExperienceId ">
                                      {{ exp.jobTitle }}
                                    </b>

                                  </span>
                                  </h5>

                                </section>
                                &lt;!&ndash;                <p class="">
                                                  <span v-for="exp in employeeExperience" :key="exp.empExperienceId">
                                                      <span>{{ exp.jobTitle }}</span>
                                                    </span>
                                                </p>&ndash;&gt;
                                <div class="custom-profile-contacts">
                                  <div class="">
                                    <a class="text-decoration-none w3-hover-text-light-gray" :href="'tel:'+emp.mobileNumber">
                                      <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                                      <span class="">{{ emp.mobileNumber }}</span>
                                    </a>


                                  </div>

                                  <div class=" ">
                                    <a class="text-decoration-none w3-hover-text-light-gray" :href="'mailto:'+emp.email">
                                      <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                                      <span>{{ emp.email }}</span>
                                    </a>
                                  </div>
                                </div>
                                <div class="custom-row justify-content-center mt-2 " >
                                  <a :href="emp.facebookLink" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link" v-if="emp.facebookLink">
                                    <i class="fab fa-facebook-f"></i>
                                  </a>

                                  <a :href="emp.googlePlusLink" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link" emp.googlePlusLink>
                                    <i class="fab fa-google"></i>
                                  </a>
                                  <a :href="emp.linkedInLink" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link" emp.linkedInLink>
                                    <i class="fab fa-linkedin-in"></i>
                                  </a>
                                  <a :href="emp.twitterLink" class="custom-twitter-icon custom-icon-style  custom-social-link" emp.twitterLink>
                                    <i class="fab fa-twitter"></i>
                                  </a>

                                </div>
                              </div>



                            </div>-->
              <div class="pt-4 pb-4">
                <div>
                  <div class="custom-profile-image-wrap">
                    <div v-if="emp.employeeImage != null">
                      <img :src="url + emp.employeeImage" alt="" class="rounded-circle custom-profile-image" />
                    </div>
                    <div v-else>
                      <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image" />
                    </div>
                    <!--                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">-->
                  </div>
                </div>

                <div class="w3-center custom-profile-detail">
                  <h5 class="font-weight-bold text-capitalize" style="color:black;">
                    {{ emp.fullName }}
                  </h5>

                  <div class="text-capitalize">
                    {{ latestExperience.jobTitle }}
                  </div>

                  <div class="custom-profile-contacts" style="color:#696969" v-if="this.canViewContact">
                    <div class="">

                      <a class="text-decoration-none w3-hover-text-light-gray" :href="phoneNumberLink + mobileNumber">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                        <span class="">{{ mobileNumber }}</span>
                      </a>
                    </div>
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="emailLink + emp.Email">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span class="" style="overflow-wrap: break-word">{{
                          emp.email
                        }}</span>
                      </a>
                    </div>
                    <div class="custom-row justify-content-center mt-2">
                      <a :href="'https://' + emp.facebookLink" type="url" target="_blank" class="
                        custom-facebook-icon custom-icon-style
                        mr-2
                        custom-social-link
                      ">
                        <i class="fab fa-facebook-f"></i>
                      </a>

                      <a :href="'https://' + emp.googlePlusLink" type="url" target="_blank" class="
                        custom-googleplus-icon custom-icon-style
                        mr-2
                        custom-social-link
                      ">
                        <i class="fab fa-google"></i>
                      </a>
                      <a :href="'https://' + emp.linkedInLink" type="url" target="_blank" class="
                        custom-linkedin-icon custom-icon-style
                        mr-2
                        custom-social-link
                      ">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a :href="'https://' + emp.twitterLink" type="url" target="_blank" class="
                        custom-twitter-icon custom-icon-style custom-social-link
                      ">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </div>

                  </div>
                  <div v-else="this.canViewContact">
                    <div class="">

                      <a class="text-decoration-none w3-hover-text-light-gray">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                        <span class="">+923********</span>
                      </a>

                    </div>
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span class="" style="overflow-wrap: break-word">**********@gmial.com</span>
                      </a>
                    </div>
                    <button class="text-decoration-none
                          btn btn-outline-secondary
                          form-control
                          custom-input-gotopage
                        " type="button" @click="purchaseCrdeits">
                      <span class="is-small is-right">
                        <i class="fas fa-eye"></i>
                      </span>
                    </button>
                    <div class="custom-row justify-content-center mt-2">
                      <a :href="'#'" type="url" target="_blank" class="
  custom-facebook-icon custom-icon-style
  mr-2
  custom-social-link
">
                        <i class="fab fa-facebook-f"></i>
                      </a>

                      <a :href="'https://' + emp.googlePlusLink" type="url" target="_blank" class="
  custom-googleplus-icon custom-icon-style
  mr-2
  custom-social-link
">
                        <i class="fab fa-google"></i>
                      </a>
                      <a :href="'https://' + emp.linkedInLink" type="url" target="_blank" class="
  custom-linkedin-icon custom-icon-style
  mr-2
  custom-social-link
">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                      <a :href="'https://' + emp.twitterLink" type="url" target="_blank" class="
  custom-twitter-icon custom-icon-style custom-social-link
">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="col-lg-3 mr-5 m-status" style="margin-top: 60px;">
          <span class="font-weight-bold"><b>Postion: </b></span>

          <span>
            {{ jobdata.jobTitle }} <span class="sr-only">(current)</span>
          </span>
          <br>
          <span class="font-weight-bold">Applied Date: </span>

          <span>
            {{ emp.city }}
          </span>
          <br>
          <span class="font-weight-bold"><b>City:</b> </span>

          <span>
            {{ emp.city }}
          </span>
          <br>
          <span class="font-weight-bold"><b>Status:</b> </span>

          <button v-b-modal.modal-1 v-on:click="SetStatusModel()" class="btn btn-secondary dropdown-toggle"
            type="button" style="
background-color: #ff4285;
    border-color: #ff4285;
    width: 170px;
    font-weight: bolder;
    margin-top: 15px;
    margin-left: 9px;
              ">
            Set Up Interview
          </button>


        </div>
        <div class="col-lg-5">
          <div v-if="isDataLoaded" class="w3-card-2 pb-3 ml-auto mr-auto">
            <button v-b-modal.modal-2 class="btn btn-secondary" type="button" style="
                  background: rgb(255, 66, 133);
                  border-color: rgb(115, 21, 128);
                  width: 100%;
                  margin-bottom: 15px;
                ">
              Candidates Filter
            </button>

            <div style="max-height: 350px; overflow-y: auto; position: relative">
              <div v-for="appli in applicants.slice().reverse()" :key="appli.jobApplicants">
                <div class="w3-container custom-row">
                  <div>
                    <p class="
                          font-weight-bold
                          w3-text-pink
                          m-0
                          text-capitalize
                        ">
                      {{ appli.employeeName }}
                    </p>
                    <!--                  <span>{{appli.employeeName}}</span>-->
                    <p class="font-weight-bold text-black m-0">
                      <span v-if="appli.appliedDate === 0">Today</span>
                      <span v-else-if="appli.appliedDate === 1">{{ appli.appliedDate }} Day Ago</span>
                      <span v-else>{{ appli.appliedDate }} Days Ago</span>
                    </p>
                  </div>
                  <div class="ml-auto">
                    <a v-on:click="FetchEmployee(appli.employeeId)" href="javascript:void(0)"
                      class="btn btn-outline-secondary ml-auto">View</a>
                    <!-- <a
                        v-if="isApplicantViewd == true"
                        v-on:click="FetchEmployee(appli.employeeId)"
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary ml-auto"
                        >Viewed</a
                      >
                      <a
                      v-if="isApplicantViewd == false"
                        href="javascript:void(0)"
                        v-on:click="FetchEmployee(appli.employeeId)"
                        class="btn btn-outline-secondary ml-auto"
                        >View</a
                      > -->
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row align-items-center mt-5">


        <!-- <b-button v-b-modal.modal-3 style="background-color: rgb(255, 66, 133); border-color: rgb(255, 66, 133); width: 187px; font-weight: bolder;">Buy Subscription</b-button> -->

        <b-modal id="modal-3" title="Purchase Subscription" ref="subscriptionModal">
          <form class="custom-validation" @submit.prevent="purchaceResumeSubscription" method="post" novalidate="">
            <div class="form-group" style="padding: 0px 15px">
              <label>Credits</label>
              <select class="form-control" name="txtStatus" required="" v-model="numberOfResumeCreditSelected">
                <option value="0">Select resume</option>
                <!-- Dynamically create options based on the loop -->
                <option v-for="i in generateOptions()" :key="i" :value="i">{{ i }} resumes</option>
              </select>

              <br>
              <span> you will be charge {{ subscriptionCharges }}</span>
              <br>
              <div style="display: flex; justify-content: space-around;">
                <a @click.prevent="purchaceResumeSubscription()" target="_blank">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAABPlBMVEVHcExsCxKnFSHyFyuKNxW2GCHhyCyWEx0nBglZAAWKCxe7USRFAA2MURWEBxTtICqZGh52OBGJlyGIDxZsHBKRDRh6PBP9+DOHERV3KhCKFxcoCwr9+TT+6y98IBKTYhT//DS+xC7v7jLYySz0ICjaM0vW3TH99jO4lSno1DCdpCmSEBkZAAhhAAn4/DQfAATFliiPEhigGRyHChWnGR6RABibARqMCxabGRv9wCD/zCOUExn/5yylAR7/2ifv0S3+4Srq5TH/6y//0yWHIBSwGR+XDRmYVhf/9TF+ABX14i6YYBiSSRdgABBlNRTk3S7MzSubpySNLRegDxymaB+lMx6+lifhtSxrcxuutSf21SxiXBj+tR2iWRvLlyTmoyf5qxq6ZCPcbiHDbCA+EA27hSfcnln7lThLGg/JwShPgkytAAAAVXRSTlMAIhlNNT/8ShJlzg02/XpdYdr46Vyh8U39j9oJYOXt3jPEpupn/Z6jbZqtcL6943JW/////////v/////////////////////////////////////+Ki4D0AAAAetJREFUKJGtkudu20AQhKlK9WYbYVyQOL0nR94deey9SVTvklVcUt7/BUIpMRAnBoIA2b8fZhY7OxT1P6aUz+fuJ+mjA8C17ELlT54olO0WJ5VbPGg9/I0dMoDnPVdAFzxqesd3xHkeSBJ0WYF1WQkjjj/5ZV3T6wMgsA5koQBxazjOnj2+hfU+DwSHZSHrDDDSZFkn5MXtwgCwMYPOQFEuxlm/25tq4ps9Kp4+bSKJhRA621ikLvUI36idj1U6hg9M1bcHEGMFa35n1iNGMNL1xRG9g9SZqI4xXs1Nbfs1CjSrgbLWrF1PpXbnv7bEkJv4Rkf2R8qNakRbNby2658yMUwemkQeGosomukTpKtTRVt+m8zND6VnMcxnRTEbKFcTbZkdi90AhUtRl40GXatRVGqoE3Ok6esvm/mc9NZaDBbX7+hMMjZOvQ+JFYqXzy83lrjZiRZY6L/NpDNJis5UVhYxydVnZR124wDaEXabZTpVjF2TNbouE70jrixzigKsQCjZ/QpdPdkfmnilEr/dmzXaCss6AhR4qVClE1QxTuj0pUzELsJIGYwEwZWAZB+k6eo+2zg+SzXaHA+AO/Qkj2uiRzkqXfzxlCdG57wU14RjGI9hEODu1CSX+1kwxmaY43sK9pdq/vN8B9xwZmTmPoX3AAAAAElFTkSuQmCC" /></a>
                <a href="https://www.ubldigital.com/UBL-Digital/UBL-Netbanking/My-Payments" target="_blank">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAXVBMVEVHcEwqlM1nrdkAf8Mylc4Adb4AesIAfsMAhMYAdb5EntJEntMAdb4Ac73///8Ab7xXndHi8PgAabmbv9+xzuelyeWHt9wCfcLQ5fNyqta/2e1DlMwui8kWgMP0+/4x1OLcAAAADHRSTlMASN/tfBeA7oCLrKz7QEfxAAABQklEQVQ4jb2T2VLFIAyGz6oeJUDCTqHv/5iGblLPcuOM/3QgJR8hBDgc/kc38VC3xX197G66TsBzvxC7+KBYEGoAFsG2yuqlwTlvvB5YJcgKa4ipV8GiqWqKwR9AANED5KUnBSJAKKVU2vwLoIwcXFKCUIzW2mxrpB1AEsk2IAoctNYx3AEjA6Qa4L03mQHOplsiSZvQGCNp5NZlJbkzqktSS9QcPKi2glZQWwf9NilFNEUpIpq2CqXu6tAQpV12nIJPPHsQ8AtYag1znWBfh9enJeCFGhDO7091DgxY+UKWAbf+RIytnWzEecx1AKZsJY4Oi0Tp7B1gdXWm+JL4KFOwHXCaTZ+1MwGHhCUnsMu0EwPHPJmjNzFmP+TRRTbnwXxst/Yzbkk7jf0e4tf8MC5mN7wKzWV7Wpe3jzu9/bj/pm9Z6C4o3oPEYQAAAABJRU5ErkJggg==" /></a>
                <a href="https://www.paypal.com/pk/home" target="_blank">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAApVBMVEX////h5vC2wdm4w9rI0ePo7fUqRZAAB3oAEnxCWZv19/oAI4MALYUAL4YAJoMAAHnb4OwAK4Q4UJYAFHwAMIeSn8IAHoAAKXsBJHENLoFfcagBIGkAKXJvvegOOo0AGWQBVpcAl92j1fEBAFsDjtEAmd1Vs+UBEGAAn+EBB1wES40AnN4AHm0DOX0EfL2t2vIEYqUHVKHn9vwGbLPT6/h7irYnpOHqpTq5AAAA30lEQVR4Ab3QRQKDQBBE0YlbD3FX3F3uf7Q07svkL3lIMeTHDYZpo/GkjdPZPG02W4wbtlwBzWPWDd3Madl2V8fhviA4wLb+3eMJL58vaefrsoY3htLD5Z71eNbwtcUnc3t/WK6KM1pBXhCl6tg12jkz+aPUcDTHT2aoaoouPhtjD4aMvU1L0XXWbozdOpZlCaKCpivLCu62FEDBYmk8uDwAhVcCoiiyNSObE9Ctm6AkPTmPVBvhyTI+Yv3n87EAq2Rl0MbwtN2uBB3z2ricYApaRLrzWNwp9eETC8gf+gLSxB5s5mkQCwAAAABJRU5ErkJggg==" /></a>

              </div>

            </div>
          </form>
        </b-modal>
        <b-modal id="modal-4" title="Pay to view resume" ref="SingleResumeViewModal">
          <form class="custom-validation" @submit.prevent="BuySingleResumeView" method="post" novalidate="">
            <div class="form-group" style="padding: 0px 15px">

              <br>
              <span> you will be charge for 5 rupee</span>
              <br>
              <div style="display: flex; justify-content: space-around;">
                <a @click.prevent="BuySingleResumeView()" target="_blank">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAABPlBMVEVHcExsCxKnFSHyFyuKNxW2GCHhyCyWEx0nBglZAAWKCxe7USRFAA2MURWEBxTtICqZGh52OBGJlyGIDxZsHBKRDRh6PBP9+DOHERV3KhCKFxcoCwr9+TT+6y98IBKTYhT//DS+xC7v7jLYySz0ICjaM0vW3TH99jO4lSno1DCdpCmSEBkZAAhhAAn4/DQfAATFliiPEhigGRyHChWnGR6RABibARqMCxabGRv9wCD/zCOUExn/5yylAR7/2ifv0S3+4Srq5TH/6y//0yWHIBSwGR+XDRmYVhf/9TF+ABX14i6YYBiSSRdgABBlNRTk3S7MzSubpySNLRegDxymaB+lMx6+lifhtSxrcxuutSf21SxiXBj+tR2iWRvLlyTmoyf5qxq6ZCPcbiHDbCA+EA27hSfcnln7lThLGg/JwShPgkytAAAAVXRSTlMAIhlNNT/8ShJlzg02/XpdYdr46Vyh8U39j9oJYOXt3jPEpupn/Z6jbZqtcL6943JW/////////v/////////////////////////////////////+Ki4D0AAAAetJREFUKJGtkudu20AQhKlK9WYbYVyQOL0nR94deey9SVTvklVcUt7/BUIpMRAnBoIA2b8fZhY7OxT1P6aUz+fuJ+mjA8C17ELlT54olO0WJ5VbPGg9/I0dMoDnPVdAFzxqesd3xHkeSBJ0WYF1WQkjjj/5ZV3T6wMgsA5koQBxazjOnj2+hfU+DwSHZSHrDDDSZFkn5MXtwgCwMYPOQFEuxlm/25tq4ps9Kp4+bSKJhRA621ikLvUI36idj1U6hg9M1bcHEGMFa35n1iNGMNL1xRG9g9SZqI4xXs1Nbfs1CjSrgbLWrF1PpXbnv7bEkJv4Rkf2R8qNakRbNby2658yMUwemkQeGosomukTpKtTRVt+m8zND6VnMcxnRTEbKFcTbZkdi90AhUtRl40GXatRVGqoE3Ok6esvm/mc9NZaDBbX7+hMMjZOvQ+JFYqXzy83lrjZiRZY6L/NpDNJis5UVhYxydVnZR124wDaEXabZTpVjF2TNbouE70jrixzigKsQCjZ/QpdPdkfmnilEr/dmzXaCss6AhR4qVClE1QxTuj0pUzELsJIGYwEwZWAZB+k6eo+2zg+SzXaHA+AO/Qkj2uiRzkqXfzxlCdG57wU14RjGI9hEODu1CSX+1kwxmaY43sK9pdq/vN8B9xwZmTmPoX3AAAAAElFTkSuQmCC" /></a>
                <!-- <a href="https://www.ubldigital.com/UBL-Digital/UBL-Netbanking/My-Payments" target="_blank">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAXVBMVEVHcEwqlM1nrdkAf8Mylc4Adb4AesIAfsMAhMYAdb5EntJEntMAdb4Ac73///8Ab7xXndHi8PgAabmbv9+xzuelyeWHt9wCfcLQ5fNyqta/2e1DlMwui8kWgMP0+/4x1OLcAAAADHRSTlMASN/tfBeA7oCLrKz7QEfxAAABQklEQVQ4jb2T2VLFIAyGz6oeJUDCTqHv/5iGblLPcuOM/3QgJR8hBDgc/kc38VC3xX197G66TsBzvxC7+KBYEGoAFsG2yuqlwTlvvB5YJcgKa4ipV8GiqWqKwR9AANED5KUnBSJAKKVU2vwLoIwcXFKCUIzW2mxrpB1AEsk2IAoctNYx3AEjA6Qa4L03mQHOplsiSZvQGCNp5NZlJbkzqktSS9QcPKi2glZQWwf9NilFNEUpIpq2CqXu6tAQpV12nIJPPHsQ8AtYag1znWBfh9enJeCFGhDO7091DgxY+UKWAbf+RIytnWzEecx1AKZsJY4Oi0Tp7B1gdXWm+JL4KFOwHXCaTZ+1MwGHhCUnsMu0EwPHPJmjNzFmP+TRRTbnwXxst/Yzbkk7jf0e4tf8MC5mN7wKzWV7Wpe3jzu9/bj/pm9Z6C4o3oPEYQAAAABJRU5ErkJggg==" /></a>
                <a href="https://www.paypal.com/pk/home" target="_blank">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAApVBMVEX////h5vC2wdm4w9rI0ePo7fUqRZAAB3oAEnxCWZv19/oAI4MALYUAL4YAJoMAAHnb4OwAK4Q4UJYAFHwAMIeSn8IAHoAAKXsBJHENLoFfcagBIGkAKXJvvegOOo0AGWQBVpcAl92j1fEBAFsDjtEAmd1Vs+UBEGAAn+EBB1wES40AnN4AHm0DOX0EfL2t2vIEYqUHVKHn9vwGbLPT6/h7irYnpOHqpTq5AAAA30lEQVR4Ab3QRQKDQBBE0YlbD3FX3F3uf7Q07svkL3lIMeTHDYZpo/GkjdPZPG02W4wbtlwBzWPWDd3Madl2V8fhviA4wLb+3eMJL58vaefrsoY3htLD5Z71eNbwtcUnc3t/WK6KM1pBXhCl6tg12jkz+aPUcDTHT2aoaoouPhtjD4aMvU1L0XXWbozdOpZlCaKCpivLCu62FEDBYmk8uDwAhVcCoiiyNSObE9Ctm6AkPTmPVBvhyTI+Yv3n87EAq2Rl0MbwtN2uBB3z2ricYApaRLrzWNwp9eETC8gf+gLSxB5s5mkQCwAAAABJRU5ErkJggg==" /></a> -->

              </div>

            </div>
          </form>
        </b-modal>

        <div class="col-lg-3" style="">
          <span v-if="emp.resume != null">
            <button @click="downloadPDF" class="btn btn-secondary"
              style="background-color: rgb(255, 66, 133); border-color: rgb(255, 66, 133); width: 187px; font-weight: bolder; ">Download
              Resume
              <b-icon class="mr-2" icon="download"></b-icon></button>

          </span>
          <div v-else class="m-ResumeDownload">
            <span>No CV Attached by Employee For Download</span>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-2">

        <div class="col-lg-3" style="">
          <span v-if="emp.resume != null">
            <button @click="previewResume" class="btn btn-secondary"
              style="background-color: rgb(255, 66, 133); border-color: rgb(255, 66, 133); width: 187px; font-weight: bolder; ">preview
              Resume
              <b-icon class="mr-2 " icon="fas fa-eye"></b-icon></button>

          </span>
          <div v-else class="m-ResumeView">
            <span>No CV Attached by Employee</span>
          </div>
        </div>
      </div>
      <!-- <b-modal id="modal-5" title="Setup Interview" modal-footer="No">
        <div>
          <iframe id="pdfFrame" width="100%" height="600px" frameborder="0" title="PDF Viewer"></iframe>
        </div>
      </b-modal> -->
   


    <b-modal id="modal-5" title="Resume" modal-footer="No"
      :style="{ width: '57rem', marginLeft: 'auto', marginRight: 'auto' }">
      <iframe id="pdfFrame" width="100%" height="600px" frameborder="0" title="PDF Viewer"></iframe>
    </b-modal>
    <!-- soft Resume design start -->

    <div class="resume-wrapper">
      <section class="profile section-padding" >
        <div class="container" style="height: 450px;">
        
          <div class="name-wrapper">
            <h1>{{ emp.fullName }}</h1><!-- YOUR NAME AND LAST NAME  -->
          </div>
          <div class="clearfix"></div>
          <div class="contact-info clearfix">
            <ul class="list-titles">
              <li>Call</li>
              <li>Mail</li>
              <!-- <li>Web</li> -->
              <li>Address</li>
            </ul>
            <ul class="list-content " >
              <span v-if="this.canViewContact">
              <li>{{ mobileNumber }}</li> <!-- YOUR PHONE NUMBER  -->
              <li>{{ emp.email }}</li> <!-- YOUR EMAIL -->
            </span>
            <span v-else>
              <li class="m-wrap-Content">03*********</li> <!-- YOUR PHONE NUMBER  -->
              <li>*******@gmail.com</li> <!-- YOUR EMAIL -->

            </span>

              <!-- <li><a href="#">janderson.com</a></li>  -->
              <li>{{ emp.cityName }} {{ emp.country }}</li> <!-- YOUR STATE AND COUNTRY  -->
            </ul>
          </div>
          <div class="contact-presentation"> <!-- YOUR PRESENTATION RESUME  -->
            <p><span class="bold">Lorem</span> ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod congue
              nisi, nec consequat quam. In consectetur faucibus turpis eget laoreet. Sed nec imperdiet purus. </p>
          </div>
          <div class="contact-social clearfix">
            <ul class="list-titles">
              <li>LinkedIn</li>
              <li>Twitter</li>
              <li> Face Book</li>
              <li>Google Plus</li>
            </ul>
            <ul class="list-content"> <!-- REMEMBER TO PUT THE URL ON THE HREF TAG  -->
              <li><a :href="emp.linkedInLink" target="_blank" :style="{ color: 'var(--linkColor)' }">view LinkedIn</a>
              </li>

              <li><a :href="emp.twitterLink" target="_blank" :style="{ color: 'var(--linkColor)' }">view twitter</a>
              </li>
              <li><a :href="emp.facebookLink" target="_blank" :style="{ color: 'var(--linkColor)' }">view faceBook</a>
              </li>
              <li><a :href="emp.googlePlusLink" target="_blank" :style="{ color: 'var(--linkColor)' }">view googl
                  Plus</a></li> <!-- YOUR BEHANCE USER  -->

            </ul>
          </div>
        </div>
      </section>

      <section class="experience section-padding">
        <div class="container">
          <h3 class="experience-title">Experience</h3>
          <div class="experience-wrapper clearfix">
            <div v-for="experience in this.employeeExperience">
              <div class="company-wrapper clearfix">
                <div class="experience-title expCompText">{{ experience.company }}</div> <!-- NAME OF THE COMPANY YOUWORK WITH  -->
                <div class="time" v-if="experience.currentlyWorking">{{ experience.startDateMonthYear }}TO Present</div>
                <div class="time" v-else="">{{ experience.startDateMonthYear }} TO {{ experience.endDateMonthYear }}
                </div> <!-- THE TIME YOU WORK WITH THE COMPANY  -->
              </div>

              <div class="job-wrapper clearfix">
                <div class="experience-title expCompText">{{ experience.jobTitle }} </div> <!-- JOB TITLE  -->
                <div class="company-description">
                  <p>{{ experience.experienceDescription }}</p> <!-- JOB DESCRIPTION  -->
                </div>
              </div>
            </div>
          </div><!--Skill experience-->
          
          <!-- <div class="section-wrapper clearfix"> -->
            <h3 class="experience-title">Education</h3>
          <div class="experience-wrapper clearfix">
          
            <div v-for="education in this.employeeEducation">
              <div class="company-wrapper clearfix">
                <div class="experience-title expCompText" v-if="education.institute">{{ education.institute }}</div>
                <div v-else>No Univeristy</div>
                <div class="time" v-if="education.graduationCompletionYear">{{ education.graduationCompletionYear }}
                </div>

                <div class="time" v-else="education.expectedGraduationYear ">{{ education.expectedGraduationYear }}
                </div>
              </div>
              <div class="job-wrapper clearfix">
                <div class="experience-title expCompText">{{ education.degreeTitle }} </div> <!-- JOB TITLE  -->
                <div class="company-description">
                  <p>{{ education.educationDescription }}</p> <!-- JOB DESCRIPTION  -->
                </div>
              </div>
            </div>
          </div>
          

           <div class="section-wrapper clearfix">
      	<h3 class="section-title">Skills</h3>  <!-- YOUR SET OF SKILLS  -->
            <!-- Loop through employeeSkill and render each skill dynamically -->
            <div v-if="employeeSkill && employeeSkill.length > 0" v-for="(skill, index) in employeeSkill" :key="index">
              <ul>
                <li class="skill-percentage"
                  :style="{ '--skill-width': skill.skillLevelOutof100 + '%', animation: 'skill_' + (index + 1) + ' var(--time) ease' }">
                  {{ skill.skillsSubCategoryTitle }}
                </li>
              </ul>
            </div>

            <!-- Optional fallback if employeeSkill is empty -->
            <div v-else>
              <p>No skills available.</p>
            </div>
          </div>

          <div class="section-wrapper clearfix">
            <h3 class="section-title" >Projects</h3>  <!-- DESCRIPTION OF YOUR HOBBIES -->
            <!-- Loop through employeeSkill and render each skill dynamically -->
            <div v-if="employeeProjects && employeeProjects.length > 0" v-for="(project, index) in employeeProjects"
              :key="index">
              <div class="experience-title expCompText" v-if="project.projectTitle">{{ project.projectTitle }}</div>

              <div class="company-description">
                <p v-if="project.projectDescription">{{ project.projectDescription }}</p> <!-- JOB DESCRIPTION -->
              </div>
            </div>


            <!-- Optional fallback if employeeSkill is empty -->
            <div v-else>
              <p>No skills available.</p>
            </div>
          </div>

        </div>
     
      </section>

      <div class="clearfix"></div>
    </div>
  </div>



    <!--End soft Resume design start -->

    <!--SPACER-->
    <b-modal id="modal-1" title="Setup Interview" modal-footer="No">
      <form class="custom-validation" @submit.prevent="postCandidateStatus" method="post" novalidate="">
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters">
              <label class="col-sm-3 col-form-label col-form-label-sm">Status</label>
              <div class="col-sm-9">
                <select class="form-control" id="txtStatus" name="txtStatus" required="" v-model="bodyCandi.status"
                  @change="onChangeValue">
                  <option value="" disabled>Select Option</option>
                  <option value="Applied">Applied</option>
                  <option value="ShortListed">ShortListed</option>
                  <option value="Contacted">Contacted</option>

                  <option v-if="empdata != null && empdata.status != 'Hired'" value="Rejected">
                    Rejected
                  </option>
                  <option value="SetupInterView">Setup Interview</option>
                  <option value="Interviewed">Interviewed</option>
                  <option value="Hired">Hired</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="bodyCandi.status == 'SetupInterView'">
          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters">
              <label class="col-sm-3 col-form-label col-form-label-sm">Interview Date</label>
              <div class="col-sm-9">
                <input v-model="bodyCandi.setupInterViewDate" type="datetime-local" class="form-control" required=""
                  id="txtinterViewDate" name="txtinterViewDate" placeholder="Select Date and Time" />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters">
              <label class="col-sm-3 col-form-label col-form-label-sm">Interview Type</label>
              <div class="col-sm-9">
                <select class="form-control" name="txtStatus" required="" v-model="bodyCandi.typeInterview">
                  <option value="" disabled>Select Option</option>
                  <option value="1">In-person</option>
                  <option value="2">Phone</option>
                  <option value="3">Video</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters">
              <label class="col-sm-3 col-form-label col-form-label-sm">Interview Address</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" required="" name="txtinterViewDate"
                  placeholder="Interview Address" v-model="bodyCandi.interviewAddress" />
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters">
              <label class="col-sm-3 col-form-label col-form-label-sm">Message to Candidate</label>
              <div class="col-sm-9">
                <textarea class="form-control" required="" maxlength="2000" placeholder="message "
                  name="RolesandResponsibility" rows="3" v-model="bodyCandi.message">
                  </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters">
              <label class="col-sm-3 col-form-label col-form-label-sm">Remarks</label>
              <div class="col-sm-9">
                <input required="" v-model="bodyCandi.remarks" type="text" class="form-control" id="txtremarks"
                  name="txtremarks" placeholder="Remarks" />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="text-end mt-5">
            <button
              type="submit"
              class="btn btn-primary waves-effect waves-light me-1"
              :disabled="isLoading"
            >
              Submit</button
            ><button type="reset" class="btn btn-secondary waves-effect">
              Cancel
            </button>
          </div> -->
        <b-button type="submit" class="custom-form-btn custom-btn-gotopage" :disabled="isLoadingChangeButton"
          variant="outline-dark" data-dismiss="modal">Save
        </b-button>
      </form>
      <!-- <template #modal-footer="{   }"> -->

      <!-- Emulate built in modal footer ok and cancel button actions -->
      <!-- <b-button size="sm" variant="success" @click="ok()">
          Save
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button> -->
      <!-- Button with custom close trigger value -->
      <!-- <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Forget it
        </b-button> -->
      <!-- </template> -->
    </b-modal>

    <b-modal id="modal-2" title="Filter Candidates" modal-footer="No">
      <form class="custom-validation" @submit.prevent="filterCandidates" method="post" novalidate="">
        <div class="form-group" style="padding: 0px 15px">
          <label>Status</label>
          <select class="form-control" name="txtStatus" required="" v-model="bodyCandiFilter.applicantStatus">
            <option value="0">All</option>
            <option value="Applied">Applied</option>
            <option value="ShortListed">ShortListed</option>
            <option value="Contacted">Contacted</option>
            <option value="Rejected">Rejected</option>
            <option value="SetupInterView">Setup Interview</option>
            <option value="Interviewed">Interviewed</option>
            <option value="Hired">Hired</option>
          </select>
        </div>

        <div class="form-group" style="padding: 0px 15px">
          <label>City</label>
          <select class="form-control" v-model="bodyCandiFilter.cityId">
            <option value="0">Select City</option>
            <option v-for="option in cities" :key="option.key" :value="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>

        <div class="form-group" style="padding: 0px 15px">
          <label>Experience</label>
          <select class="form-control" v-model="bodyCandiFilter.yearOfExperience">
            <option value="0" selected="selected">
              Select Required job experience
            </option>
            <option v-for="option in yearOfExperiences" :key="option.key" :value="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>
        <div class="form-group" style="padding: 0px 15px">
          <label>Education</label>
          <select id="degreeLevel_id" class="custom-select-option form-control form-control-sm"
            v-model="bodyCandiFilter.educationLevel">
            <option value="0" selected="selected">Select Degree</option>
            <option v-for="option in degreeLevels" :key="option.key" :value="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>

        <div class="form-group" style="padding: 0px 15px">
          <label>Expected Salary</label>
          <input required="" v-model="bodyCandiFilter.expectedSalary" type="number" class="form-control"
            name="txtremarks" placeholder="expected salary" />
        </div>

        <div class="form-group" style="padding: 0px 15px">
          <label>Present Salary</label>
          <input required="" v-model="bodyCandiFilter.currentSalary" type="number" class="form-control"
            name="txtremarks" placeholder="Present salary" />
        </div>

        <div class="form-group" style="padding: 0px 15px">
          <label>Career Level</label>
          <select class="form-control" v-model="bodyCandiFilter.careerLevels">
            <option value="0" selected="selected">Select Career level</option>
            <option v-for="option in careerLevels" :key="option.key" :value="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>

        <div class="form-group" style="padding: 0px 15px">
          <label>Department</label>
          <select class="form-control" v-model="bodyCandiFilter.departments" id="">
            <option value="0" selected="selected">Select Department</option>
            <option v-for="option in departments" :key="option.key" :value="option.key">
              {{ option.value }}
            </option>
          </select>
        </div>

        <b-button type="submit" class="custom-form-btn custom-btn-gotopage mr-2" :disabled="isLoadingCandiButton"
          variant="outline-dark">Filter
        </b-button>
        <b-button type="reset" class="custom-form-btn custom-btn-gotopage" :disabled="isLoadingCandiButton"
          variant="outline-dark">Reset
        </b-button>
      </form>
      <!-- <template #modal-footer="{   }"> -->

      <!-- Emulate built in modal footer ok and cancel button actions -->
      <!-- <b-button size="sm" variant="success" @click="ok()">
          Save
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button> -->
      <!-- Button with custom close trigger value -->
      <!-- <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Forget it
        </b-button> -->
      <!-- </template> -->
    </b-modal>
    <div class="style-spacer"></div>
    <div class="row no-gutters">
      <div class="col-lg-2 w-100 h-100"></div>
      <div class="col-lg-8"></div>
      <div class="col-lg-2"></div>
    </div>
  </div>


</template>
<script>
// import pdf from 'pdfvuer';
//pdfvuer is installed by using the cmd npm install pdfvuer@next --save
import PDFObject from 'pdfobject';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";
import _ from "underscore";
import { BModal, BIcon } from 'bootstrap-vue';
export default {
  name: "CandidatesApplied",
  components: {
    BModal,
    BIcon

  },
  data() {
    return {
      resumeItemInterval: 5,
      canViewContact: true,
      numberOfResumeCreditSelected: 0,
      url: axios.defaults.baseURL,
      InsertResumeViewSubscriptionModal: {
        employeeId: 0,
        creditsPurchase: 0,
        merchantName: "jazz Cash",
        onSucesslog: "Paid 100%"
      },
      EnableResumeViewModal: {
        companyEmployeeId: 0,
        jobId: 0,
        employeeId: 0,
        useSubscription: false
      },

      yearList: [],
      countryCodes: [
        { value: 1, text: "+92" },
        { value: 2, text: "+93" },
        { value: 3, text: "+94" },
      ],
      calculatedAge: 0,
      networkCodes: [
        { value: 1, text: "300" },
        { value: 2, text: "301" },
        { value: 3, text: "302" },
        { value: 4, text: "303" },
        { value: 5, text: "304" },
        { value: 6, text: "305" },
        { value: 7, text: "306" },
        { value: 8, text: "307" },
        { value: 9, text: "308" },
        { value: 10, text: "309" },
        { value: 11, text: "311" },
        { value: 12, text: "312" },
        { value: 13, text: "313" },
        { value: 14, text: "314" },
        { value: 15, text: "315" },
        { value: 16, text: "320" },
        { value: 17, text: "321" },
        { value: 18, text: "322" },
        { value: 19, text: "323" },
        { value: 20, text: "324" },
        { value: 21, text: "325" },
        { value: 22, text: "330" },
        { value: 23, text: "331" },
        { value: 24, text: "332" },
        { value: 25, text: "333" },
        { value: 26, text: "334" },
        { value: 27, text: "335" },
        { value: 28, text: "336" },
        { value: 29, text: "337" },

        { value: 30, text: "340" },
        { value: 31, text: "341" },
        { value: 32, text: "342" },
        { value: 33, text: "343" },
        { value: 34, text: "344" },
        { value: 35, text: "345" },
        { value: 36, text: "346" },
        { value: 37, text: "347" },

      ],
      employeeStatuses: [
        { value: 1, text: "Full Time" },
        { value: 2, text: "Part Time" },
        { value: 3, text: "Freelancing or On/Off projects" },
        { value: 4, text: "Not Currently Working" },
      ],
      selectedEmployementWorkHours: "0",
      employementWorkHours: [
        { value: 0, text: "Select Work Hours" },
        { value: 1, text: "1-20 hours per week" },
        { value: 2, text: "21-30 hours per week" },
        { value: 3, text: "31-40 hours per week" },
        { value: 4, text: "40 hours per week" },
      ],

      selectedEmployementWorkWhere: "0",
      employementWorkWhere: [
        { value: 0, text: "Select Work Place" },
        { value: 1, text: "At the office" },
        { value: 2, text: "Remote" },
        { value: 3, text: "Combination" },
        { value: 4, text: "Unsure right now" },
      ],
      months: [
        { value: 0, text: "Select Month" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 11, text: "11" },
        { value: 12, text: "12" }

      ],
      selectedMiles: "0",
      miles: [
        { value: 1, text: "0-5" },
        { value: 2, text: "5-10" },
        { value: 3, text: "10-20" },
        { value: 4, text: "20-30" },
        { value: 5, text: "30-40" },
        { value: 6, text: "40-50" },
        { value: 7, text: "50-60" },
        { value: 8, text: "60-70" },
        { value: 9, text: "70-80" },
        { value: 10, text: "80-90" },
        { value: 11, text: "90-100" },
        { value: 12, text: "More than 100" }
      ],
      selectedTravelPercent: "0",
      travels: [
        { value: 1, text: "not at all" },
        { value: 2, text: "Up to 10%" },
        { value: 3, text: "Up to 20%" },
        { value: 4, text: "Up to 30%" },
        { value: 5, text: "Up to 40%" },
        { value: 6, text: "Up to 50%" },
        { value: 7, text: "Up to 60%" },
        { value: 8, text: "Up to 70%" },
        { value: 9, text: "Up to 80%" },
        { value: 10, text: "Up to 90%" },
        { value: 11, text: "Up to 100%" }

      ],
      salaries: [
        { text: 'Choose One...', value: -1 },
        { text: '0-5000', value: 1 },
        { text: '5000-15000', value: 2 },
        { text: '15000-25000', value: 3 },
        { text: '25000-35000', value: 4 },
        { text: '35000-50000', value: 5 },
        { text: '50000-70000', value: 6 },
        { text: '70000-100000', value: 7 },
        { text: '100000-125000', value: 8 },
        { text: '125000-150000', value: 9 },
        { text: '150000-175000', value: 10 },
        { text: '175000-200000', value: 11 },
        { text: '200000-250000', value: 12 },
        { text: '300000-400000', value: 13 },
        { text: '400000-500000', value: 14 },
        { text: '500000-600000', value: 15 },
        { text: '600000-700000', value: 16 },
        { text: '700000-800000', value: 17 },
        { text: '800000-900000', value: 18 },
        { text: '900000-1000000', value: 19 },
        { text: '1000000+', value: 20 },
      ],
      isDataLoaded: false,
      isLoading: false,
      isMessageDismissed: false,
      isLoadingEmp: false,
      isEmpLoaded: false,
      isLoadingChangeButton: false,
      isLoadingCandiButton: false,
      jobId: 0,
      fullName: "",
      mobileNumber: null,
      code: 0,
      networkCode: 0,
      email: "",
      facebookLink: "",
      linkedInLink: "",
      twitterLink: "",
      googlePlusLink: "",
      cnic: "",
      currentSalary: 0,
      domicile: "",
      yearOfExperienceId: 0,
      expectedSalary: 0,
      maritalStatus: 0,
      cityId: 0,
      professionalSummary: "",
      phoneNumberLink: "tel:",
      linkMobileNo: "",
      emailLink: "mailto:",
      selectedCategory: "-1",



      //Dropdown Lists
      // cities:[],
      departments: [],
      yearOfExperiences: [],
      selectedCareerLevel: null,
      careerLevels: [],
      selectedDegreeLevel: null,
      degreeLevels: [],


      employeelist: null,
      employeeSkill: null,
      employeeExperience: null,
      employeeEducation: null,

      latestExperience: {},
      latestDegreeLevel: {},




      employeeProjects: [],
      maritalStatusList: [
        { value: 1, text: "Single" },
        { value: 2, text: "Married" },
        { value: 3, text: "Other" },
      ],
      body: {
        jobId: 0
      },

      bodyEmp: {
        jobId: 0,
        employeeId: 0
      },

      bodyCandi: {
        jobId: 0,
        employeeId: 0,
        remarks: null,
        interViewDate: null,
        //setup interview
        setupInterViewDate: null,
        typeInterview: 0,
        interviewAddress: "",
        message: "",
        status: 0,
        JobApplicantsId: 0

      },


      bodyCandiFilter: {

        jobId: 0,
        cityId: 0,
        yearOfExperience: 0,
        experienceLevels: 0,
        currentSalary: 0,
        expectedSalary: 0,
        departments: 0,
        careerLevels: 0,
        educationLevel: 0,
        gender: 0,
        industry: 0,
        applicantStatus: 0

      },





      errored: false,
      // url: "Job",
      jobdata: null,
      applicants: [],
      isApplicantViewd: null,

      //Applied Date
      appliedDate: null,
      emp: null,
      empdata: null,
    };
  },
  computed: {
    cities() {
      return this.$store.getters.availableCities
    },
    subscriptionCharges() {
      return 5 * this.numberOfResumeCreditSelected;
    }

  },
  methods: {
    generateOptions() {
      let options = [];
      for (let i = 1; i <= 5; i++) {
        const resumeItems = (i * this.resumeItemInterval) * 10;
        options.push(resumeItems); // Add the resumeItems to the options array
      }
      return options;
    },
    BuySingleResumeView() {
      this.EnableResumeViewModal.jobId = Number(this.jobId);
      this.EnableResumeViewModal.employeeId = Number(this.emp.employeeId);
      this.EnableResumeViewModal.companyEmployeeId = Number(this.$auth.user().employeeId);
      this.EnableResumeViewModal.useSubscription = true;
      axios.post("/api/ResumeViewSubscription/EnableResumeView", this.EnableResumeViewModal).then((response) => {

        response.data.data[0];
        console.log(response.data.data[0]);
        if (response.data.data == true) {
          this.enableContactInfoView = true;
          alert("The contact view is Enable");
        }
        console.log("check the true or false", response.data);
        alert(response.data.msg);
      })
      console.log(this.InsertResumeViewSubscriptionModal)
    },
    purchaceResumeSubscription() {

      this.InsertResumeViewSubscriptionModal.creditsPurchase = Number(this.numberOfResumeCreditSelected);
      this.InsertResumeViewSubscriptionModal.employeeId = Number(this.$auth.user().employeeId);

      axios.post("/api/ResumeViewSubscription/InsertResumeViewSubscription", this.InsertResumeViewSubscriptionModal).then((response) => {

        response.data.data[0];
        console.log(response.data.data[0]);
        console.log(response.data);
        alert(response.data.msg);
      })
      console.log(this.InsertResumeViewSubscriptionModal)
    },

    //this is for the [preview the  resume]
    previewResume() {
      if (this.canViewContact) {

        // let iframe = document.getElementById("pdfFrame");
        // if (iframe) {
        //   // Assuming you want to load a PDF or trigger an action on the iframe
        //   iframe.src = axios.defaults.baseURL + (this.emp.resume.startsWith('wwwroot') ? this.emp.resume.slice(7) : this.emp.resume);
        this.$bvModal.show('modal-5');
        this.$nextTick(() => {
          let iframe = document.getElementById("pdfFrame");
          if (iframe) {
            // Assuming you want to load a PDF or trigger an action on the iframe
            iframe.src = axios.defaults.baseURL + (this.emp.resume.startsWith('wwwroot') ? this.emp.resume.slice(7) : this.emp.resume);
          }
        });
      }else {
        this.purchaseCrdeits();
      }
      } ,
    
    downloadPDF() {

 if (this.canViewContact) {
  const formData = new FormData();
 
  
  console.log("resume URl",this.emp.resume);
  let fileName = this.emp.resume.split('/').pop().split('_')[1];

  formData.append("FileName", this.emp.resume);


  axios.post("/api/EmployerCompany/DownloadPDF", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  .then(response =>{
    console.log(response);
    // Base64 string from the server
    const base64String = response.data.pdfData;
    // console.log(base64String)
    // Remove all whitespaces
    const cleanedBase64String = base64String.replace(/\s/g, '');

    // Decode the Base64 string into binary data
    // Decode Base64 string into binary
    const binaryData = atob(cleanedBase64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uintArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
        uintArray[i] = binaryData.charCodeAt(i);
    }
    // Create a Blob from the binary data and display/download the PDF
    const pdfBlob = new Blob([uintArray], { type: 'application/pdf' });
    const link = document.createElement("a");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    link.href = pdfUrl;
    link.download = fileName;  // Set the file name for the downloaded PDF
    link.click();  // Trigger the download

    // Cleanup the object URL
    URL.revokeObjectURL(pdfUrl);
})
.catch(error => {
    console.error("Error downloading the PDF:", error);
});}
 else {
 this.purchaseCrdeits();
}
 },

    // downloadPDF() {

    //   if (this.canViewContact) {
    //     const formData = new FormData();
    //     formData.append("FileName", this.emp.resume);


    //     axios.post("/api/EmployerCompany/DownloadPDF", formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })

    //       .then((response) => {
    //         const blob = new Blob([response.data], { type: "application/pdf" }); // Create a Blob from the response
    //         const link = document.createElement("a");
    //         link.href = URL.createObjectURL(blob);

    //         this.removeSlash = this.employeelist.resume.split('\\').pop();
    //         this.fullFileName = this.removeSlash.split('\\').pop(); // Extract file name from the path
    //         const fileNameParts = this.fullFileName.split('_'); // Split the file name by "_"

    //         // Check if there is a second part after the underscore
    //         this.Name = fileNameParts.length > 1 ? fileNameParts[1] : fullFileName;
    //         link.download = this.Name;
    //         link.click(); // Trigger the download
    //         URL.revokeObjectURL(link.href); // Cleanup the object URL
    //       })
    //       .catch((error) => {
    //         console.error("Error downloading the PDF:", error);
    //       });
    //   } else {
    //     this.purchaseCrdeits();
    //   }
    // },
    purchaseCrdeits() {
      this.$swal.fire({
        title: "Info",
        text: 'You have to pay 5 Rupee per resume to see. Go to pay now first.',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Subscription Plan",
        denyButtonText: `<span class="mr-2"><i class="fas fa-dollar-sign"></i></span> Pay Now`, // Add icon using HTML
        customClass: {
          confirmButton: 'custom-close-button', // Custom class for Subscription button
          denyButton: 'custom-close-button',         // Custom class for Pay Now button
          cancelButton: 'custom-close-button'          // Custom class for Close button
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$refs.subscriptionModal.show();
        } else if (result.isDenied) {
          alert("deny");
          this.$refs.SingleResumeViewModal.show();
        }
      });
    },
    SetStatusModel() {
      console.log("this.empdata : ", this.empdata);
      this.bodyCandi.employeeId = this.emp.employeeId;
      this.bodyCandi.remarks = '';
      this.bodyCandi.typeInterview = this.empdata.interViewType
      this.bodyCandi.message = this.empdata.message
      this.bodyCandi.setupInterViewDate = this.empdata.setupInterViewDate
      this.bodyCandi.interviewAddress = this.empdata.interviewAddress
      this.bodyCandi.status = this.empdata.status
      this.bodyCandi.jobId = Number(this.jobId)
      this.bodyCandi.JobApplicantsId = Number(this.empdata.jobApplicantId)
      console.log(this.bodyCandi)

    },
    getJobInfo() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.isDataLoaded = false;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.jobId = Number(this.jobId)

      console.log(this.body)

      axios
        .post("/api/Job/GetJobApplicantByJobId", this.body)
        .then((res) => {

          console.log("Add Response portion");

          console.log("response : ", res);
          this.jobdata = res.data.data;
          this.applicants = this.jobdata.jobApplicants

          if (this.applicants.length == 0) {
            this.showAlert();
          }
          else {
            this.hideAlert();

            //Difference in number of days
            this.applicants.forEach(item => {

              var given = moment(item.appliedDate, "YYYY-MM-DD");
              var current = moment().startOf('day');
              this.appliedDate = moment.duration(current.diff(given)).asDays();
              item.appliedDate = this.appliedDate;
            })
            // for (let date in this.applicants){
            //   var given = moment(this.applicants[date].appliedDate, "YYYY-MM-DD");
            //   var current = moment().startOf('day');
            //   this.appliedDate = moment.duration(current.diff(given)).asDays();
            //   if(this.applicants.c == date)
            //   item.appliedDate = this.appliedDate;
            //   console.log("appliedDate : ", this.appliedDate);
            // }
          }








          console.log("saiftest:", this.applicants)
          this.isDataLoaded = true;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false
          if (this.errored) {

            console.log("Error : Sorry there is no response");

          } else {
            /*this.$bvToast.toast("Data Fetched! ", {
              toaster: "b-toaster-top-center",
              variant: "success",
              title: 'Job',
              solid: true
            });*/
            console.log("Data Fetched! ");

          }
        });
    },
    FetchEmployee(empdets) {


      if (this.isLoadingEmp) {
        return;
      }
      this.isLoadingEmp = true;
      this.isEmpLoaded = false;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.bodyEmp.jobId = Number(this.jobId)
      this.bodyEmp.employeeId = Number(empdets)


      console.log(this.bodyEmp)

      axios
        .post("/api/Job/GetAllApplicantView", this.bodyEmp)
        .then((res) => {

          console.log("Add Response portion");

          console.log("response : ", res);
          this.empdata = res.data.data[0]
          console.log("empdata", this.empdata)
          this.emp = res.data.data[0].employees
          console.log("ahmed test", this.emp)
          console.log('status ', this.empdata.status)
          this.canViewContact = this.empdata.enableContactInfoView;
          console.log("can view the resume:  ;", this.empdata.enableContactInfoView);

          this.isEmpLoaded = true
          console.log("empdata", this.empdata);
          this.isApplicantViewd = this.empdata.isApplicantViewed;
          this.employeeProjects = this.empdata.project;
          console.log("employee project", this.employeeProjects);

          this.employeeSkill = this.empdata.eMPSkills;
          console.log("Thissafasfad employeeSkill", this.employeeSkill);

          console.log("isApplicantViewd", this.isApplicantViewd);
          this.calculatedAge = this.caculateAge(this.emp.dateofBirth);
          for (let option in this.countryCodes) {
            if (this.emp.code === this.countryCodes[option].value) {
              this.code = this.countryCodes[option].text;
            }
          }
          console.log("country Code : ", this.code);
          //this.code = this.employeelist.code;
          for (let option in this.networkCodes) {
            if (this.emp.networkCode === this.networkCodes[option].value) {
              this.networkCode = this.networkCodes[option].text;
            }
          }
          console.log("network Code : ", this.networkCode);
          //this.networkCode = this.employeelist.networkCode;

          this.mobileNumber = this.emp.mobileNumber;
          this.linkMobileNo = this.code + this.networkCode + this.mobileNumber;



          this.employeeExperience = _.sortBy(this.empdata.eMPExperiences, 'startDateMonthYear').reverse();

          if (this.employeeExperience.length != 0) {
            console.log("employeeExperience : ", this.employeeExperience);
            this.employeeExperienceId = this.employeeExperience.empExperienceId;
            this.latestExperience = _.first(this.employeeExperience);
            console.log("this.latestExperience", this.latestExperience);
          }


          this.employeeEducation = _.sortBy(this.empdata.empEducations, 'degreeLevelId').reverse();

          if (this.employeeEducation.length != 0) {
            console.log("employeeEducation : ", this.employeeEducation);

            this.latestDegreeLevel = _.first(this.employeeEducation);
            console.log("this.latestDegreeLevel", this.latestDegreeLevel);
          }


        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingEmp = false
          if (this.errored) {

            console.log("Error : Sorry there is no response");

          } else {
            /*this.$bvToast.toast("Data Fetched! ", {
              toaster: "b-toaster-top-center",
              variant: "success",
              title: 'Job',
              solid: true
            });*/

            console.log("Data Fetched! ");
          }
        });

    },

    filterCandidates() {


      if (this.isLoadingCandiButton) {
        return;
      }
      this.isLoadingCandiButton = true;
      var app = this;
      this.bodyCandiFilter.jobId = Number(this.jobId)
      this.bodyCandiFilter.cityId = Number(this.bodyCandiFilter.cityId)
      this.bodyCandiFilter.yearOfExperience = Number(this.bodyCandiFilter.yearOfExperience)
      this.bodyCandiFilter.experienceLevels = Number(this.bodyCandiFilter.experienceLevels)
      this.bodyCandiFilter.currentSalary = Number(this.bodyCandiFilter.currentSalary)
      this.bodyCandiFilter.expectedSalary = Number(this.bodyCandiFilter.expectedSalary)
      this.bodyCandiFilter.departments = Number(this.bodyCandiFilter.departments)
      this.bodyCandiFilter.careerLevels = Number(this.bodyCandiFilter.careerLevels)
      this.bodyCandiFilter.educationLevel = Number(this.bodyCandiFilter.educationLevel)
      this.bodyCandiFilter.applicantStatus = this.bodyCandiFilter.applicantStatus == '0' ? Number(0) : this.bodyCandiFilter.applicantStatus

      console.log(this.bodyCandiFilter)
      console.log(Date.now())

      axios
        .post("/api/Employee/SearchCandidates", this.bodyCandiFilter)
        .then((res) => {

          console.log("Add bodyCandiFilter portion");
          console.log(res.data.data)
          this.applicants = res.data.data

        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingCandiButton = false;
          this.$bvToast.toast("Filter Applied! ", {
            toaster: "b-toaster-top-center",
            variant: "success",
            title: 'Candidates',
            solid: true
          });

          this.$bvModal.hide("modal-2")


        });

    },
    postCandidateStatus() {


      if (this.isLoadingChangeButton) {
        return;
      }
      this.isLoadingChangeButton = true;

      this.bodyCandi.jobId = Number(this.jobId)
      this.bodyCandi.employeeId = Number(this.emp.employeeId)
      this.bodyCandi.status = this.bodyCandi.status
      this.bodyCandi.typeInterview = Number(this.bodyCandi.typeInterview)
      console.log("post Candidate Status: ", this.bodyCandi);
      console.log(Date.now())

      axios
        .post("/api/Job/JobPost", this.bodyCandi)
        .then((res) => {

          console.log("Add bodyCandi portion");
          var today = new Date();
          var datenow = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
          console.log("response : ", res);
          this.empdata.status = this.bodyCandi.status
          switch (this.bodyCandi.status) {
            case 'ShortListed':
              this.empdata.shortListedDate = datenow

              this.empdata.shortListedRemarks = this.bodyCandi.remarks
              break;
            case "Rejected":
              this.empdata.rejectedDate = datenow

              this.empdata.rejectedRemarks = this.bodyCandi.remarks
              break;

            case "Hired":
              this.empdata.hiredDate = datenow

              this.empdata.hiredRemarks = this.bodyCandi.remarks
              break;

            case "SetupInterView":
              this.empdata.setupInterViewDate = this.bodyCandi.setupInterViewDate
              this.empdata.typeInterview = this.bodyCandi.typeInterview;
              this.empdata.interviewAddress = this.bodyCandi.interviewAddress;
              this.empdata.message = this.bodyCandi.message;

              this.empdata.setupInterViewRemarks = this.bodyCandi.remarks

              break;

            case "Interviewed":
              this.empdata.interviewRemarks = this.bodyCandi.remarks
              break;

            case "Contacted":
              this.empdata.contactedDate = datenow
              this.empdata.contactedRemarks = this.bodyCandi.remarks
              break;
            default:
            // code block
          }

        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingChangeButton = false;
          if (this.errored) {

            console.log("Error : Sorry there is no response");

          } else {
            this.$bvToast.toast("Status Changed! ", {
              toaster: "b-toaster-top-center",
              variant: "success",
              title: 'Job Applicant',
              solid: true
            });
            this.$bvModal.hide("modal-1")

          }
        });

    },
    getAll() {
      this.employeelist = null;
      axios
        .get("/api/Employee/GetEmployeeProfile")
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
          this.employeelist = res.data.employee;
          console.log("thisiemployeelist : ", this.employeelist);
          this.fullName = this.employeelist.fullName;
          this.mobileNumber = this.employeelist.mobileNumber;
          this.email = this.employeelist.email;
          this.facebookLink = this.employeelist.facebookLink;
          this.linkedInLink = this.employeelist.linkedInLink;
          this.twitterLink = this.employeelist.twitterLink;
          this.googlePlusLink = this.employeelist.googlePlusLink;
          this.cnic = this.employeelist.cnic;
          this.currentSalary = this.employeelist.currentSalary;
          this.domicile = this.employeelist.domicile;
          this.maritalStatus = this.employeelist.maritalStatus;
          this.yearOfExperienceId = this.employeelist.yearOfExperienceId;
          this.expectedSalary = this.employeelist.expectedSalary;
          this.cityId = this.employeelist.cityId;
          this.professionalSummary = this.employeelist.professionalSummary;
          //    this.employeeSkill = res.data.eMPSkills;
          // console.log("TestingemployeeSkill : ", this.employeeSkill);
          this.employeeExperience = res.data.eMPExperiences;
          console.log("employeeExperience : ", this.employeeExperience);
          this.employeeEducation = res.data.empEducations;
          console.log("employeeEducation : ", this.employeeEducation);
          // });
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    caculateAge(dob) {
      //var diff_ms = Date.now() - dob.getTime();
      //var age_dt = new Date(diff_ms);

      //return Math.abs(age_dt.getUTCFullYear() - 1970);

      var formatedDate = moment(String(dob)).format('YYYY/MM/DD');
      var today = new Date();
      var birthDate = new Date(formatedDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    getCityList() {
      this.$store.dispatch("getCityList");
      /*this.cities = null;
      let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });*/
    },
    getDepartmentList() {
      this.departments = [];
      let model = {
        type: "Department"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.departments = res.data.data;

          console.log("Add Response portion");

          console.log("departments : ", this.departments);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },
    getYearOfExperience() {
      this.yearOfExperiences = [];
      let ddBody = {
        type: "YearOfExperience"
      }
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }

          console.log("yearOfExperiences new: ", res.data.data[0].key)
          this.yearOfExperiences = res.data.data
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
          }
        });


    },

    getDegreeLevelList() {
      this.degreeLevels = null;
      let model = {
        type: "DegreeLevel"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.degreeLevels = res.data.data;

          console.log("Add Response portion");

          console.log("degreeLevels : ", this.degreeLevels);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },

    getCareerLevelList() {
      this.careerLevels = [];
      let model = {
        type: "CareeerLevel"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.careerLevels = res.data.data;

          console.log("Add Response portion");

          console.log("careerLevels : ", this.careerLevels);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },





    onChangeValue() {
      this.bodyCandi.remarks = null;
    },

    scrollTo(id) {
      //var id=event.target.attr("data-id");
      console.log(id);
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    },
    showAlert() {
      this.isMessageDismissed = true
    },
    hideAlert() {
      this.isMessageDismissed = false
    },
  },
  mounted() {
    this.jobId = this.$route.params.id;

    this.getJobInfo();
    console.log("jobId : ", this.jobId)
    console.log("empId : ", this.$route.params.empId)
    if (this.$route.params.empId != 0) {
      this.FetchEmployee(this.$route.params.empId);
    }

    this.getAll();
    this.getCityList();
    this.getDepartmentList();
    this.getYearOfExperience();
    this.getDegreeLevelList();
    this.getCareerLevelList();


  },
};
</script>
<style>
.event-list {
  border-left: 3px dashed #f6f6f6;
  position: relative;
  padding: 0 0 30px 30px;
}

.event-list::after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ea216c;
  background-color: #fff;
}

.resume-icon {
  font-size: 52px;
  color: #ea216c;
}

.custom-edit-button {
  font-size: 30px;
}

#modal-1 .modal-footer,
#modal-2 .modal-footer,
#modal-3 .modal-footer {
  display: none !important;
}

.custom-card {
  border: 1px solid #ff4285;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-card h3 {
  color: #ff4285;
}


.custom-close-button:hover {
  background-color: #ff4285 !important;
  /* Darker pink on hover */
}

.swal2-styled.swal2-confirm {
  background-color: #ff4285 !important;
}

.swal2-styled.swal2-deny {
  background-color: #ff4285 !important;
}

.swal2-styled.swal2-cancel {
  background-color: #ff4285 !important;
}

#modal-5 .modal-dialog .modal-content {
  width: 57rem !important;
}

/* Resume design CSS start */
/* Resume Design CSS end  */
</style>
<style>
@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700');

:root {
  --profileBg: #fff;
  --skillsBg: #3d3e42;
  --profileColor: #9099a0;
  --skillsColor: #9099a0;
  --linkColor: #66cc99;
  --boldColor: #4a4e51;
  --time: 0.6s;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

/* body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5em;
} */

/* a {
  color: var(--linkColor);
  text-decoration: none;
} */

.clearfix::after,
.clearfix::before {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.bold {
  color: var(--boldColor);
  font-weight: 400;
}

.resume-wrapper {
  position: relative;
  text-align: center;
  height: 100%;
      width: 58vw;
    margin: auto;
    margin-top: 4.2rem;
    display:flex;
}



.profile {
  background: #ffeff4;
  width: 40%;
  float: left;
  /* color: white; */
}

.profile .name-wrapper {
  float: left;
  width: 60%;
}

.profile h1 {
  font-size: 2.5em;
  text-align: left;
  font-family: 'Varela Round', sans-serif;
  /* color:white; */
  text-transform: uppercase;
  line-height: 1em;
  padding-top: 40px;
}

.profile li {
  margin-bottom: 10px;
  /* color:white !important; */
}

.profile .picture-resume-wrapper {
  width: 40%;
  display: block;
  float: left;
}

.profile .picture-resume {
  width: 220px;
  height: 220px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 0px;
  display: table;
  position: relative;
  vertical-align: middle;
}

.profile .picture-resume img {
  border-radius: 50%;
  width: 130px;
}

.profile .contact-info {
  margin-top: 100px;
  font-weight: 300;
}

.profile .list-titles {
  float: left;
  text-align: left;
  font-weight: 600;
  width: 40%;
  color: var(--boldColor);
}

.profile .list-content {
  float: left;
  width: 60%;
  text-align: left;
  font-weight: 300;
}

.profile .contact-presentation {
  text-align: left;
  font-weight: 300;
  margin-top: 100px;
  margin-bottom: 100px;
}

.experience {
  background: var(--skillsBg);
  width: 60%;
  float: left;
  position: relative;
  color: var(--skillsColor);
  font-weight: 300;
  min-height: 100%;
  min-height: 100vh;
}

.experience h3.experience-title {
  color: var(--linkColor);
  text-align: left;
  text-transform: uppercase;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 400;
}

.experience .company-wrapper {
  width: 30%;
  float: left;
  text-align: left;
  padding-right: 5%;
  margin-bottom: 60px;
}

.experience .job-wrapper {
  width: 70%;
  float: left;
  text-align: left;
  padding-right: 5%;
  margin-bottom: 60px;
}

.section-padding {
  padding: 60px 60px 40px 40px;
}

.section-wrapper {
  width: 50%;
  /* float: left; */
  text-align: left;
  color: var(--skillsColor);
  font-weight: 300;
  margin-bottom: 20px;
}

.section-wrapper h3.section-title {
  color: var(--linkColor);
  text-align: left;
  text-transform: uppercase;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 400;
}

.skill-percentage {
  margin-bottom: 10px;
  position: relative;
}

.skill-percentage::after {
  content: "";
  width: 100%;
  height: 6px;
  background: var(--boldColor);
  display: block;
  margin-top: 3px;
}

.skill-percentage::before {
  content: "";
  height: 6px;
  background: var(--linkColor);
  position: absolute;
  margin-top: 3px;
  bottom: 0;
}

.skill-percentage:nth-child(1)::before {
  width: 80%;
  animation: skill_1 var(--time) ease;
}

.skill-percentage:nth-child(2)::before {
  width: 90%;
  animation: skill_2 var(--time) ease;
}

.skill-percentage:nth-child(3)::before {
 
  animation: skill_3 var(--time) ease;
}

.skill-percentage:nth-child(4)::before {
  
  animation: skill_4 var(--time) ease;
}

.skill-percentage:nth-child(5)::before {
  
  animation: skill_5 var(--time) ease;
}

.skill-percentage:nth-child(6)::before {
  
  animation: skill_6 var(--time) ease;
}

.skill-percentage:nth-child(7)::before {
 
  animation: skill_7 var(--time) ease;
}
.expCompText{
  color: white;
  font-size: 1.2rem;
}

@keyframes skill_1 {
  from {
    width: 0%;
  }

  to {
    width: 80%;
  }
}

@keyframes skill_2 {
  from {
    width: 0%;
  }

  to {
    width: 90%;
  }
}

@keyframes skill_3 {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@keyframes skill_4 {
  from {
    width: 0%;
  }

  to {
    width: 60%;
  }
}

@keyframes skill_5 {
  from {
    width: 0%;
  }

  to {
    width: 70%;
  }
}

@keyframes skill_6 {
  from {
    width: 0%;
  }

  to {
    width: 70%;
  }
}

@keyframes skill_7 {
  from {
    width: 0%;
  }

  to {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .resume-wrapper {
    margin: 2px !important;
    width:94vw;
    font-size: 0.75rem;
  }
  .profile h1{
    font-size:1rem;
  }
  .section-padding {
    padding: 0rem !important;
  }
  .profile .contact-info {
    margin-top: 3rem !important;
    font-size: 0.75rem;
  }
  .profile .contact-presentation{
    margin-top: 3rem !important;
    font-size: 0.75rem;
  }
  .profile .list-titles {
    padding: 0rem 0rem 0rem 1rem !important;
  }
  .experience .company-wrapper {
    width: 40% !important;
  }
    .experience .job-wrapper {
      width: 60% !important;
    }
    .section-title{
      font-size: 1rem !important;
    }
    .section-wrapper {
      width: 100% !important;
    }
    .section-wrapper ul {
      padding: 0rem 0rem 0rem 0.75rem;

    }
    .mr-5, .mx-5 {
      margin-right: 0rem !important;
    }
        .container {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }
        .m-status{
          margin: 30px 10px !important;
        }
        .m-ResumeView{
          margin-bottom: 1.5rem;
        }
        .m-ResumeDownload{
          margin-top: 1.5rem;
        }
        .experience{
          min-height: auto;
        }
        .m-wrap-Content{
          display:flex;
          flex-wrap: wrap;
        }
  
}
@media (min-width: 768px) and  (max-width: 820px) {
  .resume-wrapper {
    margin: 2px !important;
    width:85vw !important;
    font-size: 0.75rem;
  }
  .mr-5, .mx-5 {
    margin-right: 0rem !important;
}
.m-status{
  margin-top: 60px;
  margin-bottom: 60px;
text-align: -webkit-center;
}
.section-padding {
    padding: 30px 20px 20px 15px;
}
dl, ol, ul {
  padding: 10px 0px 0px 10px;
}

}
</style>