<template>
  <div class="mt-5 mb-5 ml-auto mr-auto" :key="componentKey">
    <div class="container">
      <div v-if="showCompleteProfileAlert">
        <div class="alert alert-warning" role="alert">
          Please complete your profile first. Click Here to <router-link to="/employer-profile"
            class="custom-text-color text-decoration-none"><i>Update Your Profile.</i></router-link>
        </div>
      </div>
      <div class="style-spacer"></div>
      <!-- <CvSearch /> -->
      <!-- import search cv from employee canidte componentKey -->
      <div class="style-spacer"></div>
      <div v-if="showDashboard">
        <div class="row no-gutters w-100 mt-2">
          <div class="col-md-2" style="margin-left: 20px; margin-right: 20px">

            <div class="">


              <div class="card p-0 mb-20 w3-center custom-profile-card h-100"
                style="background-color: white; color:black;">
                <div class="card-body pt-4 pb-4">

                  <div>

                    <div class="custom-profile-image-wrap custom-company-image-wrap">
                      <div v-if="body.companyLogoAddress !== null">
                        <img :src="url + body.companyLogoAddress" alt="" class="rounded-circle custom-profile-image">
                      </div>
                      <div v-else>
                        <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                      </div>
                    </div>
                  </div>
                  <div class=" w3-center custom-profile-detail">
                    <h5 class="font-weight-bold text-capitalize">
                      {{ body.companyName }}
                    </h5>
                  </div>
                </div>
                <div class="card-footer border-0 pl-0 pr-0">
                  <div class="custom-profile-contacts custom-profile-detail">
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray"
                        :href="phoneNumberLink + body.contactNumber">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
                        <span class="">{{ body.contactNumber }}</span>
                      </a>
                    </div>
                    <div class=" ">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="emailLink + body.companyEmail">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span>{{ body.companyEmail }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="custom-row justify-content-center mt-2 ">
                    <a :href="'https://' + body.facebookLink" target="_blank"
                      class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a :href="'https://' + body.googlePlusLink" target="_blank"
                      class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-google"></i>
                    </a>
                    <a :href="'https://' + body.linkedInLink" target="_blank"
                      class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a :href="'https://' + body.twitterLink" target="_blank"
                      class="custom-twitter-icon custom-icon-style custom-social-link">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7" style="margin-top: 50px; margin-right: 20px"">
            <div class="" style=" margin-bottom: 20px;">
            <label for="Search" style="font-weight: bolder;font-size: larger;">CV SEARCH</label>
            <div class="input-group custom-search-input">
              <div class="input-group-prepend">
                <span class="input-group-text custom-input-group-icon" id="basic-addon1"><b-icon
                    icon="Search"></b-icon></span>
              </div>
              <input type="text" class="form-control custom-filter-input form-control-sm" placeholder="Search"
                aria-label="Search" aria-describedby="basic-addon1" v-model="currentSalary" />
              <button @click="navigateWithQuery" style="background-color: rgb(236, 34, 31);
    border: 1px rgb(241, 158, 220);
    border-radius: 5%;
    color: white;
    height: 33px;
    width: 101px;
    margin-left: 10px;">SEARCH</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="card h-100">
                <div class="card-body pb-0">


                  <h4 class="mb-1 mt-1">
                    <span data-plugin="counterup" v-if="this.dashboardData.jobStats.totalJobs">{{
                      dashboardData.jobStats.totalJobs }}</span>
                    <span data-plugin="counterup" v-else>0</span>

                  </h4>


                </div>

                <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">

                  Total Jobs

                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="card h-100">
                <div class="card-body pb-0">


                  <h4 class="mb-1 mt-1">
                    <span data-plugin="counterup">{{ dashboardData.jobStats.cVsRecieved }}</span>
                  </h4>




                </div>
                <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                  Received CVs
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="card h-100">
                <div class="card-body pb-0">


                  <h4 class="mb-1 mt-1">
                    <span data-plugin="counterup">{{ dashboardData.jobStats.shortlistedCandidates }}</span>
                  </h4>

                </div>
                <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                  Candidates shortlisted
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="card h-100">
                <div class="card-body pb-0">


                  <h4 class="mb-1 mt-1">
                    <span data-plugin="counterup">{{ dashboardData.jobStats.upcomingInterviews }}</span>
                  </h4>

                </div>
                <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                  Upcoming interviews
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style=" display: flex;
    justify-content: center;
    margin-top: 31px;
    margin-bottom: 20px;
">
              <button style="background-color: rgb(236, 34, 31);
    border: 1px rgb(241, 158, 220);
    border-radius: 5%;
    color: white;
    height: 40px;
    width: 130px;
    margin-left: 10px; ">
                <router-link style="color: white;
    font-weight: bolder;" to="/employer-postjob/0">
                  Post A Job
                </router-link>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-2" style="margin-left: 20px;">
          <div class="">
            <div class="card p-0 mb-20 w3-center h-100 ">
              <div class="card-body pt-4 pb-4">
                <div>
                  <div class="custom-profile-image-wrap">
                    <div v-if="disEmployeeLogo !== null">
                      <img :src="url + disEmployeeLogo" alt="" class="rounded-circle custom-profile-image">
                    </div>
                    <div v-else>
                      <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                    </div>
                  </div>
                </div>
                <div class=" w3-center text-dark">
                  <h5 class="font-weight-bold text-capitalize">
                    {{ body.fullName }}
                  </h5>
                  <div class=" ">
                    <span>{{ body.designation }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%;">
          <div style="display: grid;justify-content: center;padding: 9%;">
            <span style="font-size: x-large; font-weight: bolder;">Recent Applicants</span>
            <div style="display: flex;
    justify-content: center; margin-top: 10px;">
              <router-link to="/employer-candidates/0">
                <button style="background-color: rgb(236, 34, 31);
      border: 1px rgb(241, 158, 220);
      border-radius: 73px;
      color: white;
      height: 45px;
      width: 135px;
      margin-left: 10px;">See More ⮷</button>
              </router-link>
            </div>
          </div>
          <div style="float:left; width: 63%;">
            <div class="col-md-12 mb-2" v-for="appli in arrays" :key="appli.jobApplicantsId">
              <div class="card p-3 recent-applications" style="background-color: #ffe6e6; border-radius: 10px;">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="mb-0">{{appli.empName}}</h5>
                    <p class="mb-0">{{appli.jobTitle}}</p>
                  </div>
                  <div>
                    <span class="badge badge-primary">{{ appli.applicantStatus }}</span>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2">
                  <div>
                    <span>{{ appli.appliedDate | formatDate }}</span>
                  </div>
                  <router-link :to="{ name: 'CandidatesApplied', params: { id: appli.jobId, empId: appli.empId }}"
                    class="btn btn-primary">View Profile</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-4"></div>
        <div class="m-4" style="width: 75rem;">
          <div class="pt-3">
          </div>
          <!--SPACER-->
          <div class="style-spacer"></div>
          <!--SPACER-->

          <div class="style-spacer"></div>

          <div>
            <div class="row no-gutters">

              <!-- <div class="col-xl-2"></div> -->
              
              <div class="col-lg-6">
                <div class="w3-card-2 h-100 ">
                  <div class="card-body">
                    <div style="">
                    <div class="col-sm-6">Start Date
                          <input
                            type="date"
                            class="form-control"
                            autocomplete="off"
                            required=""
                            v-model="chartStartDate"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.establishedIn.$error,
                            }"
                          />
                        </div>
                    
                    <div class="col-sm-6">End Date
                          <input
                            type="date"
                            class="form-control"
                            autocomplete="off"
                            required = ""
                            v-model="chartEndDate"
                            :class="{
                              'is-invalid':
                                submitted && $v.body.establishedIn.$error,
                            }"
                          />
                        </div>
                      </div>
                    <h4 class="card-title mb-4 text-center">
                      CVs recieved on my jobs
                    </h4>
                 
                    <apexchart width="100%" type="bar" :options="lineChartOptions" :series="lineChartSeries">
                    </apexchart>
                    

                  </div>
                </div>
                <!--end card-->
              </div>
              <div class="col-xl-6">
                <apexchart type="donut" :options="chartOptions" :series="series" width="100%"></apexchart>
              </div>
              <div class="col-xl-2"></div>
            </div>
            <!-- import search cv from cv search componentKey -->
            <div class="style-spacer"></div>
            <!-- <CvSearch /> -->
            <!-- import search cv from employee canidte componentKey -->
            <div class="style-spacer"></div>
            <!-- <EmpCandidates /> -->
            <div class="style-spacer"></div>
            <!-- <div class="row">
                    <div class="col-lg-4 mt-3">
                      <div style="width: 100%;max-height: 800px;overflow: scroll;" class="w3-card-2 w-150 h-100">
                        <div class="card-body">
                          <h4 class="card-title mb-5 text-center">
                            Employees by cities
                          </h4>
                          <table class="table">
                            <thead>
                              <tr>
                                <td>Name</td>
                                <td>Count</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(job, index) in dashboardData.employeesByCity" :key="index">
                                <td><b> {{ job.name }} </b>
                                </td>
                                <td> {{ job.count }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 mt-3">
                      <div style="width: 100%;max-height: 800px;overflow: scroll;" class="w3-card-2 w-150 h-100">
                        <div class="card-body">
                          <h4 class="card-title mb-5 text-center">Universities</h4>
                          <table class="table">
                            <thead>
                              <tr>
                                <td>Name</td>
                                <td>Count</td>


                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(job, index) in dashboardData.employeesByUniversity" :key="index">

                                <td><b> {{ job.name }} </b>
                                </td>
                                <td> {{ job.count }}

                                </td>

                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 mt-3">
                      <div style="width: 100%;max-height: 800px;overflow: scroll;" class="w3-card-2 w-150 h-100">
                        <div class="card-body">
                          <h4 class="card-title mb-5 text-center">
                            Applications by experience
                          </h4>
                          <table class="table">
                            <thead>
                              <tr>
                                <td>Name</td>
                                <td>Count</td>


                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(job, index) in dashboardData.employeesByExperiance" :key="index">

                                <td><b> {{ job.name }} </b>
                                </td>
                                <td> {{ job.count }}

                                </td>

                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> -->
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #ff4285;
    color: white;">
      <label style="margin-left: 20px;
    padding: 10px;
    font-size: larger;
    font-weight: bolder;
    text-decoration: underline;">My Job</label>
    </div>
    <div>
      <div class="row mt-30">
        <div class="col-md-6" v-for="(job, index) in arrays1.slice().reverse()" :key="job.jobId">
          <div class="custom-job-height w3-card-2 p-3">
            <div class="row">
              <div class="col-lg-12">
                <h4 class="
                            custom-job-title custom-text-color
                            text-capitalize
                          ">
                  {{ job.jobTitle }}
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>
                  <b>{{ job.city }}</b> - <b>{{ job.countryName }}</b>
                </p>
                <span class="offersalary">
                  Offer Salary:
                  <span v-if="job.packageTo === 0 || job.packageTo === 1">
                    Market Competitive Salary
                  </span>
                  <span v-else>
                    Rs. {{ job.packageFrom }} - {{ job.packageTo }}
                  </span>
                </span>
              </div>

              <div class="col-md-6">
                <p class="job-posted">
                  Job Posted: {{ job.insertionDate | moment1 }}
                </p>
              </div>
              <div class="col-md-6">
                <p class="job-posted">
                  Job Expiry: {{ job.jobExpiryDate | moment1 }}
                </p>
              </div>

              <div class="col-md-7">
                <router-link :to="{
                            name: 'CandidatesApplied',
                            params: { id: job.jobId, empId: job.applicantEmployeeId },
                          }" type="button" class="btn btn-primary">View Applicants</router-link>
                <br />
                <router-link class="jobbutton" :to="{ name: 'JobDetail', params: { id: job.jobId } }"><span><i
                      class="fas fa-file"></i></span><span> View</span></router-link>
                <router-link :to="{
                            name: 'EmployerPostJob',
                            params: { id: job.jobId },
                          }"><span><i class="fas fa-pencil-square-o"></i></span><span> Edit</span></router-link>
                <!--                        <a class="jobbutton" href=""  ><span><i class="fas  fa-files-o"></i></span><span  > Copy</span></a>-->
                <br />

                <a class="jobbutton" href=""><span><i class="fas fa-power-off"></i></span><span> Deactivate</span></a>
              </div>

              <div class="col-md-5">
                <button type="button" class="btn btn-primary">
                  Instant Match
                </button>
                <br />
                <a class="jobbutton" href=""><span><i class="fas fa-link"></i></span><span> Attach Course </span></a>
                <a :id="'popover-target-' + index" class="jobbutton" href="javascript:void(0)"><span><i
                      class="fas fa-share-alt"></i></span><span> Share</span></a>
                <b-popover :target="'popover-target-' + index" triggers="hover" placement="bottom">
                  <div class="custom-row justify-content-center mt-2">
                    <a :href="
                                whatsAppUrl +
                                encodeURIComponent(
                                  domainUrl + '/#/' + job.jobId
                                )
                              " type="url" target="_blank" class="
                                custom-whatsapp-icon custom-icon-style
                                mr-2
                                custom-social-link
                              ">
                      <i class="fab fa-whatsapp"></i>
                    </a>
                    <!--                      <a href="#" type="url" target="_blank" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                                                    <i class="fab fa-facebook-f"></i>
                                                  </a>-->
                    <ShareNetwork class="
                                custom-facebook-icon custom-icon-style
                                mr-2
                                custom-social-link
                              " network="facebook" :url="domainUrl + '/#' + job.jobId" :title="job.jobTitle"
                      :description="job.jobDescription">
                      <i class="fab fa-facebook-f"></i>
                    </ShareNetwork>

                    <ShareNetwork class="
                                custom-linkedin-icon
                                custom-icon-style
                                custom-social-link
                              " network="linkedin" :url="domainUrl + '/#' + job.jobId" :title="job.jobTitle"
                      :description="job.jobDescription">
                      <i class="fab fa-linkedin-in"></i>
                    </ShareNetwork>
                  </div>
                </b-popover>
              </div>
            </div>
          </div>
          <hr />

          <hr />
        </div>
        </hr>

      </div>
      <div style="display: flex;
    justify-content: center; margin-top: 10px;">
        <router-link to="/employer-jobs">
          <button style="background-color: rgb(236, 34, 31);
      border: 1px rgb(241, 158, 220);
      border-radius: 73px;
      color: white;
      height: 45px;
      width: 135px;
      margin-left: 10px;">See More ⮷</button>
        </router-link>
      </div>
    </div>
  </div>
  <div class="row no-gutters w-100 mt-4">
    <div class="col-md-2">

    </div>
    <div class="col-md-8">

    </div>
    <div class="col-md-2">

    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade custom-modal" id="showDetailModal" tabindex="-1" role="dialog"
    aria-labelledby="showDetailModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showDetailModalTitle">{{ modalTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="isTutorialGuideVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
          <div v-if="isMumsBacktoWorkVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
          <div v-if="isAbledCandidatesVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
          <div v-if="isFreshGraduateVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
          <div v-if="isRemoteVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
          <div v-if="isViiLearnVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
          <div v-if="isShehriDirectoryVisible">
            <p style="text-align: justify;">{{ modalcontent }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>
<script>
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { ArrowLeftCircleIcon } from "vue-feather-icons";
import { ArrowRightCircleIcon } from "vue-feather-icons";
import { VueperSlide, VueperSlides } from "vueperslides";
import CvSearch from '../employer/CvSearch.vue'
import EmpCandidates from '../employer/Candidates.vue'
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  name: "EmployerDashboard",
  components: {
    VueSlickCarousel,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
    CvSearch,
    EmpCandidates
  },
  data() {
     // Get the current date
  const currentDate = new Date();
  
  // Set the start date to one month ago
  const startDate = new Date(currentDate);
  startDate.setMonth(currentDate.getMonth() - 3); // Subtract one month

  // Format the dates to 'YYYY-MM-DD'
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Set the formatted start and end dates
  this.chartStartDate=formatDate(startDate);
  this.chartEndDate=formatDate(currentDate);
  
    return {
      chartStartDate:formatDate(startDate), // One month prior to the current date
      chartEndDate: formatDate(currentDate), // Current date
      whatsAppUrl:"",
      url: axios.defaults.baseURL,
      modalTitle: "",
      modalcontent: "",
      currentSalary:null,
      isTutorialGuideVisible: false,
      isMumsBacktoWorkVisible: false,
      isAbledCandidatesVisible: false,
      isFreshGraduateVisible: false,
      isRemoteVisible: false,
      isViiLearnVisible: false,
      isShehriDirectoryVisible: false,
      showDashboard: false,
      showCompleteProfileAlert: false,
      arrays: [],
      jobTitleCounts: [],
      jobTitlename: [],
      arrays1: [],
      series: [], // Dummy data for the three fields
    chartOptions: {
      chart: {
        type: 'donut',
      },
      labels: ['Female', 'Male','Transgender'], // Labels for the fields
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    lineChartOptions: {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
    theme: {
      palette: 'palette1' // upto palette10
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "45%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#5b73e8", "#f1b44c"],
  grid: {
    borderColor: "#f1f1f1",
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (e) {
        return Math.round(e);  // Round to the nearest integer and return
      },
    },
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    min: 6,  // Start y-axis from 1
  
  },
},

lineChartSeries: [
  {
    name: "CVs Received",
    data: [],
  },
],


      phoneNumberLink: "tel:",
      emailLink: "mailto:",
      employerList: null,
      disCompanyLogo: "",
      dashboardData: [],
      disEmployeeLogo: null,
      packages: [],
      isPackageDataFetched: false,
      body: {
        employerCompanyId: 0,
        companyEmail: "",
        companyName: "",
        companyLogoAddress: null,
        ceoName: "",
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: "",
        countryId: 106,
        cityId: 0,
        address: "",
        establishedIn: "",
        websiteUrl: "",
        contactNumber: "",
        googleMapIframe: "",
        linkedInLink: "",
        facebookLink: "",
        googlePlusLink: "",
        twitterLink: "",
        employeeImage: "",
        fullName: "",
        email: "",
        mobileNumber: null,
        designation: "",
      },
     
      bodyJobFilter: {
        ApplicantStatus: 0
      },
      bodyJobFilter1: {
        ExpiredJobsOnly:0,
        jobStatus:0
      },
      allJobsList: null,
      componentKey: 0,
    };
  },
  computed: {
    employerDashboardLeftSide() {
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardLeftSide)
      return this.$store.getters.availableEmployerDashboardLeftSide
    },
    employerDashboardRightSide() {
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardRightSide)
      return this.$store.getters.availableEmployerDashboardRightSide
    },
    employerDashboardCenter() {
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardCenter)
      return this.$store.getters.availableEmployerDashboardCenter
    }
  },
  watch:{
    chartStartDate(newDate) {
      if (newDate && this.chartEndDate) {
        this.getGraphData();
      }
    },
    chartEndDate(newDate){
      if (newDate && this.chartStartDate) {
        this.getGraphData();
    }
  }
},
  methods: {
    getJobInfo(js) {
  this.arrays1 = [];
  this.isLoading = true;
  if (Number(js) === 99) {
    this.bodyJobFilter1.ExpiredJobsOnly = 1;
    this.bodyJobFilter1.jobStatus = 0;
  } else {
    this.bodyJobFilter1.ExpiredJobsOnly = 0;
    this.bodyJobFilter1.jobStatus = Number(js);
  }
  axios
    .post("/api/EmployerCompany/GetFilteredJobs", this.bodyJobFilter1)
    .then((res) => {
      if (res.status !== 200) {
        console.log("Error: Failed to fetch data.");
        throw new Error("Failed to fetch data");
      }

      let resdata = res.data.data;
      let top4Records = resdata.slice(0, 4);

      this.arrays1 = top4Records;
      console.log("arrays1",this.arrays1);
        this.isLoading = false;
        this.isDataFetched = true;
        this.arrays1.length === 0 ? this.showAlert() : this.hideAlert();
      // if (resdata.length !== 0) {
      
      //   this.jobData = resdata[0];
      //   this.setCompanyId = this.jobData.companyId;

      //   // Check if this.breadcrumbItems is defined and has at least 3 elements
      //   if (!this.breadcrumbItems || this.breadcrumbItems.length < 3) {
      //     this.breadcrumbItems = [{}, {}, {}];
      //   }
      //   this.breadcrumbItems[2].text = this.jobData.jobTitle;

      //   let currentDate = moment().format('YYYY-MM-DD');
      //   if (Number(js) !== 99) {
      //     resdata.forEach(data => {
      //       if (moment(data.jobExpiryDate).format('YYYY-MM-DD') >= currentDate) {
      //         this.arrays1.push(data);
      //       }
      //     });
      //   } else {
      //     this.arrays1 = resdata;
      //   }
      //   console.log("resdata",this.resdata);

      // } else {
      //   console.log("No data available.");
      // }
    })
    .catch((error) => {
      console.log("Error: ", error);
    })
    .finally(() => {
      // Cleanup or final actions
    });
},
    getProfileInfo(js) {
      this.arrays = []

        this.bodyJobFilter.ApplicantStatus = Number(js)

      axios
        .post("/api/EmployerCompany/GetFilteredCandidates", this.bodyJobFilter)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }
          let top3Records = res.data.data.slice(0, 3);

if (res.data != 0) {
  //this.employerObj = resdata;
  this.arrays = top3Records;
} else {
}
          let resdata = res.data.data;
          debugger;
            
    // const jobTitleCounts = {};
    // top10Records.forEach(item => {
    //   const title = item.jobTitle;
    //   if (title) {
    //     jobTitleCounts[title] = (jobTitleCounts[title] || 0) + 1;
    //   }
    // });

    const sortedJobTitles = Object.entries(jobTitleCounts)
      .sort((a, b) => b[1] - a[1]) // Sort by count in descending order
      .slice(0, 10); // Take top 10

      this.jobTitlename = sortedJobTitles.map(([title]) => title);
      this.jobTitleCounts = sortedJobTitles.map(([, count]) => count);

      console.log("jobTitlename", thissortedJobTitles);
      console.log("jobTitleCounts", this.jobTitleCounts);
    this.lineChartSeries = [
      {
        name: "Job Title Counts", // Series name
        data: this.jobTitleCounts // Data for the line
      }
    ];

    this.lineChartOptions = {
      chart: {
        type: 'line'
      },
      xaxis: {
        categories: this.jobTitlename // Categories for the x-axis
      },
      title: {
        text: "Job Title Counts"
      }
    };

          this.isDataFetched = true;

          // });
        })
        
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    navigateWithQuery() {
      this.$router.push({
        name: 'CvSearch',
        query: { currentSalary: this.currentSalary }
      });
    },
    getAdds() {
      this.$store.dispatch("fetchAdds");
    },
    getAll() {
      this.rerender();
      this.employerList = null;
      axios.get('/api/EmployerCompany/GetEmployerCompany')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }
          let respData = res.data.data;
          console.log("res.data : ", respData);
          if (respData != 0) {
            this.employerList = respData;
            this.body.employerCompanyId = this.employerList.employerCompanyId
            this.body.companyEmail = this.employerList.companyEmail
            this.body.companyName = this.employerList.companyName
            this.body.ceoName = this.employerList.ceoName
            this.body.address = this.employerList.address
            this.body.contactNumber = this.employerList.contactNumber
            this.body.linkedInLink = this.employerList.linkedInLink;
            this.body.facebookLink = this.employerList.facebookLink;
            this.body.twitterLink = this.employerList.twitterLink;
            this.body.googlePlusLink = this.employerList.googlePlusLink;
            this.body.companyLogoAddress = this.employerList.companyLogoAddress;
            this.body.fullName = this.employerList.employee.fullName
            this.body.designation = this.employerList.employee.designation;
            this.body.mobileNumber = this.employerList.employee.mobileNumber;
            this.body.email = this.employerList.employee.email;
            this.disEmployeeLogo = this.employerList.employee.employeeImage;
            this.allJobsList = this.employerList.jobs;
            console.log("allJobsList : ", this.allJobsList);
            this.showDashboard = true;
          }
          else {
            this.showCompleteProfileAlert = true;
            this.showDashboard = false;
            console.log("No Data Found..")
          }
          // });
        }).catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    getGraphData() {
      this.rerender();
      const formData = new FormData();
      formData.append("startDate",this.chartStartDate);
      formData.append("endDate",this.chartEndDate);

     if (!this.chartStartDate || !this.chartEndDate) {
    alert("Please select both start and end dates.");
    return; // Don't send request if dates are not valid
  }
      axios.post('/api/EmployerCompany/DashboardGraphs',formData)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }
          let respData = res.data.data;
          this.dashboardData = respData;
          this.dashboardData.cVsRecievedOnJobs = this.dashboardData.cVsRecievedOnJobs.filter(item => item.count !== 0);
          // this.lineChartSeries = 
          // [
          //   {
          //     name: this.jobTitlename,
          //     data: this.jobTitleCounts,
          //   },
          // ];
          // this.lineChartOptions = {
          //   xaxis: {
          //     categories: this.jobTitlename
          //   }
          // }
          console.log("res.data graph: ", respData);
          this.series = [];
          respData.genderBaseApplicants.forEach(element => {
            if (element.maleApplicantCount != 0) {
              this.series.push(element.maleApplicantCount);
            } else {
              this.series.push(0);
            }
            if (element.femaleApplicantCount != 0) {
              this.series.push(element.femaleApplicantCount);
            } else {
              this.series.push(0);

            }
            if (element.transGenderApplicantCount != 0) {
              this.series.push(element.transGenderApplicantCount);

            } else {
              this.series.push(0);
            }
          });
          console.log("this.lineChartSeries.data: ", this.lineChartSeries);
          this.lineChartOptions.xaxis.categories = [];
          this.lineChartSeries[0].data = [];
          let x = 1;
          respData.jobApplicationsAgainstJobs.forEach(element => {
            this.lineChartOptions.xaxis.categories.push(x);
            x++;
            this.lineChartSeries[0].data.push(element.jobApplicantCount);
//            if (this.lineChartOptions.xaxis.categories.length > 20) {
//   this.lineChartOptions = {
//     ...this.lineChartOptions,
//     chart: {
//       zoom: {
//         enabled: true,
//         type: 'x',  // Enable zoom for the x-axis
//         autoScaleYaxis: true, // Auto-scale y-axis based on zoom
//       }
//     },
//     xaxis: {
//       type: 'category',  // Use categories for x-axis
//       labels: {
//         rotate: -45,  // Optional: Rotate labels for better visibility
//       },
//       tickAmount: 20,  // Maximum number of ticks visible at once
//       range: 20,  // Visible range
//       min: 1,  // Ensure the x-axis doesn't go below 1
//       max: this.lineChartOptions.xaxis.categories.length,  // Adjust max based on data
//       scrollbar: {
//         enabled: true, // Enable scrollbar
//         height: 5,  // Set the height of the scrollbar
//         borderRadius: 3,  // Border radius for scrollbar
//         opacity: 0.7,  // Set opacity for the scrollbar
//         background: '#e0e0e0', // Set the background color of the scrollbar
//         borderColor: '#ccc', // Set the border color of the scrollbar
//         handleColor: '#2196F3', // Handle color (thumb of scrollbar)
//         handleWidth: 8, // Width of the scrollbar handle
//       }
//     }
//   };
// }
            this.rerender();
          });
          // });
        }).catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    getPackagesInfo() {
      axios.get('/api/EmployerJobPostPackage/GetAllJobPostPackagesByEmployeeId')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }
          this.packages = res.data.data;
          console.log("PACKAGES : ", this.packages.length);
          this.isPackageDataFetched = true
        }).catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    //Modals
    tutorialGuideModal() {
      this.modalTitle = "Tutorial Guide";
      this.modalcontent = "Tutorial Guide"
      this.isTutorialGuideVisible = true;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    mumsBacktoWorkModal() {
      this.modalTitle = "Mums Back to Work";
      this.modalcontent = "The world comprises males and females which means both genders contribute their fair share of responsibilities. There is a huge percentage of females that are career-driven and goal-oriented in their life. Most of the time these females study their whole life and gain experience with a well-defined skill set that helps any company to bloom. However, marriage and becoming a mother is an essential part of life therefore they might get a break. A female went through a lot of experiences as a mother. However most of the companies don’t consider hiring them considering it a female drawback. This is just a mere mindset that needs to be changed and altered. Being a mother should not be considered as a drawback rather it is an advantage to the company. According to the Hive report of Hive, women tend to work harder than men. They were more creative and were able to drive better ideas. A mother back to work especially is more determined than anyone. Mothers are divine humans who give time and effort in raising children. Therefore, when a mother wants to come back to work they should be more than welcomed rather than discouraged. They have more potential than any working employee and are able to induce quality work for the company.Companies need to change their mindset and update their policies so that they can create an easy and friendly environment for mothers as well. A working mother can have skills just like any other employee and they can execute best practices with quality. However, there are some obligations that they need to fulfill. Mothers have gone through such experiences that no human ever has therefore you can expect a good set of creative abilities from them."
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = true;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    abledCandidatesModal() {
      this.modalTitle = "Abled Candidates";
      this.modalcontent = "Diversity is the best way for innovation. Having a diverse team will bring out the best in everyone through a dynamic synergy. People with disabilities have the stereotype of lacking something but from a different perspective, they are the ones having unprecedented abilities. Most employers fail to recognize that a handicapped person is just as capable as any other employee in the workforce. Talent comes from within and therefore should not be rejected. There are many rights formed for the person with a disability but are not implemented in the companies right now.The provision of most of these companies needs to be upgraded in order to align with the needs of the handicapped. Having a disability is not a sin and they can generate ideas, and execute them efficiently. Different types of disability can limit their job position but it cannot eliminate them. The top-notch industry players in the market need to consider the problems of handicapped persons in applying for a job and alter the positions according to their needs. This will not only make the company stand out from others but will bring out-of-the-box ideas for the company as well. The disability of a person should not become the merit to judge their skills and a completely different criterion is to be introduced for them."
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = true;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    freshGraduateModal() {
      this.modalTitle = "Fresh Graduate";
      this.modalcontent = "The first professional experience of a student is very important for their career development. Fresh graduate jobs or internships for students is the best way to ignite the professional journey ahead. It is in human nature to expect something good after giving their best performance. A salary can be a reward for fresh graduates and motivate them to do their best. A paid internship is the best way to train young minds and help them to achieve exceptional results. A student needs a platform to practically implement the strategies they have learned throughout their lives.Paid internships are an integral part of a good company. It allows young minds to explore and induce their expertise with a fresh perspective. It is good for a company to hire interns as the workload can be divided easily and the young minds get to learn new things as well. The interns are enthusiastic learners as well as a brilliant support team. Internship is not only great for a company but also for the experienced staff as well. Paid internships are brilliant opportunities for the students as well as the companies to form a synergy that suits both of them."
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = true;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    remoteModal() {
      this.modalTitle = "Remote & Flexible Internship Program";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = true;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    viiLearnModal() {
      this.modalTitle = "Vii Learn";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = true;
      this.isShehriDirectoryVisible = false;
    },
    shehriDirectoryModal() {
      this.modalTitle = "Shehri Directory";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = true;
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },
  },
  mounted() {
    this.getAdds();
    this.getAll();
    this.getPackagesInfo();
    this.getProfileInfo(0);
    this.getJobInfo(3);
    this.getGraphData();
  },
};
</script>
<style scoped>
.adsclass{
  width: 100%;
}
.custom-modal .modal-dialog {
  max-width: 60%; 
}
@media screen and (max-width: 720px) {
  .adsclass{
  width: 200px;
}
.custom-modal .modal-dialog {
  max-width: 90%; 
}
}
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h5 {
  font-weight: bold;
}

.card .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.card .badge-primary {
  background-color: #007bff;
}
.form-control{
  width: 40% !important;
}

</style>