<template>
  <div class="container">
    <div class="mt-2">
      <div class="
            custom-row custom-options-menu
            justify-content-between
            align-items-center
          ">
        <div class="customHeading">
          <nav>
            <div class="nav nav-tabs custom-nav-tabs" id="nav-tab" role="tablist">
              <a class="
                    nav-item nav-link
                    custom-nav-link custom-nav-heading
                    active
                  " id="employee-detail-tab" data-toggle="tab" href="#employee-detail" role="tab"
                aria-controls="employee-detail" aria-selected="true">
                <span> Post a Job </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content cusform" id="nav-tabContent">
        <div class="tab-pane fade show active" id="employee-detail" role="tabpanel" aria-labelledby="employee-detail-tab">
          <div class="mt-30">
            <div class="w3-card">
              <div class="card-body">
                <!-- <h2>Job Details</h2> -->
                <form class="custom-validation" novalidate="">
                  <!--                  @submit.prevent="handleSubmit"-->
                  <!--                  method="post"-->
                  <div class="row mt-3">
                  <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        
                        <label class="col-sm-3 col-form-label col-form-label-sm">Job Post Package:</label>
                        <div class="col-sm-6">
                          <select class="form-control" required="" v-model="body.employerJobPostPackageId" :class="{
                            'is-invalid':
                              submitted &&
                              $v.body.employerJobPostPackageId.$error,
                          }">
                            <option value="0">Select Job Post Package</option>
                            <option v-for="option in packages" :key="option.employerJobPostPackageId"
                              :value="option.employerJobPostPackageId">
                              {{ option.jobPostPackages.packageName }}
                            </option>
                          </select>
                          <div v-if="
                            submitted &&
                            !$v.body.employerJobPostPackageId.required
                          " class="invalid-feedback">
                            Job Post Package is required
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <!-- <button @click="Openmodal" style="
                                font-size: 11px;
                                font-weight: 400;
                                
                            "> Select Package </button> -->
                            <button type="button" 
                             style="
                                font-size: 11px;
                                font-weight: 400;
                                border-radius: 7px;
                                background: rgb(54, 212, 147);
                                padding: 2px 6px;
                                margin-left: 20px;
                                height: 2rem;
                                color: white;
                            " 
                            data-toggle="modal" 
                            data-target="#exampleModalLong">
                              Select Package
</button>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Job Title</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" required="" v-model="body.jobTitle" :class="{
                            'is-invalid':
                              submitted && $v.body.jobTitle.$error,
                          }" name="txtfname" placeholder="Job Title" />

                          <div v-if="submitted && !$v.body.jobTitle.required" class="invalid-feedback">
                            Job Title is required
                          </div>
                          <div v-if="!$v.body.jobTitle.minLength" class="invalid-feedback">
                            Job Title must contain at least 3 characters
                          </div>
                          <div v-if="!$v.body.jobTitle.maxLength" class="invalid-feedback">
                            The maximum length of 75 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Department</label>
                        <div class="col-sm-9">
                          <select class="form-control" required v-model="body.industryId" :class="{
                            'is-invalid':
                              submitted && $v.body.industryId.$error,
                          }">
                            <option disabled value="null">Please Select</option>
                            <option v-for="option in industries" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.industryId.required" class="invalid-feedback">
                            Department is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Area</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" required="" v-model="body.area" :class="{
                            'is-invalid': submitted && $v.body.area.$error,
                          }" name="txtfname" placeholder="Area" />

                          <div v-if="submitted && !$v.body.area.required" class="invalid-feedback">
                            Area is required
                          </div>
                          <div v-if="!$v.body.area.minLength" class="invalid-feedback">
                            Area must contain at least 3 characters
                          </div>
                          <div v-if="!$v.body.area.maxLength" class="invalid-feedback">
                            The maximum length of 350 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">City</label>
                        <div class="col-sm-9">
                          <select class="form-control" required v-model="body.cityId" :class="{
                            'is-invalid': submitted && $v.body.cityId.$error,
                          }">
                            <option disabled value="null">Select City</option>
                            <option v-for="option in cities" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.cityId.required" class="invalid-feedback">
                            City is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Country</label>
                        <div class="col-sm-9">
                          <select class="form-control" required v-model="body.countryId" :class="{
                            'is-invalid':
                              submitted && $v.body.countryId.$error,
                          }">
                            <option value="null">Select Country</option>
                            <option v-for="option in countries" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.countryId.required" class="invalid-feedback">
                            Country is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Province</label>
                        <div class="col-sm-9">
                          <select :disabled="body.countryId != 811" class="form-control" v-model="body.province" :class="{
                            'is-invalid':
                              submitted &&
                              $v.body.province.$error &&
                              body.countryId === 811,
                          }" required="">
                            <option disabled value="null">
                              Select Province
                            </option>
                            <option value="Islamabad">Islamabad</option>
                            <option value="KPK">KPK</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Balochistan">Balochistan</option>
                            <option value="Sindh">Sindh</option>
                          </select>
                          <div v-if="
                            submitted &&
                            !$v.body.province.required &&
                            body.countryId === 811
                          " class="invalid-feedback">
                            Province is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row mt-3">
              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Skill 1</label
                >
                <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  required=""

                  v-model="body.Skill1 "
                  name="txtfname"
                  placeholder="skill"
                />
              </div>
              </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Skill 2</label
                >
                <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  required=""

                  v-model="body.Skill2"
                  name="txtfname"
                  placeholder="skill"
                />
              </div>
              </div>
              </div>

            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Skill 3</label
                >
                <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  required=""

                  v-model="body.Skill3"
                  name="txtfname"
                  placeholder="skill"
                />
              </div>
              </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Skill 4</label
                >
                <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  required=""

                  v-model="body.Skill4"
                  name="txtfname"
                  placeholder="skill"
                />
              </div>
              </div>
              </div>

            </div> -->

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Preference</label>
                        <div class="col-sm-9">
                          <select class="form-control" required="" v-model="body.genderId" :class="{
                            'is-invalid':
                              submitted && $v.body.genderId.$error,
                          }">
                            <option disabled value="null">Please select</option>
                            <option v-for="option in genderList" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.genderId.required" class="invalid-feedback">
                            Preference is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Salary</label>
<div class="col-sm-9">
    <select class="form-control" v-model="body.salaryId">
        <option value="competitive" selected>Market Competitive Salary</option>
        <option value="range">Salary From - To</option>
    </select>
</div>

  </div>


</div>
                  </div>

                  <div class="row mt-3">
    <div class="col-md-6">
        <div class="mb-3 form-group row no-gutters">
            <label class="col-sm-3 col-form-label col-form-label-sm">Category</label>
            <div class="col-sm-9">
                <select class="form-control" required v-model="body.categoryId" :class="{'is-invalid': submitted && $v.body.categoryId.$error}">
                    <option disabled value="null">Select Category</option>
                    <option v-for="option in categoryList" :key="option.key" :value="option.key">{{ option.value }}</option>
                </select>
                <div v-if="submitted && !$v.body.categoryId.required" class="invalid-feedback">Category is required</div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
    <div v-if="body.salaryId === 'range'" class="row no-gutters">
        <label class="col-sm-3 col-form-label col-form-label-sm">Range</label>
        <div class="col-sm-9">
            <div class="row no-gutters">
                <div class="col-sm-4 mr-1">
                    <input type="number" class="form-control" required v-model="body.packageFrom" :class="{'is-invalid': submitted && !$v.body.packageFrom.required}" placeholder="Salary From" min="1" data-parsley-type="digits" />
                    <!-- <div v-if="submitted && !$v.body.packageFrom.required" class="invalid-feedback">Package From is required</div> -->
                </div>
                <div class="col-sm-4">
                    <input type="number" class="form-control" required v-model="body.packageTo" :class="{'is-invalid': submitted && !$v.body.packageTo.required}" placeholder="Salary To" min="1" data-parsley-type="digits" />
                    <div v-if="submitted && !$v.body.packageTo.required" class="invalid-feedback">Package To is required</div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="row no-gutters">
        <div class="col-sm-12">
            <input type="text" class="form-control" v-model="body.packageTo" readonly style="display: none;" />
            <span style="display:none;">{{ body.packageTo || 'Default Value' }}</span>
        </div>
    </div>
</div>

</div>


                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Career Level</label>
                        <div class="col-sm-9">
                          <select class="form-control" required="" v-model="body.careerLevelId" :class="{
                            'is-invalid':
                              submitted && $v.body.careerLevelId.$error,
                          }">
                            <option disabled value="null">
                              Select Required Career Level
                            </option>
                            <option v-for="option in careerlevels" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.careerLevelId.required" class="invalid-feedback">
                            Career Level is required
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 form-group row no-gutters" v-if="body.categoryId != 24">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Degree Level</label>
                        <div class="col-sm-9">
                          <select class="form-control" required="" v-model="body.degreeLevelId" :class="{
                            'is-invalid':
                              submitted &&
                              $v.body.degreeLevelId.$error &&
                              body.categoryId != 24,
                          }">
                            <option disabled value="null">
                              Select Required Degree Level
                            </option>
                            <option v-for="option in degreeLevels" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.degreeLevelId.required" class="invalid-feedback">
                            Degree Level is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters" v-if="body.categoryId != 24">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Shift:</label>
                        <div class="col-sm-9">
                          <select class="form-control" v-model="body.shiftId">
                            <option value="null">Select Job Shift</option>
                            <option v-for="option in shifts" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          
                        </div>
                      </div>
                      <div class="mb-3 form-group row no-gutters" v-if="body.categoryId != 27">
                        <label class="col-sm-3 col-form-label col-form-label-sm">
                          Required Skills
                          <span class="text-muted"><i>(Max. 800 Characters)</i></span>
                        </label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" required="" v-model="body.skill1" maxlength="800"
                            placeholder="required skills" :class="{
                              'is-invalid':
                                submitted &&
                                $v.body.skill1.$error &&
                                body.categoryId != 27,
                            }" />

                          <div v-if="submitted && !$v.body.skill1.required" class="invalid-feedback">
                            Required Skill is required
                          </div>
                          <div v-if="!$v.body.skill1.minLength" class="invalid-feedback">
                            Required Skill must contain at least 3 characters
                          </div>
                          <div v-if="!$v.body.skill1.maxLength" class="invalid-feedback">
                            The maximum length of 800 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters" v-if="body.categoryId != 24">
                        <label class="col-sm-4 col-form-label col-form-label-sm">Experience Level</label>
                        <div class="col-sm-4" style="margin-left: -0.5rem;">
                          <select class="form-control" v-model="body.experienceLevelId" >
                            <option value="null">
                              Experience from
                            </option>
                            <option v-for="option in experienceLevels" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          
                        </div>
                      
                        <div class="col-sm-4" style="margin-left: 0.5rem;">
                          <select class="form-control"  v-model="body.experienceLevelIdTO" >
                            <option value="null">
                              Experience To
                            </option>
                            <option v-for="option in experienceLevels" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Select number of Positions</label>
                        <div class="col-sm-9">
                          <select class="form-control" required="" v-model="body.noOfPosition" :class="{
                            'is-invalid':
                              submitted && $v.body.noOfPosition.$error,
                          }">
                            <option value="null" disabled selected="selected">
                              Select number of Positions
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>

                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                            <option value="51">51</option>
                            <option value="52">52</option>
                            <option value="53">53</option>
                            <option value="54">54</option>
                            <option value="55">55</option>
                            <option value="56">56</option>
                            <option value="57">57</option>
                            <option value="58">58</option>
                            <option value="59">59</option>
                            <option value="60">60</option>
                            <option value="61">61</option>
                            <option value="62">62</option>
                            <option value="63">63</option>
                            <option value="64">64</option>
                            <option value="65">65</option>
                            <option value="66">66</option>
                            <option value="67">67</option>
                            <option value="68">68</option>
                            <option value="69">69</option>
                            <option value="70">70</option>
                            <option value="71">71</option>
                            <option value="72">72</option>
                            <option value="73">73</option>
                            <option value="74">74</option>
                            <option value="75">75</option>
                            <option value="76">76</option>
                            <option value="77">77</option>
                            <option value="78">78</option>
                            <option value="79">79</option>
                            <option value="80">80</option>
                            <option value="81">81</option>
                            <option value="82">82</option>
                            <option value="83">83</option>
                            <option value="84">84</option>
                            <option value="85">85</option>
                            <option value="86">86</option>
                            <option value="87">87</option>
                            <option value="88">88</option>
                            <option value="89">89</option>

                            <option value="90">90</option>
                            <option value="91">91</option>
                            <option value="92">92</option>
                            <option value="93">93</option>
                            <option value="94">94</option>
                            <option value="95">95</option>
                            <option value="96">96</option>
                            <option value="97">97</option>
                            <option value="98">98</option>
                            <option value="99">99</option>
                            <option value="100">100</option>
                            <option value="101">101</option>
                            <option value="102">102</option>
                            <option value="103">103</option>
                            <option value="104">104</option>
                            <option value="105">105</option>
                            <option value="106">106</option>
                            <option value="107">107</option>
                            <option value="108">108</option>
                            <option value="109">109</option>
                            <option value="110">110</option>
                            <option value="111">111</option>
                            <option value="112">112</option>
                            <option value="113">113</option>
                            <option value="114">114</option>
                            <option value="115">115</option>
                            <option value="116">116</option>
                            <option value="117">117</option>
                            <option value="118">118</option>
                            <option value="119">119</option>
                            <option value="120">120</option>
                            <option value="121">121</option>
                            <option value="122">122</option>
                            <option value="123">123</option>
                            <option value="124">124</option>
                            <option value="125">125</option>
                            <option value="126">126</option>
                            <option value="127">127</option>
                            <option value="128">128</option>
                            <option value="129">129</option>
                            <option value="130">130</option>
                            <option value="131">131</option>
                            <option value="132">132</option>
                            <option value="133">133</option>
                            <option value="134">134</option>
                            <option value="135">135</option>
                            <option value="136">136</option>
                            <option value="137">137</option>
                            <option value="138">138</option>
                            <option value="139">139</option>
                            <option value="140">140</option>
                            <option value="141">141</option>
                            <option value="142">142</option>
                            <option value="143">143</option>
                            <option value="144">144</option>
                            <option value="145">145</option>
                            <option value="146">146</option>
                            <option value="147">147</option>
                            <option value="148">148</option>
                            <option value="149">149</option>
                            <option value="150">150</option>
                            <option value="151">151</option>
                            <option value="152">152</option>
                            <option value="153">153</option>
                            <option value="154">154</option>
                            <option value="155">155</option>
                            <option value="156">156</option>
                            <option value="157">157</option>
                            <option value="158">158</option>
                            <option value="159">159</option>
                            <option value="160">160</option>
                            <option value="161">161</option>
                            <option value="162">162</option>
                            <option value="163">163</option>
                            <option value="164">164</option>
                            <option value="165">165</option>
                            <option value="166">166</option>
                            <option value="167">167</option>
                            <option value="168">168</option>
                            <option value="169">169</option>
                            <option value="170">170</option>
                            <option value="171">171</option>
                            <option value="172">172</option>
                            <option value="173">173</option>
                            <option value="174">174</option>
                            <option value="175">175</option>
                            <option value="176">176</option>
                            <option value="177">177</option>
                            <option value="178">178</option>
                            <option value="179">179</option>
                            <option value="180">180</option>
                            <option value="181">181</option>
                            <option value="182">182</option>
                            <option value="183">183</option>
                            <option value="184">184</option>
                            <option value="185">185</option>
                            <option value="186">186</option>
                            <option value="187">187</option>
                            <option value="188">188</option>
                            <option value="189">189</option>

                            <option value="190">190</option>
                            <option value="191">191</option>
                            <option value="192">192</option>
                            <option value="193">193</option>
                            <option value="194">194</option>
                            <option value="195">195</option>
                            <option value="196">196</option>
                            <option value="197">197</option>
                            <option value="198">198</option>
                            <option value="199">199</option>
                            <option value="200">200</option>
                          </select>
                          
                          
                        </div>
                        <div v-if="submitted && !$v.body.noOfPosition.required" class="invalid-feedback">
                            Number of positions is required
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">
                          ViiLearn Course Required
                        </label>
                        <div class="col-sm-9">
                          <span class="text-muted"><i>(Please refer to our E-Academy.)</i></span>
                          <select class="form-control" required="" v-model="body.vLearnCourseRequiredId" :class="{
                            'is-invalid':
                              submitted &&
                              $v.body.vLearnCourseRequiredId.$error,
                          }">
                            <option disabled value="">Please Select</option>
                            <option v-for="data in vlearnCourses" :key="data.courseId" :value="data.courseId">
                              {{ data.courseTitle }}
                            </option>
                          </select>
                          <div v-if="
                            submitted &&
                            !$v.body.vLearnCourseRequiredId.required
                          " class="invalid-feedback">
                            ViiLearn Course is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Vii-Learn Course Preference</label>
                        <div class="col-sm-9">
                          <select class="form-control" required="" v-model="body.courseCriteriaId" :class="{
                            'is-invalid':
                              submitted && $v.body.courseCriteriaId.$error,
                          }">
                            <option disabled value="null">Please Select</option>
                            <option v-for="data in coursePreferences" :key="data.key" :value="data.key">
                              {{ data.value }}
                            </option>
                          </select>
                          <div v-if="
                            submitted && !$v.body.courseCriteriaId.required
                          " class="invalid-feedback">
                            Course Preference is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters" v-if="body.categoryId != 24">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Required Travelling
                        </label>
                        <div class="col-sm-9">
                          <input type="radio" v-model="body.jobRequireTraveling" class="" value="true" name="optradio1" />
                          <label>Yes &nbsp;</label>
                          <input type="radio" v-model="body.jobRequireTraveling" class="" value="false"
                            name="optradio1" />
                          <label>No</label>
                        </div>
                      </div>
                    </div>
                  
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters" v-if="body.categoryId != 24">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Job Type</label>
                        <div class="col-sm-9">
                          <select class="form-control" v-model="body.jobTypeId" :class="{
                            'is-invalid':
                              submitted && $v.body.jobTypeId.$error,
                          }">
                            <option value="null">Select Job Type</option>
                            <option v-for="option in jobTypeList" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="submitted && !$v.body.jobTypeId.required" class="invalid-feedback">
                            Job Type is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Select Hiring Type</label>
                        <div class="col-sm-9">
                          <select class="form-control" required="" v-model="body.assistedHiringType" :class="{
                            'is-invalid':
                              submitted && $v.body.assistedHiringType.$error,
                          }">
                            <option value="null">Select Job Hiring Type</option>
                            <option value="1">Assisted Hiring</option>
                            <option value="0">Unassisted Hiring</option>
                          </select>
                          <div v-if="
                            submitted && !$v.body.assistedHiringType.required
                          " class="invalid-feedback">
                            Hiring Type is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <div class="formrow">
                        <label>
                          Job Specification
                          <span class="text-muted">
                            <i>
                              (Please state your requirements (Max. 2000
                              Characters))
                            </i>
                          </span> </label><textarea class="form-control" required="" v-model="body.jobSpecification"
                          :class="{
                            'is-invalid':
                              submitted && $v.body.jobSpecification.$error,
                          }" maxlength="1000" placeholder="Job Specification" name="JobSpecification" rows="5">
                          </textarea>
                        <div v-if="submitted && !$v.body.jobSpecification.required" class="invalid-feedback">
                          Job Specification is required
                        </div>
                        <div v-if="!$v.body.jobSpecification.minLength" class="invalid-feedback">
                          Job Specification must contain at least 3 characters
                        </div>
                        <div v-if="!$v.body.jobSpecification.maxLength" class="invalid-feedback">
                          The maximum length of 2000 characters is reached
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="body.categoryId != 24">
                    <div class="col-md-12">
                      <div class="formrow">
                        <label>Description</label><textarea class="form-control" required="" v-model="body.description"
                          :class="{
                            'is-invalid':
                              submitted && $v.body.description.$error,
                          }" maxlength="1000" placeholder="Job Description" name="description" rows="5">
                          </textarea>
                        <div v-if="submitted && !$v.body.description.required" class="invalid-feedback">
                          Description is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <div class="formrow">
                        <label>Job Responsibility</label><textarea class="form-control" required=""
                          v-model="body.jobResponsibility" :class="{
                            'is-invalid':
                              submitted && $v.body.jobResponsibility.$error,
                          }" maxlength="1000" placeholder="Job Responsibility" rows="5">
                          </textarea>
                        <div v-if="
                          submitted && !$v.body.jobResponsibility.required
                        " class="invalid-feedback">
                          Job Responsibility is required
                        </div>
                        <div v-if="!$v.body.jobResponsibility.minLength" class="invalid-feedback">
                          Job Responsibility must contain at least 3 characters
                        </div>
                        <div v-if="!$v.body.jobResponsibility.maxLength" class="invalid-feedback">
                          The maximum length of 1000 characters is reached
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3"></div>

                  <div class="row mt-3">
                    <div class="col-lg-12">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">
                          Rewards & Benefits
                          <!--                    <span class="text-danger">*</span>-->
                        </label>
                        <div class="col-sm-9">
                          <multiselect class="custom-multiselect" :class="isCustomMultiselect" @input="
                            onSelectedRewardsAndBenefits(
                              selectedRewardAndBenefits
                            )
                          " :max-height="150" v-model="selectedRewardAndBenefits"
                            tag-placeholder="Add this as new tag" placeholder="Search or add Reward & Benefit"
                            :options="rewardsAndBenefits" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                          <div class="">
                            <span v-if="
                              isCustomMultiselect ==
                              'custom-multiselect-invalid'
                            " class="w3-animate-opacity" style="
                                  width: 100%;
                                  margin-top: 0.25rem;
                                  font-size: 80%;
                                  color: #dc3545;
                                ">Please Select the Rewards And Benefits</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">Job Expiry Date</label>
                        <div class="col-sm-9">
                          <input type="date" class="form-control" :min="minDate" autocomplete="off" required=""
                            v-model="body.jobExpiryDate" :class="{
                              'is-invalid':
                                submitted && $v.body.jobExpiryDate.$error,
                            }" />
                          <div v-if="submitted && !$v.body.jobExpiryDate.required" class="invalid-feedback">
                            Job Expiry Date is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 form-group row no-gutters">
                        <label class="col-sm-3 col-form-label col-form-label-sm">
                          <span>Do you want the job to be Featured?</span>
                        </label>
                        <div class="col-sm-9 pt-1 pb-1">
                          <b-form-checkbox class="custom-checkbox-color" v-model="selectedJobFeature" name="check-button"
                            switch value="1" unchecked-value="0">
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!----><!----><!----><!---->

                  <!--
            <div class="row mt-3">
              <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">projectTitle</label
                >
                <div class="col-sm-9">
                  <input
                  type="text"
                  class="form-control"
                  required=""
                  id="txtfname"
                  v-model="body.projectTitle"
                  name="txtfname"
                  placeholder="Estimated Duration In Months"
                />
              </div>
              </div>
              </div>

                <div class="col-md-6">
                <div class="mb-3 form-group row no-gutters">
                  <label class="col-sm-3 col-form-label col-form-label-sm">Estimated Duration In Months</label
                >
                <div class="col-sm-9">
                  <input
                  type="number"
                  class="form-control"
                  required=""
                  id="txtfname"
                  v-model="body.estimatedDurationInMonth"
                  name="txtfname"
                  placeholder="Estimated Duration In Months"
                />

              </div>
              </div>
              </div>
            </div> -->
                  <hr />
                  <div v-if="body.categoryId == 30">
                    <h3>PWD’s Section</h3>

                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Suitable disability as per the job
                            requirement</label>
                          <div class="">
                            <select class="form-control" required="" v-model="body.disabilityTypeId" :class="{
                              'is-invalid':
                                submitted && $v.body.disabilityTypeId.$error,
                            }">
                              <option value="null">
                                Specify preference for certain disability
                              </option>
                              <option v-for="option in causeOfDisability" :key="option.key" :value="option.key">
                                {{ option.value }}
                              </option>
                            </select>
                            <div v-if="
                              submitted && !$v.body.disabilityTypeId.required
                            " class="invalid-feedback">
                              Please choose your certain disability
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->
                      <div class="col-md-6">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Requirement of Fit To Work Certificate</label>
                          <div class="">
                            <div class="">
                              <input type="radio" v-model="body.fitToWorkCertificate" class="" value="1"
                                name="optradio2" />
                              <label>Yes &nbsp;</label>
                              <input type="radio" v-model="body.fitToWorkCertificate" class="" value="0"
                                name="optradio2" />
                              <label>No</label>
                            </div>
                            <!--                  <select class="form-control" required="" v-model="body.fitToWorkCertificate">
                  <option value = null>Please Select</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <!---->
                      <!-- <div class="col-md-6">
                   <div class="mb-3 form-group row no-gutters">
                     <label class="col-sm-3 col-form-label col-form-label-sm">Is Sign Language Requrired</label
                   >
                   <div class="col-sm-9">
                     <select class="form-control" required="" v-model="body.isSignLanguageRequrired">
                     <option disabled value="">Please Select</option>
                     <option value="1">Yes</option>
                     <option value="2">No</option>
                   </select>
                 </div>
                 </div>
                 </div> -->

                      <!---->
                      <div class="col-md-12">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">What type of Facilities are available at your
                            work
                            place</label>
                          <select class="form-control" required="" v-model="body.specialFacilityAvailableId" :class="{
                            'is-invalid':
                              submitted &&
                              $v.body.specialFacilityAvailableId.$error,
                          }">
                            <option value="null">
                              Select Facilities Available
                            </option>
                            <option v-for="option in specialFacilities" :key="option.key" :value="option.key">
                              {{ option.value }}
                            </option>
                          </select>
                          <div v-if="
                            submitted &&
                            !$v.body.specialFacilityAvailableId.required
                          " class="invalid-feedback">
                            Facilities Available is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Any specific instructions for the candidate,
                            please
                            state here</label>
                          <div class="">
                            <textarea type="text" class="form-control" required="" rows="5" maxlength="2000"
                              v-model="body.specialInstruction" :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.body.specialInstruction.$error,
                              }" name="Special Instructions" placeholder="Please state here..." />
                            <div v-if="
                              submitted &&
                              !$v.body.specialInstruction.required
                            " class="invalid-feedback">
                              Special Instruction is required
                            </div>
                            <div v-if="!$v.body.specialInstruction.minLength" class="invalid-feedback">
                              Special Instruction must contain at least 3
                              characters
                            </div>
                            <div v-if="!$v.body.specialInstruction.maxLength" class="invalid-feedback">
                              The maximum length of 2000 characters is reached
                            </div>
                          </div>
                        </div>
                      </div>
                      <!---->
                    </div>
                    <hr />
                  </div>
                  <div v-if="body.categoryId == 27">
                    <h3>Graduate's Section</h3>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="formrow">
                          <div>
                            One time project / Remote & Flexible Internship
                          </div>
                          <div>
                            Freelancing projects for students & skilled mums
                          </div>
                          <textarea class="form-control" required="" v-model="body.projectDescription" :class="{
                            'is-invalid':
                              submitted && $v.body.projectDescription.$error,
                          }" maxlength="1000" placeholder="Project Description" name="RolesandResponsibility"
                            rows="5">
                            </textarea>
                          <div v-if="
                            submitted && !$v.body.projectDescription.required
                          " class="invalid-feedback">
                            Project Description is required
                          </div>
                          <div v-if="!$v.body.projectDescription.minLength" class="invalid-feedback">
                            Project Description must contain at least 3
                            characters
                          </div>
                          <div v-if="!$v.body.projectDescription.maxLength" class="invalid-feedback">
                            The maximum length of 1000 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-6">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">
                            Expertise Required
                            <span class="text-muted"><i>(Max. 800 Characters)</i></span>
                          </label>
                          <div class="">
                            <input type="text" class="form-control" maxlength="800" v-model="body.skill1" :class="{
                              'is-invalid':
                                submitted &&
                                $v.body.skill1.$error &&
                                body.categoryId === 27,
                            }" placeholder="required skills" />
                            <div v-if="submitted && !$v.body.skill1.required" class="invalid-feedback">
                              Expertise is required
                            </div>
                            <div v-if="!$v.body.skill1.minLength" class="invalid-feedback">
                              Expertise must contain at least 3 characters
                            </div>
                            <div v-if="!$v.body.skill1.maxLength" class="invalid-feedback">
                              The maximum length of 800 characters is reached
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Competency Level</label>
                          <div class="">
                            <select class="form-control" required="" v-model="body.competencyLevelId" :class="{
                              'is-invalid':
                                submitted && $v.body.competencyLevelId.$error,
                            }">
                              <option value="null">Please Select</option>
                              <option v-for="option in competencyLevels" :key="option.key" :value="option.key">
                                {{ option.value }}
                              </option>
                            </select>
                            <div v-if="
                              submitted && !$v.body.competencyLevelId.required
                            " class="invalid-feedback">
                              Competency level is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters">
                          <label class="col-sm-3 col-form-label col-form-label-sm">Project Start Date</label>
                          <div class="col-sm-9">
                            <input type="date" class="form-control" :min="minDate" autocomplete="off" required=""
                              v-model="body.startDate" :class="{
                                'is-invalid':
                                  submitted && $v.body.startDate.$error,
                              }" />
                            <div v-if="submitted && !$v.body.startDate.required" class="invalid-feedback">
                              Start Date is required
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters">
                          <label class="col-sm-3 col-form-label col-form-label-sm">Project End Date</label>
                          <div class="col-sm-9">
                            <input type="date" :min="minDate" class="form-control" autocomplete="off" required=""
                              v-model="body.endDate" :class="{
                                'is-invalid':
                                  submitted && $v.body.endDate.$error,
                              }" />
                            <div v-if="submitted && !$v.body.endDate.required" class="invalid-feedback">
                              End Date is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="formrow">
                          <label>Futher Comments and Info</label><textarea class="form-control" required=""
                            v-model="body.furtherComment" :class="{
                              'is-invalid':
                                submitted && $v.body.furtherComment.$error,
                            }" maxlength="2000" placeholder="Futher Comments and Info" name="RolesandResponsibility"
                            rows="5">
                            </textarea>
                          <div v-if="submitted && !$v.body.furtherComment.required" class="invalid-feedback">
                            Comments is required
                          </div>
                          <div v-if="!$v.body.furtherComment.minLength" class="invalid-feedback">
                            Comments must contain at least 3 characters
                          </div>
                          <div v-if="!$v.body.furtherComment.maxLength" class="invalid-feedback">
                            The maximum length of 500 characters is reached
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="body.categoryId ==91">
                    <h3>Mums Return To Work Section</h3>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="formrow">
                          <label>Job requirement for mums return to work<br><span class="text-muted"><i>(specific requirement such as preffered career break, travelling, requirement, on-site/online jobs etc)</i></span></label><textarea class="form-control" required=""
                            v-model="body.description" :class="{
                              'is-invalid':
                                submitted && $v.body.description.$error,
                            }" maxlength="1000" placeholder="Job Description" name="description" rows="5">
                            </textarea>
                          <div v-if="submitted && !$v.body.description.required" class="invalid-feedback">
                            Description is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="mb-3 form-group row no-gutters">
                          <label class="col-sm-3 col-form-label col-form-label-sm">Required Education Level</label>

                          <div class="col-sm-9">
                            <select class="form-control" required="" v-model="body.degreeLevelId" :class="{
                              'is-invalid':
                                submitted && $v.body.degreeLevelId.$error,
                            }">
                              <option disabled value="null">
                                Select Minimum
                              </option>
                              <option v-for="option in degreeLevels" :key="option.key" :value="option.key">
                                {{ option.value }}
                              </option>
                              <!--                  <option value="Primary">Primary</option>
                                          <option value="Middle">Middle</option>
                                          <option value="Matriculation">Matriculation</option>
                                          <option value="InterMediate">InterMediate</option>
                                          <option value="Bachelors">Bachelors</option>
                                          <option value="Masters">Masters</option>
                                          <option value="Doctrate">Doctrate</option>
                                          <option value="Post Doc">Post Doc</option>-->
                            </select>
                            <div v-if="
                              submitted && !$v.body.degreeLevelId.required
                            " class="invalid-feedback">
                              Required Education Levels is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--              <div class="col-md-6">
                &lt;!&ndash;                <div class="mb-3 form-group row no-gutters">
                                  <label class="col-sm-3 col-form-label col-form-label-sm">Select Required Year(s) of Experience</label
                                >
                                <div class="col-sm-9">
                                  <select class="form-control" required="" v-model="body.experienceRequired">
                                  <option value = null selected="selected">
                                    Select Required job experience
                                  </option>
                               <option v-for="option in yearOfExperiences"
                                            :key="option.key"
                                            :value="option.key"
                                    >
                                      {{ option.value }}
                                  </option>
                                </select>
                              </div>
                              </div>&ndash;&gt;
                              </div>-->
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Years of Experience</label>
                          <div class="">
                            <select class="form-control" required="" v-model="body.experienceLevelId" :class="{
                              'is-invalid':
                                submitted && $v.body.experienceLevelId.$error,
                            }">
                              <option value="null">
                                Select Required Experience Level
                              </option>
                              <option v-for="option in experienceLevels" :key="option.key" :value="option.key">
                                {{ option.value }}
                              </option>
                            </select>
                            <div v-if="
                              submitted && !$v.body.experienceLevelId.required
                            " class="invalid-feedback">
                              Required Experience Level is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Does this job requires traveling?</label>
                          <div class="">
                            <input type="radio" v-model="body.jobRequireTraveling" class="" value="true"
                              name="optradio3" />
                            <label>Yes &nbsp;</label>
                            <input type="radio" v-model="body.jobRequireTraveling" class="" value="false"
                              name="optradio3" />
                            <label>No</label>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3 form-group row no-gutters">
                          <label class="col-sm-3 col-form-label col-form-label-sm">Job Type</label>
                          <div class="col-sm-9">
                            <select class="form-control" v-model="body.jobTypeId" :class="{
                              'is-invalid':
                                submitted && $v.body.jobTypeId.$error,
                            }">
                              <option value="null">Select Job Type</option>
                              <option v-for="option in jobTypeList" :key="option.key" :value="option.key">
                                {{ option.value }}
                              </option>
                            </select>
                            <div v-if="submitted && !$v.body.jobTypeId.required" class="invalid-feedback">
                              Job type is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="mb-3 form-group">
                          <label class="col-form-label col-form-label-sm">Shift Timings</label>
                          <div class="">
                            <select class="form-control" required="" v-model="body.shiftId">
                              <option value="null">Select Job Shift</option>
                              <option v-for="option in shifts" :key="option.key" :value="option.key">
                                {{ option.value }}
                              </option>
                            </select>
                           
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div v-if="body.categoryId == 254">
                    <h3>Technical Staff</h3>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="formrow">
                          <label>Please mention any other requirements</label>
                          <textarea class="form-control" v-model="body.cnic" :class="{
                            'is-invalid': submitted && $v.body.cnic.$error,
                          }" placeholder="Other Requirements" name="Requirements"></textarea>
                          <div v-if="submitted && !$v.body.cnic.required" class="invalid-feedback">
                            Other requirements is required
                          </div>
                          <div v-if="!$v.body.cnic.minLength" class="invalid-feedback">
                            Other requirements must contain at least 3
                            characters
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer" style="width: 100%; border-top: 0px">
                    <div v-if="buyPackage">
                      <b-button type="button" class="form-control custom-form-btn custom-btn-gotopage"
                        @click="onBuyPackage">Buy Package
                      </b-button>
                    </div>

                    <div>
                      <b-button type="button" class="form-control custom-form-btn custom-btn-gotopage" @click="resetForm"
                        variant="outline-dark">
                        Reset
                      </b-button>
                    </div>
                    <div>
                      <b-button type="button" class="form-control custom-form-btn custom-btn-gotopage"
                        :disabled="isLoading" @click.prevent="handleSubmit(6)" variant="outline-dark">Save As Draft
                      </b-button>
                    </div>
                    <div>
                      <b-button type="button" class="form-control custom-form-btn custom-btn-gotopage"
                        :disabled="isLoading" @click.prevent="handleSubmit(2)" variant="outline-dark">Save
                      </b-button>
                    </div>
                  </div>
                  <!-- <div class="text-end mt-5">
              <button
                type="submit"
                class="btn btn-primary waves-effect waves-light me-1"
                :disabled="isLoading"
              >
                Submit</button
              ><button type="reset" class="btn btn-secondary waves-effect">
                Cancel
              </button>
            </div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="style-spacer"></div>
    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" 
    @hidden.bs.modal="updatePackagesSub"> 
     <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 150%;margin-left: -8rem;" >
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Select Pakage</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <BuyPackage></BuyPackage>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="updatePackagesSub">Close</button>
        
      </div>
    </div>
  </div>
</div>
  </div>
</template>
<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import Multiselect from "vue-multiselect";
import moment from "moment";
import BuyPackage from "../employer/BuyPackage.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "EmployerPostJob",
  components: {
    Multiselect,
    BuyPackage
  },
  directives: { mask },
  data() {
    return {
      showmodal:false,
      selectedRewardAndBenefits: [],
      submitted: false,
      rewardsAndBenefits: [],
      isCustomMultiselect: "",

      minDate: new Date().toISOString().substr(0, 10), // 05/09/2019
      status: 1,
      userName: null,
      emailAddress: null,
      password: null,
      phoneNo: null,
      selectedUserRole: null,
      has_error: false,
      isLoading: false,
      EmployeerId: 8,
      jobTitle: null,
      description: null,
      countryId: 106,
      cityId: null,
      packageFrom: null,
      packageTo: null,
      noOfJobs: null,
      preference: null,
      jobExpiryDate: null,
      endDate: null,
      startDate: null,
      degreeLevelId: null,
      requiredJobExperience: null,
      jobTypeId: null,
      jobSubTypeId: null,
      jobTypeDetailId: null,
      weeklyWorkingHour: null,
      workLocation: null,
      travelMiles: null,
      travelTimePercent: null,
      projectTitle: null,
      estimatedDurationInMonth: null,
      projectDescription: null,
      isSignLanguageRequrired: null,
      disabilityTypeId: null,
      jobHiringType: null,
      assistedHiringType: null,
      employerJobPostPackageId: null,

      // cities:[],
      // countries:[],
      industries: [],
      careerlevels: [],
      yearOfExperiences: [],
      experienceLevels: [],
      shifts: [],
      specialFacilities: [],
      causeOfDisability: [],
      vlearnCourses: [],
      coursePreferences: [],
      genderList: [],
      jobTypeList: [],
      categoryList: [],
      //packages: [],
      degreeLevels: [],
      competencyLevels: [],
      selectedJobFeature: 0,
      buyPackage: false,
      body: {
        jobId: 0,
        EmployerId: this.$auth.user().employeeId,
        jobTitle: null,
        area: null,
        description: null,
        industryId: null,
        genderId: null,
        shiftId: null,
        salaryId: null,
        cnic: null,
        careerLevelId: null,
        experienceRequired: null,
        experienceLevelId: null,
        experienceLevelIdTO:null,
        countryId: 811,
        cityId: null,
        weeklyWorkingHour: 0,
        workLocation: null,
        travelMiles: null,
        typeJob: 0,
        travelTimePercent: null,
        estimatedDurationInMonth: 0,
        isSignLanguageRequrired: null,
        disabilityTypeId: null,
        jobHiringType: 0,
        assistedHiringType: null,
        competencyLevelId: null,
        categoryId: null,
        employerJobPostPackageId: null,
        fitToWorkCertificate: 0,
        reward: "",
        // vLearnCourseRequiredId: null,
        courseCriteriaId: null,
        ////////////////////////////////////
        specialFacilityAvailableId: null,
        province: null,
        packageFrom: null,
        packageTo: 0,
        noOfPosition: null,
        preference: null,
        jobExpiryDate: null,
        degreeLevelId: null,
        requiredJobExperience: null,
        jobTypeId: null,
        jobSubTypeId: null,
        jobTypeDetailId: null,
        projectDescription: "",
        projectTitle: "",
        facilityAvailableForPWD: "",
        minimumEducation: null,
        jobResponsibility: null,
        jobRequireTraveling: false,
        jobSpecification: null,
        skill1: null,
        Skill2: null,
        Skill3: null,
        Skill4: null,
        jobStatus: 0
      },
    };
  },

  validations: {
    body: {
      jobTitle: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(75),
      },
      industryId: {
        required,
      },
      area: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(350),
      },
      cityId: { required },
      countryId: { required },
      province: { required },
      genderId: { required },
      categoryId: { required },
      careerLevelId: { required },
      degreeLevelId: { required },
      
      jobTypeId: { required },
      skill1: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(800),
      },
      // packageFrom: { required },
      packageTo: { required },
      noOfPosition: { required },
      // vLearnCourseRequiredId: { required },
      courseCriteriaId: { required },
      employerJobPostPackageId: { required },
      assistedHiringType: { required },
      jobSpecification: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(2000),
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(5000),
      },
      jobResponsibility: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      jobExpiryDate: {
        required,
      },
      projectDescription: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
      competencyLevelId: { required },
      startDate: { required },
      endDate: { required },
      furtherComment: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      //person with disability validations
      disabilityTypeId: { required },
      specialFacilityAvailableId: { required },
      specialInstruction: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(2000),
      },
      //labor man power
      cnic: { required, minLength: minLength(3) },
    },
  },
  computed: {
    countries() {
      return this.$store.state.countries;
    },
    cities() {
      return this.$store.getters.availableCities;
    },
    packages(){
      const packages = this.$store.getters.getPackages;
    return packages;
    }
  },
  mounted() {
    this.body.jobId = this.$route.params.id;
    if (this.body.jobId > 0) this.getJobById();
    //
    this.getPackagesInfo();
    this.getCountries();
    this.getCities();
    this.getInductries();
    this.getCareerLevel();
    this.getYearOfExperience();
    this.getExperienceLevel();
    this.getDegreeLevelList();
    this.getShifts();
    this.getFacilityAvailable();
    this.getCauseOfDisability();
    this.getGender();
    this.getJobType();
    this.getCategory();

    this.getRewardAndBenefits();
    this.getCompetencyLevelList();

    this.fetchCourses();
    this.getCoursePreferences();
    $('#exampleModalLong').on('hidden.bs.modal', this.updatePackagesSub);
  },
  beforeDestroy() {
    // Clean up the event listener to avoid memory leaks
    $('#exampleModalLong').off('hidden.bs.modal', this.updatePackagesSub);
  },
  methods: {
    Openmodal(){
      this.showmodal = true;
    },
    updatePackagesSub() {
     this.getPackagesInfo();
    },
    postjob(status) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.jobStatus = Number(status);
      this.body.jobId = Number(this.body.jobId);
      this.body.countryId = Number(this.body.countryId);
      this.body.packageFrom = Number(this.body.packageFrom);
      this.body.packageTo = Number(this.body.packageTo);
      this.body.employerJobPostPackageId = Number(
        this.body.employerJobPostPackageId
      );
      if (this.body.industryId != null)
        this.body.industryId = Number(this.body.industryId);
      if (this.body.genderId != null)
        this.body.genderId = Number(this.body.genderId);
      if (this.body.careerLevelId != null)
        this.body.careerLevelId = Number(this.body.careerLevelId);
      if (this.body.experienceRequired != null)
        console.log(
          "this.body.experienceRequired",
          this.body.experienceRequired
        );
      this.body.experienceRequired =
        this.body.experienceRequired == "0" ||
          this.body.experienceRequired == null
          ? null
          : Number(this.body.experienceRequired);
    
        this.body.experienceLevelId = Number(this.body.experienceLevelId);
      
        this.body.experienceLevelIdTO = Number(this.body.experienceLevelIdTO);
        
      if (this.body.department != null)
        this.body.department = Number(this.body.department);
      if (this.body.shiftId != null)
        this.body.shiftId = Number(this.body.shiftId);
        if (this.body.salaryId != null)
        this.body.salaryId = Number(this.body.salaryId);
      if (this.body.cityId != null) this.body.cityId = Number(this.body.cityId);
      if (this.body.noOfJobs != null)
        this.body.noOfJobs = Number(this.body.noOfJobs);
      if (this.body.preference != null)
        this.body.preference = Number(this.body.preference);
      if (this.body.degreeLevelId != null)
        this.body.degreeLevelId = Number(this.body.degreeLevelId);
      if (this.body.requiredJobExperience != null)
        this.body.requiredJobExperience = Number(
          this.body.requiredJobExperience
        );
      if (this.body.jobTypeId != null)
        this.body.jobTypeId = Number(this.body.jobTypeId);
      if (this.body.jobSubTypeId != null)
        this.body.jobSubTypeId = Number(this.body.jobSubTypeId);
      if (this.body.jobTypeDetailId != null)
        this.body.jobTypeDetailId = Number(this.body.jobTypeDetailId);
      if (this.body.specialFacilityAvailableId != null)
        this.body.weeklyWorkingHour = Number(this.body.weeklyWorkingHour);
      if (this.body.fitToWorkCertificate != null)
        this.body.fitToWorkCertificate = Number(this.body.fitToWorkCertificate);
      if (this.body.disabilityTypeId != null)
        this.body.disabilityTypeId = Number(this.body.disabilityTypeId);

      this.body.reward = String(this.selectedRewardAndBenefits);
      this.body.typeJob = Number(this.selectedJobFeature);

      if (this.body.estimatedDurationInMonth != null)
        this.body.estimatedDurationInMonth = Number(
          this.body.estimatedDurationInMonth
        );
      if (this.body.facilityAvailableForPWD != null)
        this.body.facilityAvailableForPWD = this.body.facilityAvailableForPWD;
      if (this.body.assistedHiringType != null)
        this.body.assistedHiringType = Number(this.body.assistedHiringType);
      if (this.body.specialFacilityAvailableId != null)
        this.body.specialFacilityAvailableId = Number(
          this.body.specialFacilityAvailableId
        );
      if (this.body.categoryId != null)
        this.body.categoryId = Number(this.body.categoryId);
      this.body.isSignLanguageRequrired = Number(
        this.body.isSignLanguageRequrired
      );
      this.body.jobRequireTraveling = Boolean(this.body.jobRequireTraveling);

      this.errored = false;
      this.buyPackage = false;
      axios
        .post("/api/Job/InsertNewJob", this.body)
        .then((res) => {
          // code that we will 'try' to run
          this.info = res.data;

          console.log("Add Response portion");

          console.log("response : ", this.info);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.isLoading = false;

          if (this.info.status == "Error") {
            this.$bvToast.toast(this.info.msg, {
              toaster: "b-toaster-top-center",
              variant: "danger",
              title: "Job",
              solid: true,
            });
          }

          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else if (this.info.status == "Success") {
            this.$bvToast.toast("Posted Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",
              title: "Job",
              solid: true,
            });
            if (status == 2) {
              this.$router.push({
                name: "EmployerJobs",
                params: { status: "4" },
              });
            }
            else {
              this.$router.push({ name: "EmployerJobs", params: { status: "2" } });
            }

          } else {
            this.buyPackage = true;
            this.$bvToast.toast("Please buy a package, before posting a job", {
              toaster: "b-toaster-top-center",
              variant: "danger",

              solid: true,
            });
          }
        });
    },




    handleSubmit(status) {
      this.submitted = true;
      let isProvinceValidation = true;
      let isDegreeLevelValidation = true;
      let isShiftValidation = true;
      let isExperienceLevel = true;
      let isRequiredTravelling = true;
      let isJobType = true;
      let isSkill1Validation = true;
      let isProjectDesValidation = false;
      let isCompetencyLevelValidation = false;
      let isFurtherCommentsValidation = false;
      let isStartDateValidation = false;
      let isEndDateValidation = false;

      //PWD VALIDATIONS
      let isDisabilityTypeValidation = false;
      let isSpecialFecilityAvailableValidation = false;
      let isSpecialInstructionValidation = false;

      //labor man power
      let isOtherReqValidation = false;

      if (this.body.countryId != 811) {
        //REMOVE PROVINCE VALIDATION IF COUNTRY !=PAKISTAN
        isProvinceValidation = false;
      }
      if (this.body.categoryId === 24) {
        //mum returns to work
        //  isDegreeLevelValidation=false;
        //  isShiftValidation=false;
        //  isExperienceLevel=false;
        isRequiredTravelling = false;
        //  isJobType=false;
      }
      if (this.body.categoryId === 27) {
        //graduate /remote flexible
        // isSkill1Validation = false;
        isCompetencyLevelValidation = true;
        isFurtherCommentsValidation = true;
        isProjectDesValidation = true;
        isStartDateValidation = true;
        isEndDateValidation = true;
      }

      if (this.body.categoryId === 30) {
        //person with disability
        isDisabilityTypeValidation = true;
        isSpecialFecilityAvailableValidation = true;
        isSpecialInstructionValidation = true;
      }
      if (this.body.categoryId === 254) {
        //labor man power
        isOtherReqValidation = true;
      }

      this.$v.$touch();

      if (this.$v.body.jobTitle.$error){
        alert("Enter Title");
        return;
      }
      if (this.body.experienceLevelId==0 ||  this.body.experienceLevelId==null){
        alert("Select  Experince level upto");
        return
      }
if(this.body.experienceLevelIdTO==0){
  alert("Select  Experince");
        return
}
        if(this.$v.body.industryId.$error){
          alert("Enter Industry");
          return;
        } 
        if(this.$v.body.area.$error){
          alert("Enter area")
          return;
        }
         
        if(this.$v.body.cityId.$error ){
          alert("Enter city")
          return;
        }
      
if (this.$v.body.genderId.$error) {
  alert("Enter prefernce");
  return;
}

if (this.$v.body.categoryId.$error) {
  alert("Enter category");
  return;
}

if (this.$v.body.careerLevelId.$error) {
  alert("Enter career level");
  return;
}

// If these are required fields, you can include them too
// if (this.$v.body.vLearnCourseRequiredId.$error) {
//   alert("Enter course required");
// }

// if (this.$v.body.packageFrom.$error) {
//   alert("Enter package from");
// }

// if (this.$v.body.packageTo.$error) {
//   alert("Enter package to");
// }

if (this.$v.body.noOfPosition.$error) {
  alert("Enter number of positions");
  return;
}

// if (this.$v.body.courseCriteriaId.$error) {
//   alert("Enter course criteria");
// }

if (this.$v.body.assistedHiringType.$error) {
  alert("Enter assisted hiring type");
  return;
}

if (this.$v.body.jobSpecification.$error) {
  alert("Enter job specification");
  return;
}

if (this.$v.body.description.$error) {
  alert("Enter description");
  return;
}

if (this.$v.body.jobResponsibility.$error) {
  alert("Enter job responsibility");
  return;
}

if (this.$v.body.jobExpiryDate.$error) {
  alert("Enter job expiry date");
  return;
}
      
      if (isProvinceValidation && this.$v.body.province.$error) {
        alert("province is invalid");
        return;
      }
      if (isDegreeLevelValidation && this.$v.body.degreeLevelId.$error) {
       alert("degreeLevelId is invalid");
        return;
      }
     
      
      if (isJobType && this.$v.body.jobTypeId.$error) {
       alert("jobTypeId is invalid");
        return;
      }
      if (isSkill1Validation && this.$v.body.skill1.$error) {
        alert("skill1 is invalid");
        return;
      }
      if (isProjectDesValidation && this.$v.body.projectDescription.$error) {
        alert("projectDescription is invalid");
        return;
      }
      if (
        isCompetencyLevelValidation &&
        this.$v.body.competencyLevelId.$error
      ) {
        alert("competencyLevelId is invalid");
        return;
      }
      if (isFurtherCommentsValidation && this.$v.body.furtherComment.$error) {
        alert("furtherComment is invalid");
        return;
      }
      if (isStartDateValidation && this.$v.body.startDate.$error) {
        alert("startDate is invalid");
        return;
      }
      if (isEndDateValidation && this.$v.body.endDate.$error) {
        alert("endDate is invalid");
        return;
      }
      if (isEndDateValidation && this.$v.body.endDate.$error) {
        alert("endDate is invalid");
        return;
      }
      if (
        (isDisabilityTypeValidation && this.$v.body.disabilityTypeId.$error) ||
        (isSpecialFecilityAvailableValidation &&
          this.$v.body.specialFacilityAvailableId.$error) ||
        (isSpecialInstructionValidation &&
          this.$v.body.specialInstruction.$error)
      ) {
        alert("pwd is invalid");
        return;
      }
      if (isOtherReqValidation && this.$v.body.cnic.$error) {
        alert("isOtherReqValidation --cnic is invalid");
        return;
      }

      this.postjob(status);

      // if(this.body.countryId != 811 && this.body.categoryId === 24){
      //   //validation on province should be removed
      //   //hide validation from degree level if body.categoryId === 24

      //   this.$v.body.province.$touch();
      //   this.$v.body.province.$reset();
      //   // this.$v.body.province.$anyDirty=false;
      // }

      // if (this.$v.$invalid) {
      //   console.log("invalid");
      //   return;
      // }
    },

    async onBuyPackage() {

      await this.handleSubmit(6);

      await this.$router.push({
        name: "BuyPackage"
      });

    },



    getCountries() {
      this.$store.dispatch("getCountryList");
      /*this.countries = [];
      let ddBody = {
        type : "Country"
      }
      // console.log("selected Country : ",country);
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
      .then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }

        console.log("countries new: ",res)
        this.countries = res.data.data;
      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");

            }
          });*/
    },
    getCities() {
      this.$store.dispatch("getCityList");
      /*this.cities = [];
      let ddBody = {
        type : "City"
      }
      // console.log("selected Country : ",country);
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
      .then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }

        console.log("cities new: ",res)
        this.cities = res.data.data;
      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });*/
      /*if (country === 106){
        this.city_input = false;
        this.city_dd = true;

      }
      else{
        this.city_input = true;
        this.city_dd = false;
      }*/
    },

    getInductries() {
      this.industries = [];
      let ddBody = {
        type: "Industry",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("industries new: ", res.data.data[0].key);
          this.industries = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },

    getCareerLevel() {
      this.careerlevels = [];
      let ddBody = {
        type: "CareeerLevel",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("careerlevels new: ", res.data.data[0].key);
          this.careerlevels = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getYearOfExperience() {
      this.yearOfExperiences = [];
      let ddBody = {
        type: "YearOfExperience",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("yearOfExperiences new: ", res.data.data[0].key);
          this.yearOfExperiences = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getExperienceLevel() {
      this.experienceLevels = [];
      let ddBody = {
        type: "ExperienceLevel",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("experienceLevels new: ", res.data.data[0].key);
          this.experienceLevels = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getDegreeLevelList() {
      this.degreeLevels = [];
      let model = {
        type: "DegreeLevel",
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then((res) => {
          // code that we will 'try' to run
          this.degreeLevels = res.data.data;

          console.log("Add Response portion");

          console.log("degreeLevels : ", this.degreeLevels);
        })
        .catch((error) => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
          }
        });
    },
    getCoursePreferences() {
      this.coursePreferences = [];
      let ddBody = {
        type: "CoursePrefernce",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("genderList new: ", res.data.data[0].key);
          this.coursePreferences = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    fetchCourses() {
      this.vlearnCourses = [];

      let courseFilter = {
        CourseCategoryId: null,
        CourseTitle: null,
      };

      axios
        .post("/api/V_Learn/GetAllCourse", courseFilter)
        .then((res) => {
          // code that we will 'try' to run

          let list = [];
          list = res.data.data;

          this.vlearnCourses = list.slice().reverse();
          console.log("list : ", this.vlearnCourses);

          console.log("Add Response portion");
        })
        .catch((error) => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
          }
        });
    },
    getCompetencyLevelList() {
      this.competencyLevels = [];
      let model = {
        type: "CompetencyLevel",
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then((res) => {
          // code that we will 'try' to run
          this.competencyLevels = res.data.data;

          console.log("Add Response portion");

          console.log("competencyLevels : ", this.competencyLevels);
        })
        .catch((error) => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {
          }
        });
    },
    getShifts() {
      this.shifts = [];
      let ddBody = {
        type: "Shift",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("shifts new: ", res.data.data[0].key);
          this.shifts = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },

    getFacilityAvailable() {
      this.specialFacilities = [];
      let ddBody = {
        type: "SpecialFacilities",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("specialFacilities new: ", res.data.data[0].key);
          this.specialFacilities = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getCauseOfDisability() {
      this.causeOfDisability = [];
      let ddBody = {
        type: "DisabilityType",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("causeOfDisability new: ", res.data.data[0].key);
          this.causeOfDisability = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getGender() {
      this.genderList = [];
      let ddBody = {
        type: "Gender",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("genderList new: ", res.data.data[0].key);
          this.genderList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },

    getJobType() {
      this.jobTypeList = [];
      let ddBody = {
        type: "JobType",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("jobTypeList new: ", res.data.data[0].key);
          this.jobTypeList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getRewardAndBenefits() {
      this.rewardsAndBenefits = [];
      let ddBody = {
        type: "RewardsAndBenefits",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          let rList = res.data.data;
          for (let reward in rList) {
            this.rewardsAndBenefits.push(rList[reward].value);
          }
          console.log("rewardsAndBenefits : ", this.rewardsAndBenefits);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getJobById() {
      //this.jobTypeList = [];
      let jddBody = {
        jobId: Number(this.body.jobId),
      };
      axios
        .post("/api/Job/GetJobId", jddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          this.body = res.data.data;
          console.log("body new: ", this.body);
          this.selectedRewardAndBenefits =
            this.body.reward == "" || this.body.reward == null
              ? []
              : this.body.reward.split(",");
          this.selectedJobFeature = this.body.typeJob == "FeatureJob" ? 1 : 0;

          this.body.jobRequireTraveling = String(this.body.jobRequireTraveling);
          this.body.jobExpiryDate =
            this.body.jobExpiryDate == "" || this.body.jobExpiryDate == null
              ? null
              : moment(String(this.body.jobExpiryDate)).format("YYYY-MM-DD");
          this.body.startDate =
            this.body.startDate == "" || this.body.startDate == null
              ? null
              : moment(String(this.body.startDate)).format("YYYY-MM-DD");
          this.body.endDate =
            this.body.endDate == "" || this.body.endDate == null
              ? null
              : moment(String(this.body.endDate)).format("YYYY-MM-DD");
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },
    getPackagesInfo() {
      this.$store.dispatch("getPackages");
      console.log("getPackagesInfo hit");
    },
    //   axios
    //     .get("/api/EmployerJobPostPackage/GetAllJobPostPackagesByEmployeeId")
    //     .then((res) => {
    //       if (res.status != "200") {
    //         console.log("error.....");
    //         const error = new Error("Failed to fetch!");
    //         return error;
    //       }

    //       this.packages = res.data.data;
    //       console.log("PACKAGES : ", this.packages);

    //       // });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     })
    //     .finally(() => { });
    // },
    getCategory() {
      this.categoryList = [];
      let ddBody = {
        type: "Category",
      };
      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", ddBody)
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          console.log("categoryList new: ", res.data.data[0].key);
          this.categoryList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
          }
        });
    },

    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.rewardsAndBenefits.push(tag);

      this.selectedRewardAndBenefits.push(tag);
    },
    onSelectedRewardsAndBenefits(sl) {
      console.log("selected Reward : ", sl);
      if (sl.length == 0) {
        this.isCustomMultiselect = "custom-multiselect-invalid";
      } else {
        this.isCustomMultiselect = "custom-multiselect-valid";
      }
    },

    resetForm() {
      this.body.jobTitle = null;
      this.body.area = null;
      this.body.description = null;
      this.body.industryId = null;
      this.body.genderId = null;
      this.body.shiftId = null;
      this.body.salaryId = null;
      this.body.cnic = null;
      this.body.careerLevelId = null;
      this.body.experienceRequired = null;
      this.body.experienceLevelId = null;
      this.body.experienceLevelIdTO = null;
      this.body.countryId = 811;
      this.body.cityId = null;
      this.body.weeklyWorkingHour = 0;
      this.body.workLocation = null;
      this.body.travelMiles = null;
      this.body.typeJob = 0;
      this.body.travelTimePercent = null;
      this.body.estimatedDurationInMonth = 0;
      this.body.isSignLanguageRequrired = null;
      this.body.disabilityTypeId = null;
      this.body.jobHiringType = 0;
      this.body.assistedHiringType = 0;
      this.body.competencyLevelId = null;
      this.body.categoryId = null;
      this.body.employerJobPostPackageId = null;
      this.body.fitToWorkCertificate = 0;
      this.body.reward = "";
      // this.body.vLearnCourseRequiredId = null;
      // this.body.courseCriteriaId = null;
      this.body.specialFacilityAvailableId = null;
      this.body.province = null;
      this.body.packageFrom = null;
      this.body.packageTo = null;
      this.body.noOfPosition = null;
      this.body.preference = null;
      this.body.jobExpiryDate = null;
      this.body.degreeLevelId = null;
      this.body.requiredJobExperience = null;
      this.body.jobTypeId = null;
      this.body.jobSubTypeId = null;
      this.body.jobTypeDetailId = null;
      this.body.projectDescription = "";
      this.body.projectTitle = "";
      this.body.facilityAvailableForPWD = "";
      this.body.minimumEducation = null;
      this.body.jobResponsibility = null;
      this.body.jobRequireTraveling = false;
      this.body.jobSpecification = null;
      this.body.skill1 = null;
      this.body.Skill2 = null;
      this.body.Skill3 = null;
      this.body.Skill4 = null;

      this.selectedRewardAndBenefits = [];
      this.selectedJobFeature = 0;
    },
  },
};
</script>
