import { render, staticRenderFns } from "./EmpJobs.vue?vue&type=template&id=ffb22fae&scoped=true"
import script from "./EmpJobs.vue?vue&type=script&lang=js"
export * from "./EmpJobs.vue?vue&type=script&lang=js"
import style0 from "./EmpJobs.vue?vue&type=style&index=0&id=ffb22fae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffb22fae",
  null
  
)

export default component.exports